import React from 'react';

export type RadioButtonSize = 'sm' | 'md';

export interface RadioButtonProps {
  id?: string;
  name: string;
  label: string;
  value: string | number;
  checked?: boolean;
  margin?: number;
  className?: string;
  handleChange: (name: string | number) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  name,
  value,
  checked,
  handleChange,
  margin = 1,
  className,
}) => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        defaultChecked={checked}
        className={`m-${margin} text-cyan p-0 cursor-pointer ${className} w-4 h-4`}
        onChange={() => handleChange(value)}
      />
      <label className={`m-${margin} cyan p-0`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
