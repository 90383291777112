import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';

import { setAuthorized, setUnauthorized } from '../authActionCreators/authActionCreators';
import { useAuthDispatch, useAuthState } from 'hooks';
import { Loader } from 'components';
import { UserType } from 'models/user';

import { UserControllerProps } from './UserController.types';

export const UserController = ({ children }: UserControllerProps) => {
  const dispatch = useAuthDispatch();
  const { isAuthorized, role, isMember } = useAuthState();
  const [{ data, error, loading }, getProfile] = useAxios({ method: 'POST' }, { manual: true });

  useEffect(() => {
    if (isAuthorized) {
      if (role === UserType.Admin) {
        getProfile({ url: '/admins/me' });
      } else {
        if (isMember) {
          getProfile({ url: '/members/me' });
        } else {
          getProfile({ url: '/medics/me' });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfile, isAuthorized, role]);

  useEffect(() => {
    if (!loading && error) {
      dispatch(setUnauthorized());
    }

    if (!loading && !error && data) {
      dispatch(setAuthorized(data.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  if (loading) {
    return <Loader />;
  }

  return <>{children}</>;
};
