import React from 'react';
import { Field } from 'formik';
import BaseSelect from 'react-select';
import { ValueType } from 'react-select/lib/types';

import { useLocale } from 'hooks';

import { FieldStyled } from './styled';
import { CustomSelectProps, SelectFieldOption, SelectFieldProps } from './SelectField.types';

const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  values,
  defaultMenuIsOpen = false,
  isSearchable = true,
  isError = false,
  disabled = false,
  noOptionsMessage,
  onChange,
}: CustomSelectProps) => {
  const { formatMessage } = useLocale();

  const onChangeWithSetFieldValue = (option: ValueType<SelectFieldOption | SelectFieldOption[]>) => {
    form.setFieldValue(
      field.name,
      isMulti ? (option as SelectFieldOption[]).map((item: SelectFieldOption) => item.value) : option,
    );

    if (onChange) {
      onChange();
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => (values || []).findIndex((value) => value === option.value) >= 0)
        : values;
    } else {
      return isMulti ? [] : '';
    }
  };

  const noOptionsText = noOptionsMessage ? noOptionsMessage : formatMessage({ id: 'common.no_options' });

  return (
    <BaseSelect
      isDisabled={disabled}
      className={`custom-select-container ${isError && '_error'} ${className}`}
      classNamePrefix="custom-select-field"
      name={field.name}
      value={getValue()}
      onChange={onChangeWithSetFieldValue}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isSearchable={isSearchable}
      hideSelectedOptions
      defaultMenuIsOpen={defaultMenuIsOpen}
      noOptionsMessage={({ inputValue }) => (!inputValue ? noOptionsText : '')}
    />
  );
};

export const SelectField = ({
  id,
  name,
  label,
  labelId,
  options = [],
  className,
  placeholder,
  placeholderId,
  values = [],
  isMulti,
  paddingR = 0,
  paddingT,
  marginTop = 5,
  required = false,
  defaultMenuIsOpen,
  isSearchable,
  isError = false,
  disabled = false,
  noOptionsMessage,
  onChange,
}: SelectFieldProps) => {
  const { formatMessage } = useLocale();

  return (
    <label id={id} className={`block w-full pr-${paddingR} mt-${marginTop} pt-${paddingT}`}>
      {(label || labelId) && (
        <>
          <span className="text-base text-gray-light">{label ? label : formatMessage({ id: labelId })}</span>
          {required && <span className="text-base text-red-dark ml-1">{'*'}</span>}
        </>
      )}
      <FieldStyled>
        <Field
          className={className}
          name={name}
          options={options}
          component={CustomSelect}
          placeholder={placeholder ? placeholder : placeholderId ? formatMessage({ id: placeholderId }) : undefined}
          isMulti={isMulti}
          values={values}
          onChange={onChange}
          defaultMenuIsOpen={defaultMenuIsOpen}
          isSearchable={isSearchable}
          isError={isError}
          disabled={disabled}
          noOptionsMessage={noOptionsMessage}
        />
      </FieldStyled>
    </label>
  );
};
