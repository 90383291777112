import { makeObservable, observable, action } from 'mobx';
abstract class AbstractSingleValueFilterStore {
  options: number[] | string[] = [];
  current?: number | string;
  tabToMaintainOnProfileReload?: number | string;

  setValue = (value: number | string | undefined): void => {
    if (this.current !== value) this.current = value;
    this.tabToMaintainOnProfileReload = undefined;
  };

  maintainTabAfterReload = (): void => {
    if (this.current) this.tabToMaintainOnProfileReload = this.current;
  };

  constructor(options: number[] | string[], defaultOption?: number | string) {
    this.options = options;
    this.current = defaultOption;

    makeObservable(this, {
      options: observable,
      current: observable,
      tabToMaintainOnProfileReload: observable,
      setValue: action,
      maintainTabAfterReload: action,
    });
  }
}

export default AbstractSingleValueFilterStore;
