import useAxios from 'axios-hooks';
import React, { useReducer } from 'react';
import { useEffect } from 'react';
import { ReactTestRendererNode } from 'react-test-renderer';
import { DateTime } from 'luxon';

import { useAuthState, useLocale, useModalDispatch } from 'hooks';
import { PatientDispatchContext, PatientStateContext } from '../patientContext/PatientContext';
import { patientReducer } from '../patientReducer/patientReducer';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { PatientStateContextType } from '../patientContext/PatientContext.types';
import {
  setLoading,
  setOverallInfo,
  setPatientActivities,
  setPatientInBetweenActivities,
  setPatients,
  setSelectedDateNoteAction,
} from '../patientActionCreators/patientActionCreators';
import { UserType } from 'models/user';
import { PatientProfileModel } from 'models/patient';

import { PatientContextControllerProps } from './PatientContextController.types';

declare global {
  interface Window {
    Cypress: ReactTestRendererNode;
    store: PatientStateContextType;
  }
}

export const PatientContextController = ({ children }: PatientContextControllerProps) => {
  const [state, dispatch] = useReducer(patientReducer, {
    patients: [],
    bwOutOfRange: false,
    overallInfo: undefined,
    loading: false,
    formDate: { from: new Date(), to: new Date() },
    selectedDateNote: null,
  });
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();
  const { user, role, profile } = useAuthState();
  const { open } = useModalDispatch();

  const [{ data: activityData, loading: activityLoading, error: activityError }, getActivities] = useAxios(
    {
      method: 'GET',
    },
    { manual: true },
  );
  const [{ data, error, loading }, getPatients] = useAxios(`/medics/patients/lists/${user?.id}`, { manual: true });

  const [
    { data: activitiesInBetweenData, loading: activitiesInBetweenLoading, error: activitiesInBetweenError },
    getActivitiesInBetween,
  ] = useAxios({ method: 'GET' }, { manual: true });

  useEffect(() => {
    if (user && user.id && role === UserType.Medic && getPatients) {
      getPatients();
    }
  }, [getPatients, user, role]);

  useEffect(() => {
    dispatch(setLoading(loading));

    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.patients.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.patients.error_description' }),
        autoHideDuration: 5000,
      });
      dispatch(setLoading(false));
    }

    if (loading && !error) {
      dispatch(setLoading(true));
    }

    if (!loading && !error && data) {
      dispatch(setPatients(data?.data?.patients || []));
      dispatch(setOverallInfo(data?.data?.lists));
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  const [{ data: checkPatientData, error: checkPatientError, loading: checkPatientLoading }, checkPatient] = useAxios(
    {
      url: '/patients',
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    if (!checkPatientLoading && checkPatientError) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.check_patient.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.check_patient.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!checkPatientLoading && !checkPatientError && checkPatientData) {
      open('patient-checked');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPatientLoading, checkPatientError, checkPatientData]);

  const onCheckPatient = async (patientId: string, bpReadingId: string) => {
    await checkPatient({
      data: {
        id: patientId,
        checked_reading_id: bpReadingId,
        patient_weekly_checked: true,
      },
    });

    if (getPatients) {
      getPatients();
    }
  };

  if (window.Cypress) {
    window.store = { ...state };
  }

  const getActivitiesList = (id: string) => {
    getActivities({
      url: `/medics/activities/patients/${id}/activities`,
    });
  };

  const getActivitiesInBetweenList = (id: string, from: string, to: string) => {
    getActivitiesInBetween({
      url: `/medics/activities/patients/${id}/activities/${from}/${to}`,
    });
  };

  useEffect(() => {
    if (state.selectedPatient?.id) {
      getActivitiesList(state.selectedPatient?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedPatient]);

  useEffect(() => {
    if (!activityLoading && activityError) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'patients.activities.error_title' }),
        description: formatMessage({ id: 'patients.activities.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!activityLoading && !activityError && activityData) {
      dispatch(setPatientActivities(activityData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLoading, activityError, activityData]);

  useEffect(() => {
    if (!activitiesInBetweenLoading && activitiesInBetweenError) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'patients.activities.error_title' }),
        description: formatMessage({ id: 'patients.activities.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!activitiesInBetweenLoading && !activitiesInBetweenError && activitiesInBetweenData) {
      dispatch(setPatientInBetweenActivities(activitiesInBetweenData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesInBetweenLoading, activitiesInBetweenError, activitiesInBetweenData]);

  // Activate treatment

  const [, updatePatient] = useAxios({ url: '/patients', method: 'PUT' }, { manual: true });

  const startPatientTreatment = async (patient: PatientProfileModel) => {
    const { id, treatment } = patient;

    const selectedTreatment = [...treatment];

    selectedTreatment.push({
      start_date: DateTime.now().toUTC().toISO(),
      end_date: null,
      reason: null,
    });

    try {
      const data = await updatePatient({
        data: {
          id: id,
          treatment: JSON.stringify(selectedTreatment),
          is_treatment_going_on: true,
        },
      });

      if (data) {
        openSnackbar({
          severity: 'success',
          title: formatMessage({ id: 'dashboard.snackbar.update_patient.success_title' }),
          description: formatMessage({ id: 'dashboard.snackbar.update_patient.success_description' }),
          autoHideDuration: 5000,
        });

        await getPatients();

        // Segment event
        window.analytics.track('Continue treatment', {
          userId: user?.id,
          company: profile?.practice_name,
        });
      }
    } catch (error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.update_patient.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.update_patient.error_description' }),
        autoHideDuration: 5000,
      });
    }
  };

  // Set selected date for note

  const setSelectedDateNote = (date: string | null) => {
    dispatch(setSelectedDateNoteAction(date));
  };

  return (
    <PatientStateContext.Provider
      value={{
        ...state,
        getPatients,
        onCheckPatient,
        getActivitiesList,
        getActivitiesInBetweenList,
        startPatientTreatment,
        setSelectedDateNote,
      }}
    >
      <PatientDispatchContext.Provider value={dispatch}>{children}</PatientDispatchContext.Provider>
    </PatientStateContext.Provider>
  );
};
