import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import { DateField, SelectField } from 'components';
import { Color } from 'utils/colors.enum';
import { CheckBoxStyled } from 'app/shared/styled';
import { stepsComponentProps } from '../../AddMedicModal.types';

import { BasicInfoStepFormikProps } from './BasicInfoStep.types';

export const accountTypeOptions = (formatMessage) => {
  return [
    {
      value: 'CUSTOMER',
      label: formatMessage({ id: 'medics.account_type.option.customer' }),
    },
    {
      value: 'TEST',
      label: formatMessage({ id: 'medics.account_type.option.test' }),
    },
    {
      value: 'ARCHIVED',
      label: formatMessage({ id: 'medics.account_type.option.archived' }),
    },
    {
      value: 'INTERNAL',
      label: formatMessage({ id: 'medics.account_type.option.internal' }),
    },
  ];
};

export const BasicInfoStep = ({ parentContext }: stepsComponentProps) => {
  const { formatMessage } = useLocale();
  const { errors, values, setFieldValue } = useFormikContext<BasicInfoStepFormikProps>();

  return (
    <div className={`${parentContext ? 'w-full' : 'w-60'}`}>
      <label htmlFor="firstName" className="block text-base text-gray-light hidden">
        <span>
          {formatMessage({ id: 'enroll_new_medic.firstname' })}
          <span className="text-red-dark ml-1">{'*'}</span>
        </span>
        <Field
          type="text"
          data-testid="e2e-enroll-first-name"
          name="firstName"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.firstName ? 'red' : 'gray-light'} ${
            errors.firstName && 'border-2'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder={formatMessage({ id: 'enroll_new_medic.placeholder_firstname' })}
          required
        />
      </label>
      <label htmlFor="lastName" className="mt-3 block text-base text-gray-light hidden">
        <span>{formatMessage({ id: 'enroll_new_medic.lastname' })}</span>
        <span className="text-red-dark ml-1">{'*'}</span>
        <Field
          type="text"
          data-testid="e2e-enroll-last-name"
          name="lastName"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.lastName ? 'red' : 'gray-light'} ${
            errors.lastName && 'border-2'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder={formatMessage({ id: 'enroll_new_medic.placeholder_lastname' })}
          required
        />
      </label>
      <SelectField
        name="accountType"
        options={accountTypeOptions(formatMessage)}
        labelId="enroll_new_medic.account_type"
        values={values?.accountType}
        // defaultValue={accountTypeDefaultValue}
        isMulti={false}
        // placeholderId="enroll_new_patient.insured_type"
        // className="pii"
        marginTop={3}
        required={true}
      />
      <div className="hidden">
        <DateField
          name="birthday"
          labelId="enroll_new_patient.date_of_birth"
          maxDate={new Date()}
          required
          marginTop={3}
          classes={{
            fieldClassName: `mt-1 shadow-sm block w-full sm:text-sm border-${errors.birthday ? 'red' : 'gray-light'} ${
              errors.birthday && 'border-2'
            } text-gray placeholder-gray-light rounded-md pii`,
          }}
        />
      </div>
      <label htmlFor="clinic" className="mt-3 block text-base text-gray-light">
        <span>{formatMessage({ id: 'enroll_new_medic.clinic' })}</span>
        <span className="text-red-dark ml-1">{'*'}</span>
        <Field
          type="text"
          data-testid="e2e-enroll-clinic"
          name="clinic"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.clinic ? 'red' : 'gray-light'} ${
            errors.clinic && 'border-2'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder={formatMessage({ id: 'enroll_new_medic.placeholder_clinic' })}
          required
        />
      </label>
      <CheckBoxStyled>
        <label className="flex mt-3">
          <input
            type="checkbox"
            className="form-checkbox rounded h-5 w-5 focus:outline-none"
            style={{ color: Color.cyan }}
            name="sendNotification"
            checked={values.sendNotification}
            onChange={(e) => setFieldValue('sendNotification', e.target.checked)}
          />
          <span className="text-base pr-1 items-center ml-2 text-gray-light">
            {formatMessage({ id: 'enroll_new_medic.send_notification' })}
          </span>
        </label>
      </CheckBoxStyled>
    </div>
  );
};
