import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useAuthState, useLocale, usePatientState } from '../../../../hooks';
import { filteredTaskEstimateTime } from '../../../../utils/filterBy';
import PatientListHeader from '../patientListContainer/patientListHeader/PatientListHeader';
import PatientListItem from '../patientListContainer/patientListItem/PatientListItem';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';
import { customAxios } from 'hooks/useCustomAxios/useCustomAxios';
import { TaskStore } from 'store/TaskStore/TaskStore';
import { TaskStatus } from 'app/tasks/Tasks.types';

const TaskInspectionList = observer(() => {
  const { formatMessage } = useLocale();
  const { overallInfo } = usePatientState();
  const { user } = useAuthState();

  const taskList = Object.keys(TaskStore?.filterTaskList).map((item) => TaskStore?.filterTaskList[item][0]);
  const estimateTime = filteredTaskEstimateTime(overallInfo?.tasks);
  const filterTaskStatus = taskList.filter(
    (items) =>
      TaskStore?.filterTaskList[items.patient_id].filter((item) => item.status.includes(TaskStatus.CREATED)).length > 0,
  );
  // const stoppedTreatmentPatientList = filterStoppedTreatmentPatiens(taskList);

  useEffect(() => {
    if (user?.id) {
      customAxios({
        method: 'post',
        url: `/medics/tasks/${user?.id}`,
        data: {
          status: Object.values(TaskStatus), // send all possible status
        },
      }).then((res) => {
        if (res && res?.data) {
          TaskStore.setFilterTaskList(res?.data?.data?.patients?.INSPECTION, true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-1 border-gray-border md:py-14 px-8 py-8">
      <PatientListHeader
        title={formatMessage({ id: 'dashboard.open_tasks.register_inspection' })}
        lists={filterTaskStatus}
        minutes={estimateTime}
      ></PatientListHeader>
      <PatientListItem
        lists={filterTaskStatus}
        commitmentChartData={TaskStore?.filterTaskList}
        info={overallInfo?.tasks}
        tasks={true}
        filterOption={PatientsCategory.OpenTasks}
      />
    </div>
  );
});

export default TaskInspectionList;
