import { DateTime } from 'luxon';

import { DEVICE_TYPES } from 'enums/device';
import { PatientModel } from 'models/patient';

import { GENDER, HOUR } from './constants.enum';

type TemplateProps = {
  firstName?: string;
  lastName?: string;
};

const img = ['.png', '.svg', '.jpeg', '.jpg', '.img'];

export const isImage = (str: string) => img.some((ele) => str.includes(ele));

export const templateValues = (value: TemplateProps, field: string) => {
  const template = {
    first_name: value.firstName,
    last_name: value.lastName,
  };

  let updated = field;

  Object.keys(template).map((data) => {
    if (field.includes(`{{${data}}}`)) updated = updated.split(`{{${data}}}`).join(template[data] || '');

    return '';
  });

  return updated;
};

export const isDateBetween = (date: string, from: string, to: string) => {
  const fDate = Date.parse(from);
  const lDate = Date.parse(to);
  const cDate = Date.parse(date);

  return cDate <= lDate && cDate >= fDate;
};

export const getGender = (gender: number, formatMessage: ({ id }: { id: string }) => void) => {
  switch (gender) {
    case GENDER.MALE:
      return formatMessage({ id: 'common.short_male' });
    case GENDER.FEMALE:
      return formatMessage({ id: 'common.short_female' });
    case GENDER.DIVERSE:
      return formatMessage({ id: 'common.short_diverse' });
    case GENDER.OPEN:
      return formatMessage({ id: 'common.short_open' });
    default:
      return '';
  }
};

export const getValueBYDeviceTypeId = (deviceTypeId: number | undefined) => {
  return deviceTypeId ? DEVICE_TYPES.filter((selectedType) => selectedType.typeId === deviceTypeId)[0].value : '';
};

export const getNameByTypeId = (deviceTypeId: number | undefined) => {
  return deviceTypeId ? DEVICE_TYPES.filter((selectedType) => selectedType.typeId === deviceTypeId)[0].name : '';
};

export const getPriorityLineColor = (patient: PatientModel) => {
  if (patient?.bpAlertType?.isRed) return 'error';

  if (patient?.bpAlertType?.isYellow) return 'warning';
};

export const computeMedicineDisplayUnit = (intakeModeId: number | undefined) => {
  switch (intakeModeId) {
    case 1:
      return 'mg';
    case 2:
      return 'ml';
    case 3:
      return 'piece';
    default:
      return 'mg';
  }
};

export const getAgeDiff = (data: string) => {
  const dob = new Date(data);
  const month_diff = Date.now() - dob.getTime();
  const age_dt = new Date(month_diff);
  const year = age_dt.getUTCFullYear();
  const age = Math.abs(year - 1970);

  return age;
};

export const isPatientChecked = (patient: PatientModel) => {
  const checkedReadingId = Number(patient?.checked_reading_id);

  if (checkedReadingId === 0) return false;

  return Number(patient?.currentReading?.id) <= checkedReadingId;
};

export const isBetweenWithTimeSlot = (time: string, min: number, max: number) => {
  const timeHour = DateTime.fromISO(time).hour;

  return min < max
    ? min <= timeHour && timeHour <= max
    : (min <= timeHour && timeHour <= HOUR.MAX) || (max >= timeHour && timeHour >= HOUR.MIN);
};

export const iterateObjectAndFilterArrays = (obj: unknown, filterFunc: unknown) => {
  if (Object.keys(obj).length === 0) return obj;

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter(filterFunc);
    }
  });

  return obj;
};

// Group By Generic Method.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = (array: any[], key: string) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);

    return result;
  }, {}); // empty object is the initial value for result object
};

// array of items [ [key: groupByKey[], key: groupByKey[]], [key: groupByKey[], key: groupByKey[]]
export const arrayOfGroupBy = <T>(array: T[], key: string): { [key: string]: T[] }[] => {
  // call groupBy method and get the object
  const group = groupBy(array, key);

  // convert the { key: value[] } to [ key: value[], key: value[] ]
  return Object.keys(group).map((groupKey) => {
    return {
      [groupKey]: group[groupKey],
    };
  });
};
