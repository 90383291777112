import React from 'react';

import { useLocale, usePatientState } from '../../../../hooks';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';
import PatientListHeader from '../patientListContainer/patientListHeader/PatientListHeader';
import PatientListItem from '../patientListContainer/patientListItem/PatientListItem';

const EverythingGoodAll = () => {
  const { formatMessage } = useLocale();
  const { overallInfo } = usePatientState();

  return (
    <div className="flex-1 border-gray-border md:py-14 px-8 py-8">
      <PatientListHeader
        title={formatMessage({ id: 'dashboard.everything_fine' })}
        lists={overallInfo?.everythingGood}
      />
      <PatientListItem
        lists={overallInfo?.everythingGood}
        info={overallInfo?.everythingGood}
        everythingGood={true}
        filterOption={PatientsCategory.EverythingGood}
      />
    </div>
  );
};

export default EverythingGoodAll;
