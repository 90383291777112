import React from 'react';

const SignalIcon = ({ className }: { className?: string }) => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="10.5" cy="8.5" r="1.5" fill="currentColor" />
    <path
      d="M7 4.25708C5.7785 5.26587 5 6.79198 5 8.49992C5 10.2079 5.7785 11.734 7 12.7428M14 4.25708C15.2215 5.26587 16 6.79198 16 8.49992C16 10.2079 15.2215 11.734 14 12.7428"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.45455 1C2.34468 2.78323 1 5.48091 1 8.5C1 11.5191 2.34468 14.2168 4.45455 16M16.5455 1C18.6553 2.78323 20 5.48091 20 8.5C20 11.5191 18.6553 14.2168 16.5455 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default SignalIcon;
