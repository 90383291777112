import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import { StepsComponentProps } from '../../AddPatientModal.types';
import { DateField } from 'components/form/dateField/DateField';

import DeliveryDetailsFields from './DeliveryDetailsFields';

export interface FormikType {
  street: string;
  city: string;
  streetNumber: string;
  postalCode: string;
  company: string;
  send24HDiagnosis: boolean;
  email: string;
  mobileNumber: string;
  weight: number;
  height: number;
  hasDeliveryDateDiagnosis: boolean;
  deliveryDateDiagnosis: string;
}

export const Assign24hDiagnosisStep = ({ parentContext }: StepsComponentProps) => {
  const { formatMessage } = useLocale();
  const { errors, values, setFieldValue } = useFormikContext<FormikType>();
  const today = new Date();
  const availableDeliveryDate = new Date(today);
  availableDeliveryDate.setDate(today.getDate() + 3);

  const handleDeliveryDateCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    if (!value) {
      setFieldValue('deliveryDateDiagnosis', null);
    }

    setFieldValue('hasDeliveryDateDiagnosis', value, true);
  };

  return (
    <>
      {!parentContext && <h2 className="mb-4 text-lg">{formatMessage({ id: 'enroll_new_patient.step.24h.title' })}</h2>}
      <div className={parentContext ? '' : 'mt-5'}>
        <>
          <div className="flex">
            <label htmlFor="weight" className="w-full mr-3 block text-base text-gray-light mt-3">
              <span>
                {formatMessage({ id: 'enroll_new_patient.label_weight' })}
                {!parentContext && <span className="text-red-dark ml-1">{'*'}</span>}
              </span>
              <Field
                type="number"
                required={!parentContext}
                data-testid="e2e-enroll-weight"
                name="weight"
                className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.weight ? 'red' : 'gray-light'} ${
                  errors.weight && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
                } text-gray placeholder-gray-light rounded-md pii`}
                placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_weight' })}
              />
            </label>
            <label htmlFor="height" className="w-full block text-base text-gray-light mt-3">
              <span>
                {formatMessage({ id: 'enroll_new_patient.label_height' })}
                {!parentContext && <span className="text-red-dark ml-1">{'*'}</span>}
              </span>
              <Field
                type="number"
                required={!parentContext}
                data-testid="e2e-enroll-height"
                name="height"
                className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.height ? 'red' : 'gray-light'} ${
                  errors.height && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
                } text-gray placeholder-gray-light rounded-md pii`}
                placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_height' })}
              />
            </label>
          </div>
          {parentContext !== 'RightSideBar' && (
            <>
              <DeliveryDetailsFields />

              <div>
                <label className="flex mt-5">
                  <input
                    type="checkbox"
                    className="form-checkbox rounded h-5 w-5 text-cyan"
                    name="hasDeliveryDateDiagnosis"
                    checked={values?.hasDeliveryDateDiagnosis}
                    onChange={handleDeliveryDateCheckbox}
                  />
                  <span className="ml-2 text-gray">
                    {formatMessage({ id: 'enroll_new_patient.delivery_date.checkbox' })}
                  </span>
                </label>

                {values?.hasDeliveryDateDiagnosis && (
                  <DateField
                    name="deliveryDateDiagnosis"
                    labelId="enroll_new_patient.delivery_date.datepicker"
                    minDate={availableDeliveryDate}
                    required
                    marginTop={3}
                    classes={{
                      fieldClassName: `mt-1 shadow-sm block w-full sm:text-sm border-${
                        errors.deliveryDateDiagnosis ? 'red' : 'gray-light'
                      } ${
                        errors.deliveryDateDiagnosis &&
                        'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
                      } text-gray placeholder-gray-light rounded-md pii`,
                    }}
                  />
                )}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};
