export enum Constant {
  CALL = 7,
  EMAIL = 6,
  REPORT = 5,
  SIGNAL_PROBLEM = 8,
  BATTERY_LOW = 7,
  HEARTBEAT_IRREGULAR = 10,
  RED_ALERT = 9,
  YELLOW_ALERT = 8,
}

export enum INITIAL_EXAMINATION {
  amount = 4.66,
  goa = '5',
  factor = 2.3,
  description = 'Initial examination',
}

export enum INITIAL_CONSULTATION {
  amount = 4.66,
  goa = '1',
  factor = 2.3,
  description = 'Initial consultation',
}

export enum EVALUATION_OF_RECORDS {
  amount = 4.66,
  goa = '1a',
  factor = 2.3,
  description = 'Per week for the summary of the evaluation for 1 week',
}

export enum ADVICE_BY_TELEPHONE {
  amount = 4.66,
  goa = '1',
  factor = 2.3,
  description = 'Advice - by telephone',
}

export enum INSTRUCTION_INTO_DEVICE {
  amount = 4.08,
  goa = 'a76',
  factor = 2.3,
  description = 'Instruction into the device',
}

export enum BLOOD_PRESSURE_MEASUREMENT {
  amount = 8.74,
  goa = 'a654',
  factor = 1.0,
  description = 'Long-term blood pressure measurement',
}

export enum SHORT_CERTIFICATE {
  amount = 2.33,
  goa = '70',
  factor = 2.3,
  description = 'Short certificate',
}

export enum WEEKLY_REPORT {
  amount = 5.36,
  goa = '70',
  factor = 2.3,
  description = 'REPORT',
}

export enum EVALUATION {
  amount = 26.55,
  goa = '653',
  factor = 1.8,
  description = 'Inspection',
}

export enum DEPTH_CONSULTATION {
  amount = 8.74,
  goa = '3',
  factor = 2.3,
  description = 'Depth consultation',
}

export enum MEDICAL_REPORT {
  amount = 7.58,
  goa = '75',
  factor = 2.3,
  description = 'Detailed written illness report',
}

export enum EMAIL_TO_PATIENT {
  amount = 0.17,
  goa = '1a',
  factor = 1.0,
  description = 'Email to patient',
}

export enum DUE_REPORT {
  amount = 5.36,
  goa = '70',
  description = 'Due blood pressure report',
}

export enum EVALUATION_INSPECTION {
  amount = 26.55,
  goa = '653',
  description = 'Evaluation measurement',
}

export enum WEEKLY_REPORTS {
  bloodPressure = 'Blood-Pressure',
  bodyWeight = 'Body-Weight',
}

export enum HEALTH_METRIC_AREAS {
  bloodPressure = 'bp',
  bodyWeight = 'bw',
}

export enum GENDER {
  MALE = 1,
  FEMALE = 2,
  DIVERSE = 3,
  OPEN = 4,
}

export enum HOUR {
  MIN = 0,
  MAX = 23,
}
