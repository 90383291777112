import React from 'react';

const BpIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 'currentColor',
  className = '',
  onClick = () => {},
}: {
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
  className?: string;
  onClick?: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    className={`h-6 w-6 ${className}`}
    // fill={color}
    stroke={stroke}
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      d="M3 12H8L10 9L14 15L16 12H21M12 5.52422L12.765 4.70229C14.8777 2.43257 18.3029 2.43257 20.4155 4.70229C22.5282 6.972 22.5282 10.6519 20.4155 12.9217L13.5301 20.3191C12.685 21.227 11.315 21.227 10.4699 20.3191L3.58447 12.9217C1.47184 10.6519 1.47184 6.972 3.58447 4.70229C5.69709 2.43257 9.12233 2.43257 11.235 4.70229L12 5.52422Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BpIcon;
