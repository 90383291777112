import useAxios from 'axios-hooks';
import React, { useEffect, useReducer } from 'react';

import { useAuthState, useLocale } from 'hooks';
import { DeviceListStateContext, DeviceListDispatchContext } from '../deviceListContext/deviceListContext';
import { deviceListReducer } from '../deviceListReducer/deviceListReducer';
import { UserType } from 'models/user';
import { setDevices, setLoading } from '../deviceListActionCreators/deviceListActionCreator';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { DeviceListContextControllerProps } from './deviceListContextController.types';

export const DevicesListContextController = ({ children }: DeviceListContextControllerProps) => {
  const { user, role } = useAuthState();
  const [state, dispatch] = useReducer(deviceListReducer, {
    devices: [],
    loading: false,
  });
  const { open: openSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();

  const [{ data, error, loading }, getDevices] = useAxios(
    {
      method: 'GET',
    },
    { manual: true },
  );

  useEffect(() => {
    if (user?.id && role === UserType.Admin) {
      getDevices({
        url: `/devices/admin/${user.id}`,
      });
    }

    if (user?.id && role === UserType.Medic) {
      getDevices({
        url: `/devices/medic/${user.id}/report`,
      });
    }
  }, [getDevices, user, role]);

  const onGetDevices = () => {
    if (user?.id && role === UserType.Admin) {
      getDevices({
        url: `/devices/admin/${user.id}`,
      });
    }

    if (user?.id && role === UserType.Medic) {
      getDevices({
        url: `/devices/medic/${user.id}/report`,
      });
    }
  };

  useEffect(() => {
    dispatch(setLoading(loading));

    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'medics.snackbar.devices.error_title' }),
        description: formatMessage({ id: 'medics.snackbar.devices.error_description' }),
        autoHideDuration: 5000,
      });
      dispatch(setLoading(false));
    }

    if (loading && !error) {
      dispatch(setLoading(true));
    }

    if (!loading && !error && data) {
      dispatch(setDevices(data?.data || []));
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  return (
    <DeviceListStateContext.Provider value={{ ...state, getDevices: onGetDevices }}>
      <DeviceListDispatchContext.Provider value={dispatch}>{children}</DeviceListDispatchContext.Provider>
    </DeviceListStateContext.Provider>
  );
};
