import React from 'react';
import { useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import { Color } from 'utils/colors.enum';
import { AddPatientFormSteps } from '../../AddPatientModal.types';

export interface FormikType {
  send24HDiagnosis: boolean;
  sendDeviceToPatient: boolean;
}

type DeviceChoiceStepProps = {
  handleDeviceChange: (
    stepValue: AddPatientFormSteps.diagnosis24 | AddPatientFormSteps.telemonitoring,
    value: boolean,
  ) => void;
};

export const DeviceChoiceStep = ({ handleDeviceChange }: DeviceChoiceStepProps) => {
  const { formatMessage } = useLocale();
  const { values, setFieldValue } = useFormikContext<FormikType>();

  return (
    <>
      <h2 className="mb-4 text-lg">{formatMessage({ id: 'enroll_new_patient.device_choice_spep.title' })}</h2>
      <div className="mt-5">
        <label className="flex items-center mb-3 bg-gray-background py-3 px-4 rounded-md border border-gray-light hover:shadow-md transition-shadow cursor-pointer">
          <div className="mr-4">
            <input
              type="checkbox"
              className="form-checkbox rounded h-5 w-5 focus:outline-none"
              style={{ color: Color.cyan }}
              name="send24HDiagnosis"
              checked={values.send24HDiagnosis}
              onChange={(e) => {
                handleDeviceChange(AddPatientFormSteps.diagnosis24, e.target.checked);
                setFieldValue('send24HDiagnosis', e.target.checked);
              }}
            />
          </div>
          <div className="pr-4">
            <h2 className="text-base font-bold mb-1">
              {formatMessage({ id: 'enroll_new_patient.send_24h_diagnosis_package.title' })}
            </h2>
            <p className="text-base">{formatMessage({ id: 'enroll_new_patient.send_24h_diagnosis_package.text' })}</p>
          </div>
          <div className="w-20 flex-shrink-0">
            <img src="https://pinzon-static.fra1.digitaloceanspaces.com/IEM-Mobil-O-min.png" alt="IEM-Mobil-O device" />
          </div>
        </label>

        <label className="flex items-center bg-gray-background py-3 px-4 rounded-md border border-gray-light hover:shadow-md transition-shadow cursor-pointer">
          <div className="mr-4">
            <input
              type="checkbox"
              className="form-checkbox rounded h-5 w-5 focus:outline-none"
              style={{ color: Color.cyan }}
              name="sendDeviceToPatient"
              checked={values.sendDeviceToPatient}
              onChange={(e) => {
                handleDeviceChange(AddPatientFormSteps.telemonitoring, e.target.checked);
                setFieldValue('sendDeviceToPatient', e.target.checked);
              }}
            />
          </div>
          <div className="pr-4">
            <h2 className="text-base font-bold mb-1">
              {formatMessage({ id: 'enroll_new_patient.send_device_to_patient.title' })}
            </h2>
            <p className="text-base">{formatMessage({ id: 'enroll_new_patient.send_device_to_patient.text' })}</p>
          </div>
          <div className="w-20 flex-shrink-0">
            <img
              src="https://pinzon-static.fra1.digitaloceanspaces.com/BP_measurement-min.png"
              alt="Blood Preasure device"
            />
          </div>
        </label>
      </div>
    </>
  );
};
