import React, { useEffect, useState } from 'react';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { useHistory, useLocation } from 'react-router';
import useAxios from 'axios-hooks';
import { DateTime } from 'luxon';

import { WeekTrendChart } from '../../../weeklyTrends/weekTrendChart';
import { useLocale, usePatientState } from '../../../../../hooks';
import Button from 'components/Button/Button';
import BatteryIcon from 'components/Icons/BatteryIcon';
import SignalIcon from 'components/Icons/SignalIcon';
import { TaskModel } from 'models/task';
import { PatientModel } from 'models/patient';
import { TechnicalIssueModel } from 'models/technical_issue';
import { SharedStyle } from 'app/shared/styled';
import { AppRoute } from 'app/routing/AppRoute.enum';
import CheckboxOutlinedIcon from 'components/Icons/CheckboxOutlinedIcon';
import { getGender } from 'utils/helper';
import { RemarkTooltip } from 'app/dashboard/patientItem/remarkTooltip/RemarkTooltip';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { FontSize } from 'enums/font';
import { getFilterOption } from 'utils/filterBy';
import ChevronRightIcon from 'components/Icons/ChevronRightIcon';
// import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';
import { TaskCommitmentChart } from 'app/patients/commitmentChart/CommitmentChart';
import { InspectionsCompleteStore } from 'store/PatientStore/TaskCompleteStore/InspectionsCompleteStore';

import { PatientListItemProps } from './PatientListItem.types';
import { BoxShadow, PatientRemark } from './styled';
import { PatientListItemDataPoint } from './patientListItemDataPoint/PatientListItemDataPoint';

const formatLastMeasurement = (list: TaskModel): string => {
  if (!('currentReading' in list)) return '-';

  const value = list.currentReading?.ts;
  const func = value > 10000000000 ? DateTime.fromMillis : DateTime.fromSeconds;

  return func(value).toFormat('dd.MM.yyyy');
};

const PatientListItem = (props: PatientListItemProps) => {
  const { formatMessage } = useLocale();
  const { push } = useHistory();
  const { open: openSnackbar } = useSnackbar();
  const { getPatients, overallInfo } = usePatientState();
  const { pathname } = useLocation();

  const [selectedPatient, setSelectedPatient] = useState();

  const {
    lists,
    info,
    tasks,
    everythingGood,
    technicalIssues,
    bpOutOfRange,
    administrative,
    itemsNotFoundMessage,
    // filterOption,
    dueReport,
    commitmentChartData,
  } = props;

  const [{ data, error, loading }, updatePatient] = useAxios({ url: '/patients', method: 'POST' }, { manual: true });

  useEffect(() => {
    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.check_patient.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.check_patient.error_description' }),
        autoHideDuration: 5000,
      });
    }

    const isContinueTreatmentSuccess = !loading && !error && data;
    const isPatientSelected = selectedPatient;

    if (isContinueTreatmentSuccess && isPatientSelected) {
      if (getPatients) getPatients();

      if (selectedPatient) {
        push(
          `${AppRoute.patients}/${getPatientId(selectedPatient)}?filter=${getFilterOption(
            overallInfo,
            selectedPatient?.id,
          )}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, getPatients, selectedPatient]);

  const getPatientId = (list: TaskModel | TechnicalIssueModel | PatientModel) => {
    if (tasks && 'patient_id' in list) {
      return list.patient_id;
    } else {
      return list.id;
    }
  };

  //getting the last interaction to the patient by medic.
  const getLastInteraction = (tasks: TaskModel[]) => {
    const tasksDone = (tasks || [])
      .filter((task) => task.status === 1)
      .sort((a, b) => a.updated_at.localeCompare(b.updated_at));

    if (tasksDone && tasksDone.length > 0 && tasksDone[0].updated_at) {
      return DateTime.fromISO(tasksDone[0].updated_at).toFormat('dd.MM.yyyy');
    }

    return '-';
  };

  const onContinueTreatment = async (
    event: Event,
    list: TechnicalIssueModel[] | TaskModel[] | PatientModel[] | undefined,
  ) => {
    event.stopPropagation();

    setSelectedPatient(list);

    const selectedTreatment = [...list?.treatment];

    selectedTreatment.push({
      start_date: DateTime.now().toUTC().toISO(),
      end_date: null,
      reason: null,
    });

    await updatePatient({
      data: {
        id: list?.id,
        treatment: JSON.stringify(selectedTreatment),
        is_treatment_going_on: true,
      },
    });
  };

  const handleClick = (list: TaskModel | TechnicalIssueModel | PatientModel) => {
    if (pathname === AppRoute.bpOutOfRange) return push(`${AppRoute.patients}/${getPatientId(list)}?filter=All`);
    // return push(`${AppRoute.patients}/${getPatientId(list)}?filter=${filterOption}`);

    if (dueReport) {
      return push(`${AppRoute.weeklyReport}/${list.patient_id}?filter=All`);
      // return push(`${AppRoute.weeklyReport}/${list.patient_id}?filter=${PatientsCategory.DueReport}&from=report`);
    } else {
      InspectionsCompleteStore.active = true;

      return push(`${AppRoute.patients}/${getPatientId(list)}?filter=All`);
      // return push(`${AppRoute.patients}/${getPatientId(list)}?filter=${filterOption}`);
    }
  };

  return (
    <div data-testid="e2e-patients-list" className="flex flex-col items-center text-gray-light pt-11">
      {lists &&
        lists?.length > 0 &&
        lists?.map((list: TaskModel | TechnicalIssueModel | PatientModel, index: number) => {
          return (
            <BoxShadow
              key={index}
              data-testid="e2e-tasks-list"
              className="
              md:p-5 border-b-2 border-gray-border hover:border-transparent
              lg:flex flex-row items-center justify-between w-full cursor-pointer"
              onClick={() => handleClick(list)}
            >
              <div
                className={`pii flex text-lg lg:flex-col w-1/5 lg:justify-start items-center lg:items-start mb-3 lg:mb-0
              ${(tasks || everythingGood || technicalIssues || bpOutOfRange || administrative) && 'lg:w-2/6'}`}
              >
                <span className="text-gray pii">{`${list?.lastname}, ${list?.firstname}`}</span>
                {list?.date_of_birth && DateTime.fromISO(list?.date_of_birth).toFormat('dd.MM.yyyy')}{' '}
                {(list?.gender || list?.date_of_birth) &&
                  `(${`${getGender(list?.gender, formatMessage)}${list?.gender ? ',' : ''} ${
                    list?.date_of_birth
                      ? `${formatMessage(
                          { id: 'common.short_years_old' },
                          { age: Math.ceil(Math.abs(DateTime.fromISO(list.date_of_birth).diffNow('years').years)) },
                        )}`
                      : '-'
                  }`})`}
                <RemarkTooltip tooltipText={`${list.remarks}`}>
                  <PatientRemark className="text-base text-gray-light mt-1 pii">{list.remarks}</PatientRemark>
                </RemarkTooltip>
              </div>
              {(tasks || bpOutOfRange || technicalIssues || administrative || everythingGood || dueReport) && (
                <div
                  className={`md:flex items-center w-full justify-between ${
                    (technicalIssues || bpOutOfRange || administrative) && 'lg:w-4/6'
                  }`}
                >
                  <div
                    className={`flex text-lg text-gray-light ${
                      (technicalIssues || bpOutOfRange || administrative) && 'w-4/5'
                    }`}
                  >
                    <div className="grid grid-cols-5">
                      <>
                        {bpOutOfRange && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.last_measurement' })}
                            data={`${('currentReading' in list && list.currentReading?.systolic) || 0}/
                            ${('currentReading' in list && list.currentReading?.diastolic) || 0}`}
                            col={1}
                          />
                        )}
                        {administrative && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.last_measurement' })}
                            data={formatLastMeasurement(list)}
                            col={1}
                          />
                        )}
                        {administrative && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.last_interaction' })}
                            data={`${'tasks' in list && getLastInteraction(list.tasks)}`}
                            col={3}
                          />
                        )}
                        {bpOutOfRange && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.seven_days_average' })}
                            data={`${
                              ('oneWeekAvgAndDelta' in list &&
                                list.oneWeekAvgAndDelta?.averageBloodPressure?.systolic) ||
                              0
                            }
                            /
                            ${
                              ('oneWeekAvgAndDelta' in list &&
                                list.oneWeekAvgAndDelta?.averageBloodPressure?.diastolic) ||
                              0
                            }`}
                            col={2}
                          />
                        )}
                        {bpOutOfRange && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.change_to_previous_week' })}
                            data={`${('oneWeekAvgAndDelta' in list && list.oneWeekAvgAndDelta?.delta?.systolic) || 0}/
                          ${('oneWeekAvgAndDelta' in list && list.oneWeekAvgAndDelta?.delta?.diastolic) || 0}`}
                            col={3}
                          />
                        )}
                        {bpOutOfRange && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.change_total' })}
                            data={`${('avgAndDelta' in list && list?.avgAndDelta?.delta?.systolic) || 0}/
                          ${('avgAndDelta' in list && list?.avgAndDelta?.delta?.diastolic) || 0}`}
                            col={4}
                          />
                        )}
                        {bpOutOfRange && (
                          <PatientListItemDataPoint
                            title={formatMessage({ id: 'patients.weekly_trends' })}
                            data={
                              <>
                                <div className="xl:ml-3.5 graph-offset pii">
                                  {'weeklyTrendData' in list && list?.weeklyTrendData && (
                                    <WeekTrendChart thisData={list?.weeklyTrendData} />
                                  )}
                                </div>
                              </>
                            }
                            col={5}
                          />
                        )}
                      </>
                    </div>
                  </div>
                  {list?.is_treatment_going_on === false && (
                    <>
                      <div className="flex ml-auto grid grid-cols-2">
                        <div className="col-start-2 col-end-3 row-end-2 text-center text-base text-gray-light">
                          {formatMessage({ id: 'patients.treatment_ended' })}
                        </div>
                        <div className="col-start-2 col-end-3 row-start-2 row-end-3 text-center text-gray-light text-lg mt-2 md:whitespace-nowrap pii">
                          {formatMessage({ id: 'patients.billing_report_yes' })}
                        </div>
                      </div>
                      <Button
                        variant="primary"
                        borderWidth={2}
                        paddingY={4}
                        fontSize={FontSize.base}
                        onClick={(e: Event) => onContinueTreatment(e, list)}
                        className="h-full mx-2"
                      >
                        {formatMessage({ id: 'patients.continue_treatment' })}
                      </Button>
                    </>
                  )}
                  {everythingGood && (
                    <SharedStyle hoverColor={'cyan'}>
                      <CheckboxOutlinedIcon />
                    </SharedStyle>
                  )}
                  {tasks && (
                    <>
                      <span> {formatMessage({ id: 'patients.last_five_records' })}</span>
                      <TaskCommitmentChart data={commitmentChartData[list.patient_id]} maxItems={5} />
                      <div className="flex items-center justify-between -ml-3 lg:ml-0">
                        {/* <div className="inline-block relative">
                      <div className="flex items-center justify-center w-14 h-14 rounded-full bg-gray-background ">
                        <PhoneIcon className={list.task_type.call ? 'text-cyan' : 'text-gray'} />
                      </div>
                      {list.task_type.call && (
                        <>
                          <span className="absolute top-0 right-2 block h-2 w-2 rounded-full ring-4 ring-white bg-red" />
                        </>
                      )}
                    </div> */}
                        {/* <div className="inline-block relative ml-4">
                      <div className="flex items-center justify-center w-14 h-14 rounded-full bg-gray-background ">
                        <EmailIcon className={list.task_type.email ? 'text-cyan' : 'text-gray'} />
                      </div>
                      {list.task_type.email && (
                        <>
                          <span className="absolute top-0 right-2 block h-2 w-2 rounded-full ring-4 ring-white bg-red" />
                        </>
                      )}
                    </div> */}
                        <div className="space-x-1 col-start-8 col-end-9 row-start-1 row-end-3 self-center">
                          <div className={`text-xs text-cyan flex`}>
                            {formatMessage({ id: 'patients.check' })}&nbsp;
                            <div className="text-cyan flex items-center">
                              <ChevronRightIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {dueReport && (
                    <>
                      <span> {formatMessage({ id: 'patients.last_five_records' })}</span>
                      <TaskCommitmentChart data={commitmentChartData[list.patient_id]} maxItems={5} />
                      {/* check */}
                      <div className="space-x-1 col-start-8 col-end-9 row-start-1 row-end-3 self-center">
                        <div className={`text-xs text-cyan flex`}>
                          {formatMessage({ id: 'patients.check' })}&nbsp;
                          <div className="text-cyan flex items-center">
                            <ChevronRightIcon />
                          </div>
                        </div>
                      </div>
                      {/* end of check */}
                    </>
                  )}
                  {technicalIssues && (
                    <div className="flex items-center justify-between -ml-3 lg:ml-0">
                      {'technicalIssues' in list && list.technicalIssues?.isBatteryLow && (
                        <Button variant="inherit" justify="start" className="w-full ml-2">
                          <SharedStyle hoverColor={'cyan'}>
                            <BatteryIcon />
                          </SharedStyle>
                        </Button>
                      )}
                      {'technicalIssues' in list && list.technicalIssues?.isBodyWeightDeviceBatteryLow && (
                        <Button variant="inherit" justify="start" className="w-full ml-2">
                          <SharedStyle hoverColor={'cyan'}>
                            <BatteryIcon />
                          </SharedStyle>
                        </Button>
                      )}
                      {'technicalIssues' in list && list.technicalIssues?.isSignalWeak && (
                        <Button variant="inherit" justify="start" className="w-full ml-2">
                          <SharedStyle hoverColor={'cyan'}>
                            <SignalIcon />
                          </SharedStyle>
                        </Button>
                      )}
                      {'technicalIssues' in list && list.technicalIssues?.isBodyWeightDeviceSignalLow && (
                        <Button variant="inherit" justify="start" className="w-full ml-2">
                          <SharedStyle hoverColor={'cyan'}>
                            <SignalIcon />
                          </SharedStyle>
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </BoxShadow>
          );
        })}
      {info?.length === 0 && itemsNotFoundMessage && (
        <>
          <ClipboardListIcon className="w-16" />
          <div className="text-lg text-center mt-4">{itemsNotFoundMessage}</div>
        </>
      )}
    </div>
  );
};

export default PatientListItem;
