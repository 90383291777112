import axios from 'axios';

import config from 'config';

const getConfig = () => {
  const token = localStorage.getItem('accessToken');

  return {
    'x-access-token': token,
  };
};

interface AxiosParams {
  method: string | undefined;
  url: string;
  data?: unknown;
}

export const customAxios = (props: AxiosParams) => {
  const { method, url, data } = props;

  return axios({
    method,
    url: `${config.apiUrl}${url}`,
    data,
    headers: getConfig(),
  });
};
