import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';

import { useLocale, useModalDispatch, useAuthState, useDeviceListState, useModalState } from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { AddDeviceModel } from './AddDeviceModel';
import { AddDeviceBody } from './AddDeviceModel.types';

export const AddDeviceModelContainer = () => {
  const { options } = useModalState('add-device');
  const { close } = useModalDispatch();
  const { open: openSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { getDevices } = useDeviceListState();
  const { user } = useAuthState();
  const [{ data, error, loading }, addDevice] = useAxios(
    {
      url: '/devices/create',
      method: 'POST',
    },
    { manual: true },
  );
  const [{ data: updateMedicData, error: updateMedicError, loading: updateMedicLoading }, updateMedic] = useAxios(
    {
      url: '/devices/updateMedic',
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.add_new_device.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.add_new_device.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!loading && !error && data) {
      openSnackbar({
        severity: 'success',
        title: formatMessage({ id: 'dashboard.snackbar.add_new_device.success_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.add_new_device.success_description' }),
        autoHideDuration: 5000,
      });
      // This will trigger the device assigments just after the patient is successfully enrolled.

      onClose();

      if (getDevices) {
        getDevices();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data]);

  useEffect(() => {
    if (!updateMedicLoading && updateMedicError) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.update_device_assignee.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.update_device_assignee.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!updateMedicLoading && !updateMedicError && updateMedicData) {
      openSnackbar({
        severity: 'success',
        title: formatMessage({ id: 'dashboard.snackbar.update_device_assignee.success_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.update_device_assignee.success_description' }),
        autoHideDuration: 5000,
      });

      onClose();

      if (getDevices) {
        getDevices();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMedicLoading, updateMedicError, updateMedicData]);

  const onAddNewDevice = (values: AddDeviceBody) => {
    if (options?.device) {
      updateMedic({
        data: {
          id: options.device.id,
          medic_id: values.medicId !== 'None' ? values.medicId : null,
          shipping_status: values.shippingStatus && values.shippingStatus !== 'None' ? values.shippingStatus : null,
        },
      });
    } else {
      addDevice({
        data: {
          admin_id: user?.id,
          imei: values.imei,
          label: values.label,
          medic_id: values.medicId,
          device_type: values.deviceType,
        },
      });
    }
  };

  const onClose = () => {
    close('add-device');
  };

  return <AddDeviceModel onAddNewDevice={onAddNewDevice} onClose={onClose} />;
};
