import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Field, Form, Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { DEVICE_TYPES } from 'enums/device';
import { getValueBYDeviceTypeId } from 'utils/helper';
import { useLocale, useMedicsState, useModalState } from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { MedicContextController } from 'context/medics/medictContextController/MedicContextController';
import { MedicModel } from 'models/medic';
import { DeviceShippingStatus, ShippingStatusLabel } from 'models/device';

import { AddDeviceBody, ErrorBody, AddDeviceProps } from './AddDeviceModel.types';

export const AddDeviceModel = ({ onAddNewDevice, onClose }: AddDeviceProps) => {
  const { isOpen, options } = useModalState('add-device');
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();
  const { medics } = useMedicsState();

  const EnrollSchema = Yup.object().shape({
    imei: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    label: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    medicId: Yup.string(),
    deviceType: Yup.string().required(formatMessage({ id: 'common.required' })),
  });

  const onValidationFailure = (
    event: React.FormEvent<HTMLFormElement>,
    values: AddDeviceBody,
    errors: FormikErrors<ErrorBody>,
  ) => {
    event.preventDefault();

    if (errors.imei || errors.label || errors.medicId) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'add_new_device.snackbar.validation_error_title' }),
        description: formatMessage({ id: 'add_new_device.snackbar.validation_error_description' }),
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmitDevice = (values: AddDeviceBody) => {
    onAddNewDevice(values);
  };

  const initialValues = {
    imei: options?.device?.imei || '',
    label: options?.device?.label || '',
    medicId: options?.device?.medic_id || '',
    deviceType: getValueBYDeviceTypeId(options?.device?.type) || '',
    shippingStatus: options?.device?.shipping_status || '',
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center"
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            data-testid="e2e-enroll-patient-modal"
            className="relation bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg"
          >
            <Formik initialValues={initialValues} onSubmit={handleSubmitDevice} validationSchema={EnrollSchema}>
              {({ handleSubmit, isValid, values, errors }) => (
                <Form
                  onSubmit={(e) => {
                    isValid ? handleSubmit(e) : onValidationFailure(e, values, errors);
                  }}
                >
                  <div className="p-10">
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-xl font-bold text-gray">
                        {formatMessage({ id: options?.device ? 'update_device.title' : 'add_new_device.title' })}
                      </Dialog.Title>
                    </div>

                    <div className="mt-7 flex flex-col justify-center items-center">
                      <MedicContextController>
                        <div className="mt-12 flex flex-col justify-center">
                          <div className="w-60">
                            <label htmlFor="imei" className="block text-base text-gray-light">
                              <span>
                                {formatMessage({ id: 'add_new_device.imei' })}
                                <span className="text-red-dark ml-1">{'*'}</span>
                              </span>
                              <Field
                                type="text"
                                data-testid="e2e-enroll-imei"
                                name="imei"
                                className={`mt-1 shadow-sm block w-full sm:text-sm border-${
                                  errors.imei ? 'red' : 'gray-light'
                                } ${errors.imei && 'border-2'} text-gray placeholder-gray-light rounded-md pii`}
                                placeholder={formatMessage({ id: 'add_new_device.placeholder_imei' })}
                                required
                                readonly={options?.device}
                              />
                            </label>

                            <label htmlFor="label" className="block text-base text-gray-light">
                              <span>
                                {formatMessage({ id: 'add_new_device.label' })}
                                <span className="text-red-dark ml-1">{'*'}</span>
                              </span>
                              <Field
                                type="text"
                                data-testid="e2e-enroll-label"
                                name="label"
                                className={`mt-1 shadow-sm block w-full sm:text-sm border-${
                                  errors.label ? 'red' : 'gray-light'
                                } ${errors.label && 'border-2'} text-gray placeholder-gray-light rounded-md pii`}
                                placeholder={formatMessage({ id: 'add_new_device.placeholder_label' })}
                                required
                                readonly={options?.device}
                              />
                            </label>

                            <label htmlFor="device" className="block text-base text-gray-light mt-3">
                              <span>{formatMessage({ id: 'add_new_device.medic' })}</span>
                              <Field
                                as="select"
                                data-testid="e2e-enroll-select-medic"
                                name="medicId"
                                className="mt-1 shadow-sm block w-full sm:text-sm border-gray-light text-gray placeholder-gray-light rounded-md pii"
                              >
                                <option value="None">
                                  {formatMessage({ id: 'add_new_device.medic.default_option' })}
                                </option>
                                {medics &&
                                  medics?.length > 0 &&
                                  medics.map((medic: MedicModel) => (
                                    <option data-testid="e2e-enroll-device-option" value={medic.id}>
                                      {medic.practice_name || medic.clinic}
                                    </option>
                                  ))}
                              </Field>
                            </label>
                          </div>
                        </div>
                      </MedicContextController>
                      {!options?.device && (
                        <div className="w-60">
                          <label htmlFor="device" className="block text-base text-gray-light mt-3">
                            <span>{formatMessage({ id: 'add_new_device.type' })}</span>
                            <Field
                              as="select"
                              data-testid="e2e-enroll-select-type"
                              name="deviceType"
                              className="mt-1 shadow-sm block w-full sm:text-sm border-gray-light text-gray placeholder-gray-light rounded-md pii"
                            >
                              <option value="None">{formatMessage({ id: 'add_new_device.medic.default_type' })}</option>
                              {DEVICE_TYPES.map((type: { name: string; value: string }) => (
                                <option data-testid="e2e-enroll-device-option" value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </Field>
                          </label>
                        </div>
                      )}
                      {options?.device && (
                        <div className="w-60">
                          <label htmlFor="device" className="block text-base text-gray-light mt-3">
                            <span>{formatMessage({ id: 'add_new_device.shipping_status' })}</span>
                            <Field
                              as="select"
                              data-testid="e2e-enroll-select-type"
                              name="shippingStatus"
                              className="mt-1 shadow-sm block w-full sm:text-sm border-gray-light text-gray placeholder-gray-light rounded-md pii"
                            >
                              <option value="None">
                                {formatMessage({ id: 'add_new_device.medic.default_status' })}
                              </option>
                              {Object.keys(ShippingStatusLabel).map((statusString: string) => (
                                <option data-testid="e2e-enroll-device-option" value={statusString}>
                                  {formatMessage({ id: ShippingStatusLabel[statusString as DeviceShippingStatus] })}
                                </option>
                              ))}
                            </Field>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-4 bg-gray-background sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      data-testid="e2e-enroll-finish-btn"
                      className="ml-3 inline-flex rounded-md shadow-sm px-4 py-2 bg-cyan text-base font-bold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {}}
                    >
                      {formatMessage({ id: options?.device ? 'common.update' : 'common.finish' })}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
