import styled from 'styled-components';

export const DataPlaceholder = styled.div`
  border-radius: 4px;

  &.blinking {
    animation: blink-animation 1s infinite;

    @keyframes blink-animation {
      from {
        background-color: rgba(239, 239, 239, 1);
      }
      to {
        background-color: rgba(239, 239, 239, 0.6);
      }
    }
  }
`;
