import React from 'react';

import { LineChartCapsuleProps } from './arythmeaDot.types';

export const ArythmeaDot = (props: LineChartCapsuleProps) => {
  const {
    cx,
    cy,
    color,
    payload,
    // handleMouseMove, handleMouseOut
  } = props;

  return (
    <>
      {payload && payload.arythmea && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={color ? color : 'currentColor'}
          viewBox="0 0 24 24"
          strokeWidth={1}
          stroke={color ? color : 'currentColor'}
          width={25}
          height={25}
          x={cx - 12}
          y={cy - 12}
        >
          <path
            fill={color ? color : 'currentColor'}
            fillRule="evenodd"
            d="M10.056 4.231L8 8l4.5 4.5-1 2.5 3.5-3-4-4 1.351-1.687a.566.566 0 00.174-.23c1.34-3.19 5.607-3.78 7.763-1.075l.388.488a5.54 5.54 0 01-.718 7.65l-7.39 6.365c-.23.197-.344.296-.477.32a.497.497 0 01-.182 0c-.133-.024-.248-.123-.477-.32l-7.39-6.364a5.54 5.54 0 01-.718-7.651l.388-.488c1.633-2.05 4.478-2.207 6.344-.777z"
            clipRule="evenodd"
          ></path>
        </svg>
      )}
    </>
  );
};
