import React from 'react';
import { Dialog } from '@headlessui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useLocale, useModalState } from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { AppStyled } from 'app/shared/styled';
import { memberPositionsOptions } from 'api/mocks/memberPositionsType';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import { Button } from 'components';

import { BasicInfoStep } from './Steps/BasicInfoStep';
import { AddMemberBody, ErrorBody, AddMemberProps } from './InviteMembers.types';

export const InviteMembers = ({ onAddNewMember, loading }: AddMemberProps) => {
  const { isOpen } = useModalState('add-member');
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();

  const EnrollSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    lastName: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    email: Yup.string()
      .email()
      .required(formatMessage({ id: 'common.required' })),
  });

  const onValidationFailure = (event: React.FormEvent<HTMLFormElement>, values: AddMemberBody, errors: ErrorBody) => {
    event.preventDefault();

    if (errors.firstName || errors.lastName || errors.email || errors.birthday || errors.mobileNumber) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'enroll_new_patient.snackbar.validation_error_title' }),
        description: formatMessage({ id: 'enroll_new_patient.snackbar.validation_error_description' }),
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmitMember = (values: AddMemberBody) => {
    onAddNewMember(values);
  };

  const initialValues: AddMemberBody = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    position: memberPositionsOptions(formatMessage)[0],
  };

  return (
    <ModalWrapper isOpen={isOpen} modalName="add-member">
      <Formik initialValues={initialValues} onSubmit={handleSubmitMember} validationSchema={EnrollSchema}>
        {({ handleSubmit, isValid, values, errors }) => (
          <Form
            onSubmit={(e) => {
              isValid ? handleSubmit(e) : onValidationFailure(e, values, errors);
            }}
          >
            <AppStyled>
              <div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="text-xl font-bold text-gray">
                    {formatMessage({ id: 'setting.members.add_new_member' })}
                  </Dialog.Title>
                </div>

                <div className="max-w-md mx-auto mt-6">
                  <BasicInfoStep />
                </div>
              </div>

              <div
                className="p-4 -m-6 mt-12 bg-gray-background sm:flex sm:flex-row-reverse"
                style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}
              >
                <Button
                  testId="e2e-add-member-submit-btn"
                  type="submit"
                  variant="primary"
                  className="ml-3"
                  disabled={!isValid}
                  paddingY={2}
                  paddingX={6}
                  btnLoader={loading}
                >
                  {formatMessage({ id: 'common.finish' })}
                </Button>
              </div>
            </AppStyled>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};
