import React from 'react';

const BatteryIcon = ({ className }: { className?: string }) => (
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M9.78954 4.1965C9.78954 3.996 9.70925 3.80384 9.56661 3.66293C9.42396 3.52203 9.23083 3.4441 9.03034 3.44656C7.65083 3.46347 6.27174 3.52648 4.89539 3.63559L4.70666 3.65055C4.26771 3.68534 3.90262 4.00202 3.80615 4.43165C3.42768 6.11718 3.42782 7.86616 3.80627 9.55161C3.90245 9.97993 4.26577 10.2964 4.70356 10.3325L4.87783 10.3469C6.25974 10.461 7.64464 10.5269 9.02999 10.5445C9.23054 10.5471 9.42376 10.4692 9.56648 10.3283C9.7092 10.1874 9.78954 9.99518 9.78954 9.79461V4.1965Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2501 8.99997V4.99997C21.2501 4.03347 20.4666 3.24997 19.5001 3.24997H18.8501V3.18964C18.8501 1.9186 17.8722 0.861502 16.605 0.762696L15.3365 0.663788C11.7843 0.386811 8.21585 0.38681 4.66363 0.663787L3.31603 0.768863C2.10638 0.863182 1.11649 1.76932 0.915882 2.96594C0.468144 5.63666 0.468143 8.36328 0.915882 11.034C1.11649 12.2306 2.10638 13.1368 3.31602 13.2311L4.66363 13.3361C8.21586 13.6131 11.7843 13.6131 15.3365 13.3361L16.605 13.2372C17.8722 13.1384 18.8501 12.0813 18.8501 10.8103V10.75H19.5001C20.4666 10.75 21.2501 9.96647 21.2501 8.99997ZM19.7501 4.99997V8.99997C19.7501 9.13804 19.6381 9.24997 19.5001 9.24997L18.1001 9.24997C17.6858 9.24997 17.3501 9.58575 17.3501 9.99997V10.8103C17.3501 11.2981 16.9747 11.7039 16.4884 11.7418L15.2199 11.8407C11.7453 12.1116 8.25484 12.1116 4.78023 11.8407L3.43263 11.7356C2.9098 11.6948 2.48194 11.3032 2.39524 10.786C1.97503 8.27946 1.97503 5.72047 2.39524 3.21395C2.48195 2.69674 2.9098 2.30509 3.43263 2.26432L4.78023 2.15925C8.25484 1.88832 11.7453 1.88832 15.2199 2.15925L16.4884 2.25816C16.9747 2.29608 17.3501 2.7018 17.3501 3.18964V3.99997C17.3501 4.41418 17.6858 4.74997 18.1001 4.74997H19.5001C19.6381 4.74997 19.7501 4.8619 19.7501 4.99997Z"
      fill="currentColor"
    />
  </svg>
);

export default BatteryIcon;
