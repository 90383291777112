export interface Feature {
  date_live: string;
  id: string;
  medic_id: string;
  name: string;
}

export type MedicModel = {
  id?: string;
  id_auto?: number;
  is_active?: boolean;
  email: string;
  fullname?: string;
  password?: string;
  mobile_no?: string;
  address?: string;
  date_of_birth?: string;
  status?: string;
  admin_id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  resetToken?: string;
  resetTokenExpiresAt?: string;
  profileDeleteRequest?: boolean;
  hashedEmail?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  firstname?: string;
  lastname?: string;
  phone_no?: string;
  practice_name?: string;
  clinic?: string;
  title?: number;
  country_code?: string;
  send_daily_alerts?: boolean;
  notification_email?: string;
  features?: Feature[];
  price_change_acknowledged: boolean;
};

export enum Features {
  DIAGNOSIS_24 = '24_diagnosis',
  ASSIGNED_DOCTOR_AND_NURSE = 'assigned_doctor_and_nurse',
  DO_NOT_SEND_NEW_MEMBERS_EMAIL = 'do_not_send_new_members_email',
  NEW_MEMBER_EASY_PASSWORD = 'new_member_easy_password',
  ONBOARDING_MODE = 'onboarding_mode',
  DISABLE_DMP = 'disable_dmp',
  DISABLE_SEND_APP_INVITE_ENROLLMENT = 'disable_send_app_invite_enrollment',
  DISABLE_PINZON_DELIVERY = 'disable_pinzon_delivery',
  DISABLE_MANUAL_BP = 'disable_manual_bp',
  DISABLE_BP_REPORT_ADDRESS = 'disable_bp_report_address',
  SHOW_ASSIGNED_DOCTOR_NAME_BP_REPORT = 'show_assigned_doctor_name_bp_report',
  SEND_BP_REPORT_TO_ASSIGNED_DOCTOR = 'send_bp_report_to_assigned_doctor',
  DISABLE_TASKS_EVALUATION = 'disable_tasks_evaluation',
  DISABLE_FILTER_BY_OPEN_TASKS = 'disable_filter_by_open_tasks',
}
