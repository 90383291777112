import { useContext } from 'react';

import { DeviceStateContextType } from 'context/devices/deviceContext/deviceContext.types';
import { DeviceStateContext } from 'context/devices/deviceContext/deviceContext';

export const useDeviceState: () => DeviceStateContextType = () => {
  const context = useContext(DeviceStateContext);

  if (context === undefined) {
    throw new Error('useDeviceState must be used within an DeviceContextController');
  }

  return context;
};
