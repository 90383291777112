import React, { useState } from 'react';

import Button from '../../../../components/Button/Button';
import { useLocale, usePatientState } from '../../../../hooks';
import Report from '../../../../components/Icons/Report';
import PhoneIcon from '../../../../components/Icons/PhoneIcon';
import EmailIcon from '../../../../components/Icons/EmailIcon';
import { filterByTasks, filteredTaskEstimateTime, filterStoppedTreatmentPatiens } from '../../../../utils/filterBy';
import PatientListHeader from '../patientListContainer/patientListHeader/PatientListHeader';
import PatientListItem from '../patientListContainer/patientListItem/PatientListItem';
import { SharedStyle } from 'app/shared/styled';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';

const TasksAll = () => {
  const { formatMessage } = useLocale();
  const { overallInfo } = usePatientState();

  const [filterType, setFilterType] = useState<string[]>([]);

  const taskList = overallInfo?.tasks && filterByTasks(overallInfo?.tasks, filterType);
  const estimateTime = filteredTaskEstimateTime(overallInfo?.tasks);
  const stoppedTreatmentPatientList = filterStoppedTreatmentPatiens(taskList);

  const setFilter = (data: string) => {
    if (filterType.includes(data)) {
      const filterTypeReplica = [...filterType];
      filterTypeReplica.splice(filterTypeReplica.indexOf(data), 1);
      setFilterType(filterTypeReplica);
    } else {
      setFilterType(() => filterType.concat(data));
    }
  };

  return (
    <div className="flex-1 border-gray-border md:py-14 px-8 py-8">
      <PatientListHeader
        title={formatMessage({ id: 'dashboard.open_tasks' })}
        lists={stoppedTreatmentPatientList}
        minutes={estimateTime}
      >
        <>
          <Button
            variant="inherit"
            justify="start"
            className={`${filterType.includes('call') && 'text-cyan'}`}
            testId="e2e-filter-call-btn"
            onClick={() => setFilter('call')}
          >
            <SharedStyle hoverColor={'cyan'} className="flex items-center">
              <PhoneIcon /> <span className="ml-2 text-xs">{formatMessage({ id: 'dashboard.patients.call' })}</span>
            </SharedStyle>
          </Button>
          <Button
            variant="inherit"
            justify="start"
            className={`${filterType.includes('email') && 'text-cyan'}`}
            testId="e2e-filter-email-btn"
            onClick={() => setFilter('email')}
          >
            <SharedStyle hoverColor={'cyan'} className="flex items-center">
              <EmailIcon /> <span className="ml-2 text-xs">{formatMessage({ id: 'dashboard.patients.email' })}</span>
            </SharedStyle>
          </Button>
          <Button
            variant="inherit"
            justify="start"
            className={`${filterType.includes('report') && 'text-cyan'}`}
            testId="e2e-filter-report-btn"
            onClick={() => setFilter('report')}
          >
            <SharedStyle hoverColor={'cyan'} className="flex items-center">
              <Report /> <span className="ml-2 text-xs">{formatMessage({ id: 'dashboard.patients.report' })}</span>
            </SharedStyle>
          </Button>
        </>
      </PatientListHeader>
      <PatientListItem
        lists={stoppedTreatmentPatientList}
        info={overallInfo?.tasks}
        tasks={true}
        filterOption={PatientsCategory.OpenTasks}
      />
    </div>
  );
};

export default TasksAll;
