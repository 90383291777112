import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import useAxios from 'axios-hooks';

import { MemberModel } from 'app/shared/addPatientModal/steps/remarksInfoStep/RemarksInfoStep';
import { useAuthState } from 'hooks';

type MembersContextType = {
  members: MemberModel[];
  getMembers: () => void;
};

export const MembersContext = createContext<MembersContextType | undefined>(undefined);

type MembersContextControllerProps = {
  children: ReactNode;
};

export const MembersContextController = ({ children }: MembersContextControllerProps) => {
  const { isAuthorized } = useAuthState();
  const [members, setMembers] = useState<MemberModel[]>([]);

  const [{ data: membersData }, fetch] = useAxios<{ data: MemberModel[] }>(
    {
      url: '/members',
      method: 'GET',
    },
    { manual: true },
  );

  // Function to refetch members
  const getMembers = useCallback(() => {
    if (isAuthorized) {
      fetch();
    }
  }, [fetch, isAuthorized]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  // Update members when membersData changes
  useEffect(() => {
    if (membersData) {
      setMembers(membersData.data);
    }
  }, [membersData]);

  return <MembersContext.Provider value={{ members, getMembers }}>{children}</MembersContext.Provider>;
};
