import React from 'react';
import styled from 'styled-components';

import { useLocale } from 'hooks';
import { AppRoute } from '../../../src/app/routing/AppRoute.enum';

const LowResolution = styled.div`
  z-index: -5;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #085c70;
  border-radius: 0px 0px 5px 5px;
  justify-content: center;
  align-items: center;
  @media (min-width: 1281px) {
  }
  @media (max-width: 1280px) {
    z-index: 1000;
    opacity: 1;
    transition: opacity 1s ease 0s;
  }
  .low-resolution-notification {
    color: white;
  }
`;

export const LowResolutionNotification = ({ exclude = [] }: { exclude?: AppRoute[] }) => {
  const { formatMessage } = useLocale();

  if (exclude.indexOf(window.location.pathname) > -1) return <></>;

  return (
    <LowResolution>
      <div className="low-resolution-notification text-xl text-center">
        {formatMessage({ id: 'common.low_resolution_message.title' })}
      </div>
      <div className="low-resolution-notification text-lg mt-2 text-center">
        {formatMessage({ id: 'common.low_resolution_message.text' })}
      </div>
    </LowResolution>
  );
};
