export const categoryOptions = (formatMessage) => {
  return [
    {
      value: 0,
      label: formatMessage({ id: 'content.category.sports_activity' }),
    },
    {
      value: 1,
      label: formatMessage({ id: 'content.category.nutrition' }),
    },
    {
      value: 2,
      label: formatMessage({ id: 'content.category.daily_routine' }),
    },
    {
      value: 3,
      label: formatMessage({ id: 'content.category.hypertension' }),
    },
    {
      value: 4,
      label: formatMessage({ id: 'content.category.wellbeing' }),
    },
  ];
};

export const typeOptions = (formatMessage) => {
  return [
    {
      value: 0,
      label: formatMessage({ id: 'content.type.video' }),
    },
    {
      value: 1,
      label: formatMessage({ id: 'content.type.image' }),
    },
    {
      value: 2,
      label: formatMessage({ id: 'content.type.pdf' }),
    },
  ];
};

export const statusOptions = (formatMessage) => {
  return [
    {
      value: 0,
      label: formatMessage({ id: 'content.status.publish' }),
    },
    {
      value: 1,
      label: formatMessage({ id: 'content.status.draft' }),
    },
    {
      value: 2,
      label: formatMessage({ id: 'content.status.archive' }),
    },
  ];
};
