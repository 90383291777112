// TODO: rename enum values to kebab-case to match the URL naming convention
export enum PatientsCategory {
  All = 'All', // Active patients with treatment
  BpOutOfRange = 'BpOutOfRange',
  OpenTasks = 'OpenTasks',
  TechnicalIssues = 'TechnicalIssues',
  EverythingGood = 'EverythingGood',
  StopTreatment = 'StopTreatment',
  DueReport = 'DueReport',
}

export enum InsuredType {
  Privately = 'Privately insured',
  Legally = 'Legally insured',
}
