import React from 'react';

import { AvatarProps } from './Avatar.types';

const Avatar = ({ firstname, lastname, username, image, size = 6 }: AvatarProps) => {
  const firstName = firstname || username.split(' ')?.slice(0, -1)?.join(' ') || '';
  const lastName = lastname || username.split(' ')?.slice(-1)?.join(' ') || '';

  const initial = (
    firstName && lastName ? `${firstName.substring(0, 1)}${lastName.substring(0, 1)}` : username.substring(0, 2)
  ).toUpperCase();

  if (!image) {
    return (
      <div className={`w-${size} h-${size} inline-flex items-center justify-center rounded-full bg-cyan`}>
        <span className="text-xs font-medium leading-none text-white">{initial}</span>
      </div>
    );
  }

  return <img className={`w-${size} h-${size} rounded-full ring-2 ring-white`} src={image} alt="" />;
};

export default Avatar;
