import { makeObservable, action, observable, computed } from 'mobx';

class OnboardingModeStore {
  KEY = 'onboarding_mode';
  enabled = 0;

  setValue = (value: number): void => {
    this.enabled = value;
    localStorage.setItem(this.KEY, this.enabled.toString());
  };

  get isOn(): boolean {
    return this.enabled === 1;
  }

  get isOff(): boolean {
    return this.enabled === 0;
  }

  constructor() {
    const value = localStorage.getItem(this.KEY) === '1' ? 1 : 0;
    this.setValue(value);

    makeObservable(this, {
      enabled: observable,
      setValue: action,
      isOn: computed,
      isOff: computed,
    });
  }
}

const store = new OnboardingModeStore();
export { store as OnboardingModeStore };
