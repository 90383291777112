import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { DateField, SelectField } from 'components';
import { useAuthState, useDeviceState, useLocale } from 'hooks';
import { DeviceModel } from 'models/device';
import { getNameByTypeId } from 'utils/helper';
import { DeliveryOptions } from '../../AddPatientModal.types';
import { deliveryOptions } from '../../AddPatientModal';
import { Features } from 'models/medic';
import Alert from 'components/Alert/Alert';

import DeliveryDetailsFields from './DeliveryDetailsFields';

interface FormikType {
  street?: string;
  city: string;
  streetNumber: string;
  postalCode: string;
  company?: string;
  sendDeviceToPatient: boolean;
  mobileNumber?: string;
  termsAndConditionsConfirmation: boolean;
  devices: { label: string; value: string } | null;
  selectedDelivery: {
    label: string;
    value: DeliveryOptions;
  };
  hasDeliveryDateTelemetrie: boolean;
  deliveryDateTelemetrie: string;
  gkvFlatrate: boolean;
}

export const AssignTelemonitoringStep = () => {
  const { formatMessage } = useLocale();
  const { devices } = useDeviceState();
  const { values, errors, setFieldValue } = useFormikContext<FormikType>();
  const { devices: selectedDevices, selectedDelivery, hasDeliveryDateTelemetrie, gkvFlatrate } = values;
  const today = new Date();
  const availableDeliveryDate = new Date(today);
  availableDeliveryDate.setDate(today.getDate() + 3);
  const { profile } = useAuthState();

  const showAlert = gkvFlatrate;
  const isSelectDisabled = gkvFlatrate;

  const formatLabel = (device: DeviceModel, limit: number) => {
    const label = device.label ? device.label : device.imei;

    return label?.substring(0, limit);
  };

  const requireDeliveryValues = values.sendDeviceToPatient;
  const showDeliveryDetails = requireDeliveryValues && selectedDelivery?.value === DeliveryOptions.PINZON;

  const hasPINZONDeliveryOption = !profile?.hasFeature(Features.DISABLE_PINZON_DELIVERY);
  const hasManualBPDeliveryOption = !profile?.hasFeature(Features.DISABLE_MANUAL_BP);

  useEffect(() => {
    if (selectedDelivery?.value === DeliveryOptions.MANUAL_BP) {
      window.location.hash = 'terms-and-conditions';
    }
  }, [selectedDelivery]);

  useEffect(() => {
    if (gkvFlatrate) {
      setFieldValue('selectedDelivery', {
        label: formatMessage({ id: 'enroll_new_patient.step.delivery.option.manual_bp' }),
        value: DeliveryOptions.MANUAL_BP,
      });

      // setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeliveryDateCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    if (!value) {
      setFieldValue('deliveryDateTelemetrie', null);
    }

    setFieldValue('hasDeliveryDateTelemetrie', value, true);
  };

  return (
    <>
      <h2 className="mb-4 text-lg">{formatMessage({ id: 'enroll_new_patient.step.telemonitoring.title' })}</h2>
      <div className="mt-5">
        <SelectField
          id="selectedDelivery"
          name="selectedDelivery"
          required
          options={deliveryOptions(formatMessage, {
            hasPINZONDeliveryOption,
            hasManualBPDeliveryOption,
          })}
          labelId="enroll_new_patient.step.delivery.label"
          values={selectedDelivery}
          placeholderId="enroll_new_patient.step.delivery.default_option"
          className="pii"
          paddingT={3}
          paddingR={0}
          marginTop={0}
          isSearchable={false}
          isError={!!errors.selectedDelivery}
          disabled={isSelectDisabled}
        />

        {requireDeliveryValues && selectedDelivery?.value === DeliveryOptions.PRAXIS && (
          <SelectField
            name="devices"
            options={(devices || [])
              .filter((device) => !Boolean(device.patient_id))
              .map((device: DeviceModel) => ({
                value: device.id as string,
                label: `${formatLabel(device, 8)}-${getNameByTypeId(device.type)}` as string,
              }))}
            labelId="patients.device_number"
            values={selectedDevices}
            placeholderId="enroll_new_patient.step.device.default_option"
            required
            className="pii"
            paddingT={3}
            paddingR={0}
            marginTop={0}
            isError={!!errors.devices}
            isSearchable={true}
          />
        )}
        {showDeliveryDetails && (
          <>
            <DeliveryDetailsFields />

            <div>
              <label className="flex mt-5">
                <input
                  type="checkbox"
                  className="form-checkbox rounded h-5 w-5 text-cyan"
                  name="hasDeliveryTelemetrie"
                  checked={hasDeliveryDateTelemetrie}
                  onChange={handleDeliveryDateCheckbox}
                />
                <span className="ml-2 text-gray">
                  {formatMessage({ id: 'enroll_new_patient.delivery_date.checkbox' })}
                </span>
              </label>

              {hasDeliveryDateTelemetrie && (
                <DateField
                  name="deliveryDateTelemetrie"
                  labelId="enroll_new_patient.delivery_date.datepicker"
                  minDate={availableDeliveryDate}
                  required
                  marginTop={3}
                  classes={{
                    fieldClassName: `mt-1 shadow-sm block w-full sm:text-sm border-${
                      errors.deliveryDateTelemetrie ? 'red' : 'gray-light'
                    } ${
                      errors.deliveryDateTelemetrie &&
                      'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
                    } text-gray placeholder-gray-light rounded-md pii`,
                  }}
                />
              )}
            </div>
          </>
        )}

        {showAlert && (
          <div className="mt-4">
            <Alert text={formatMessage({ id: 'enroll_new_patient.add_dmp.info_delivery' })} />
          </div>
        )}
      </div>
    </>
  );
};
