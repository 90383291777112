import React from 'react';

const ContentIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="26px"
    height="48px"
    viewBox="0 0 55 48"
    enableBackground="new 0 0 55 48"
    className={className}
  >
    <path
      fill="none"
      stroke="#A4A4A4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M52.433,12.421  c-0.273-1.1-0.835-2.108-1.625-2.922c-0.791-0.814-1.781-1.405-2.873-1.714C43.948,6.811,28,6.811,28,6.811s-15.949,0-19.936,1.066  c-1.091,0.309-2.082,0.9-2.87,1.714c-0.791,0.815-1.352,1.822-1.626,2.922c-0.73,4.046-1.087,8.152-1.066,12.263  c-0.026,4.143,0.33,8.278,1.066,12.355c0.303,1.066,0.877,2.035,1.665,2.816c0.788,0.779,1.764,1.343,2.832,1.635  C12.051,42.649,28,42.649,28,42.649s15.948,0,19.935-1.066c1.092-0.309,2.082-0.901,2.873-1.714c0.79-0.815,1.352-1.822,1.625-2.923  c0.725-4.016,1.08-8.091,1.066-12.17C53.526,20.634,53.169,16.498,52.433,12.421z"
    />
    <path
      fill="none"
      stroke="#A4A4A4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22.784,32.355  l13.329-7.579l-13.329-7.58V32.355z"
    />
  </svg>
);

export default ContentIcon;
