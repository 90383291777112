import React, { useEffect } from 'react';

import { Snackbar } from 'components/Snackbar/Snackbar';
import { Confetti } from 'components/Confetti/Confetti';
import { LowResolutionNotification } from 'components/LowResolution/LowResolution';
import { useLocale } from 'hooks';

import AppRoutes from './routing/AppRoutes';
import { AppRoute } from './routing/AppRoute.enum';
import { AddPatientModalContainer } from './shared/addPatientModal/AddPatientModalContainer';
import { AddMedicModalContainer } from './shared/addMedicModal/AddMedicModalContainer';
import { AddDeviceModelContainer } from './shared/addDeviceModel/AddDeviceModelContainer';
import { InviteMemberContainer } from './settings/Members/InviteMember/InviteMemberContainer';

// import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
// import useWindowSize from 'react-use/lib/useWindowSize';

const App: React.FC = () => {
  const { locale } = useLocale();

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <>
      <LowResolutionNotification exclude={[AppRoute.resetPasswordApp]} />
      <AppRoutes />
      <Snackbar />
      <AddPatientModalContainer />
      <AddMedicModalContainer />
      <AddDeviceModelContainer />
      <InviteMemberContainer />
      <Confetti />
    </>
  );
};

export default App;
