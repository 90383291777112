import React from 'react';

import { LineChartCapsuleProps } from './capsuleDot.types';

export const LineChartCapsule = (props: LineChartCapsuleProps) => {
  const { cx, cy, payload, handleMouseMove, handleMouseOut } = props;

  return (
    <>
      {payload && payload.medicines && payload.medicines.length > 0 ? (
        <>
          <svg
            x={cx - 12}
            y={cy - 12}
            width={30}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute"
            onMouseOver={handleMouseMove}
            onMouseOut={handleMouseOut}
          >
            <circle cx="10" cy="10" r="10" fill="#095C70" />
            <rect
              x="11.5103"
              y="3.29663"
              width="5.60595"
              height="14.3777"
              rx="3.30298"
              transform="rotate(30 11.5103 3.29663)"
              fill="#FFAB48"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="4" width="10" height="14">
              <rect
                x="11.5103"
                y="3.29663"
                width="5.60595"
                height="14.3777"
                rx="3.30298"
                transform="rotate(30 11.5103 3.29663)"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0)">
              <path d="M5.5603 8.16235L17.6752 15.1569L12.6007 23.9461L0.485834 16.9516L5.5603 8.16235Z" fill="white" />
            </g>
          </svg>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
