import { ProfileChangeBody } from 'app/profile/Profile.types';
import { UserModel, UserType } from 'models/user';
import {
  LOGOUT,
  SET_AUTHORIZED,
  SET_ROLE,
  SET_TOKENS,
  SET_UNAUTHORIZED,
  START_AUTHORIZING,
  SET_IS_MEMBER,
  SET_IS_OWNER,
  SET_PROFILE,
  SET_PROFILE_LOADING,
} from '../authReducer/authReducer';
import { AuthAction } from '../authReducer/authReducer.types';

export const setAuthorized: (user: UserModel) => AuthAction = (user) => ({
  type: SET_AUTHORIZED,
  user,
});

export const setUnauthorized: () => AuthAction = () => ({
  type: SET_UNAUTHORIZED,
});

export const setTokens: (accessToken: string) => AuthAction = (accessToken) => ({
  type: SET_TOKENS,
  accessToken,
});

export const logout: () => AuthAction = () => ({
  type: LOGOUT,
});

export const startAuthorizing: () => AuthAction = () => ({
  type: START_AUTHORIZING,
});

export const setRole: (role: UserType) => AuthAction = (role) => ({
  type: SET_ROLE,
  role,
});

export const setIsMember: (isMember: boolean) => AuthAction = (isMember) => ({
  type: SET_IS_MEMBER,
  isMember,
});

export const setIsOwner: (isOwner: boolean) => AuthAction = (isOwner) => ({
  type: SET_IS_OWNER,
  isOwner,
});

export const setProfile: (profile: ProfileChangeBody) => AuthAction = (profile) => ({
  type: SET_PROFILE,
  profile,
});

export const setProfileLoading: (profileLoading: boolean) => AuthAction = (profileLoading) => ({
  type: SET_PROFILE_LOADING,
  profileLoading,
});
