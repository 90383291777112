import { MedicStateContextType } from '../medicContext/MedicContext.types';

import { MedicAction } from './medicReducer.types';

export const SET_MEDICS = 'set-medics';
export const SET_LOADING = 'set-loading';

export const medicReducer: (state: MedicStateContextType, action: MedicAction) => MedicStateContextType = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: MedicStateContextType, action: MedicAction) => MedicStateContextType> = {
    [SET_MEDICS]: (state, { medics }) => {
      return {
        ...state,
        medics,
      };
    },
    [SET_LOADING]: (state, { loading }) => ({
      ...state,
      loading,
    }),
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
