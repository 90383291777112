import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import 'react-datepicker/dist/react-datepicker.css';

import CloseIcon from 'components/Icons/CloseIcon';
import { useModalDispatch } from 'hooks';

type ModalWrapperProps = {
  children: React.ReactNode;
  isOpen: boolean;
  modalName: string;
  size?: ModalSize;
  onClose?: () => void;
};

export const enum ModalSize {
  SMALL = 'max-w-lg',
  MEDIUM = 'max-w-3xl',
}

const ModalWrapper = ({ children, isOpen, modalName, size = ModalSize.MEDIUM, ...props }: ModalWrapperProps) => {
  const { close } = useModalDispatch();

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      close(modalName);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center"
        open={isOpen}
        onClose={onClose}
      >
        <div className="md:my-8 md:w-2/6">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative bg-white rounded-lg shadow-xl transform transition-all md:w-full mx-auto ${size} md:h-full`}
            >
              <button className="text-gray-light absolute p-2 right-4 top-4 cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </button>
              <div className="pt-12 p-6 text-gray">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWrapper;
