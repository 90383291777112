import React from 'react';

const CheckboxOutlinedIcon = ({ className }: { className?: string }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.25" y="1.25" width="19.5" height="19.5" rx="9.75" fill="none" stroke="currentColor" strokeWidth="1.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0596 7.48969C15.7668 7.1968 15.2919 7.1968 14.999 7.48969L9.99409 12.4946L7.5418 10.0423C7.24891 9.7494 6.77404 9.7494 6.48114 10.0423C6.18825 10.3352 6.18825 10.8101 6.48114 11.103L9.36081 13.9826C9.38756 14.0225 9.41855 14.0603 9.45378 14.0956C9.74667 14.3885 10.2215 14.3885 10.5144 14.0956L16.0596 8.55035C16.3525 8.25746 16.3525 7.78258 16.0596 7.48969Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckboxOutlinedIcon;
