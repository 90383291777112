import { useContext } from 'react';

import { DeviceListStateContextType } from 'context/deviceList/deviceListContext/deviceListContext.types';
import { DeviceListStateContext } from 'context/deviceList/deviceListContext/deviceListContext';

export const useDeviceListState: () => DeviceListStateContextType = () => {
  const context = useContext(DeviceListStateContext);

  if (context === undefined) {
    throw new Error('useDeviceListState must be used within an DeviceListContextController');
  }

  return context;
};
