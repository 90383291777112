import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'classnames';

import { Logo } from 'components/Logo/Logo';
import Avatar from 'components/Avatar/Avatar';
import { AppRoute } from 'app/routing/AppRoute.enum';

import { ListItem, ListItemButton, MainContainer } from './styled';
import { SideBarProps } from './SideBar.types';

const SideBar: FC<SideBarProps> = ({ topItems, bottomItems, firstname, lastname, username, children }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV || '';

  const environmentTopItems = topItems?.filter(
    (item) => !item.environments || item.environments?.includes('all') || item.environments?.includes(deployEnv),
  );

  const isProfile = pathname === AppRoute.profile;

  return (
    <div className="flex flex-row h-full w-screen">
      <nav className="fixed min-h-screen z-10 bg-white justify-between flex flex-col border-r-2 border-gray-border">
        <div className="flex flex-col items-center mt-10 mb-10">
          <div className="cursor-pointer" onClick={() => push(AppRoute.home)}>
            <Logo />
          </div>
          {environmentTopItems && (
            <div className="mt-20">
              <ul>
                {environmentTopItems.map((item, index) => (
                  <ListItem
                    key={index}
                    className={clsx('mb-6', item.isSelected && 'selected', item.isSelected && 'border-cyan')}
                  >
                    <ListItemButton
                      data-testid={item.testId}
                      id={item.id}
                      onClick={item.onClick ? item.onClick : () => push(item.path ?? '')}
                      className={`flex items-center justify-center p-1 w-9 h-9 md:ml-7 md:mr-7 ml-4 mr-4 ${
                        item.isSelected ? 'text-gray' : 'text-gray-light'
                      }`}
                    >
                      {item.icon}
                    </ListItemButton>
                  </ListItem>
                ))}
              </ul>
            </div>
          )}
        </div>
        {bottomItems && (
          <div className="mb-10 flex flex-col items-center">
            <ul>
              {bottomItems.map((item, index) => (
                <ListItem key={index} className={clsx('mb-6', item.isSelected && 'selected')}>
                  <ListItemButton
                    data-testid={item.testId}
                    id={item.id}
                    onClick={item.onClick ? item.onClick : () => push(item.path ?? '')}
                    className={`p-1 md:ml-7 md:mr-7 ml-4 mr-4 ${item.isSelected ? 'text-gray' : 'text-gray-light'}`}
                  >
                    {item.icon}
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem className={clsx(isProfile && 'selected', 'flex flex-col items-center')}>
                <ListItemButton data-testid="e2e-profile-btn" onClick={() => push(AppRoute.profile)} className="pii">
                  <Avatar firstname={firstname} lastname={lastname} username={username || ''} size={10} />
                </ListItemButton>
              </ListItem>
            </ul>
          </div>
        )}
      </nav>
      <MainContainer className="relative md:ml-24 text-gray-700 bg-gray-100 h-screen w-screen overflow-x-hidden">
        {children}
      </MainContainer>
    </div>
  );
};

export default SideBar;
