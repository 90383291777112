export type UserModel = {
  id: string;
  email: string;
  name: string;
  firstname?: string;
  lastname?: string;
  language?: string;
  status?: UserStatus;
  memberId?: string;
  accountEmail?: string;
};

export enum UserType {
  Admin = 1,
  Medic = 2,
  Patient = 3,
  Member = 4,
}

export enum UserStatus {
  Pending = 1,
  Ongoing = 2,
  Completed = 3,
  Discarded = 4,
}
