import React, { ReactNode } from 'react';

type AlertProps = {
  text?: string;
  children?: ReactNode;
  icon?: ({ className }: { className?: string }) => JSX.Element;
};

const Alert = ({ text, children, icon }: AlertProps) => {
  const Icon = icon;

  return (
    <div className="text-base rounded p-3 bg-gray-superLight">
      {children || (
        <>
          {Icon && <Icon className="w-5 h-5 text-gray" />}
          <p>{text}</p>
        </>
      )}
    </div>
  );
};

export default Alert;
