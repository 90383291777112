import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog, Transition } from '@headlessui/react';
import { Form, FormikErrors, FormikProps } from 'formik';
import { FormattedHTMLMessage } from 'react-intl';

import CloseIcon from 'components/Icons/CloseIcon';
import { useAuthState, useLocale, useModalState } from 'hooks';
import DeviceContextController from 'context/devices/deviceContextController/deviceContextController';
import { AppStyled } from '../styled';
import Button from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import { Features } from 'models/medic';
import { FormatMessageFunction } from 'utils/types';

import { BasicInfoStep } from './steps/basicInfoStep/BasicInfoStep';
import { RemarksInfoStep } from './steps/remarksInfoStep/RemarksInfoStep';
import {
  AddPatientBody,
  AddPatientModalProps,
  DeliveryOptions,
  AddPatientFormSteps,
  StepState,
  AddPatientFormStep,
} from './AddPatientModal.types';
import { Assign24hDiagnosisStep } from './steps/assignDeviceInfoStep/Assign24hDiagnosisStep';
import { AssignTelemonitoringStep } from './steps/assignDeviceInfoStep/AssignTelemonitoringStep';
import FormStepsStatus from './FormStepsStatus';
import { DeviceChoiceStep } from './steps/deviceChoiceStep/DeviceChoiceStep';
import AddPatientFormikWrapper from './AddPatientFormikWrapper';

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  :hover {
    cursor: pointer;
  }
`;

const isBasicInfoComplete = (values: AddPatientBody): boolean =>
  !!values.firstName && !!values.lastName && !!values.birthday && !!values.insuredType;

const hasBasicInfoErrors = (errors: FormikErrors<AddPatientBody>): boolean =>
  !!errors.firstName || !!errors.lastName || !!errors.birthday;

const is24hComplete = (values: AddPatientBody): boolean =>
  ['weight', 'height', 'street', 'city', 'postalCode', 'streetNumber', 'mobileNumber'].every(
    (field) => !!values[field as keyof AddPatientBody],
  );

const has24hErrors = (errors: FormikErrors<AddPatientBody>): boolean =>
  ['weight', 'height', 'street', 'city', 'postalCode', 'streetNumber', 'mobileNumber'].some(
    (field) => !!errors[field as keyof AddPatientBody],
  );

const isTeleCompleted = (values: AddPatientBody) =>
  values.selectedDelivery?.value === DeliveryOptions.PINZON
    ? !!values.street && !!values.city && !!values.postalCode && !!values.streetNumber && !!values.mobileNumber
    : values.selectedDelivery?.value === DeliveryOptions.MANUAL_BP || !!values.devices;

const hasTeleErrors = (values: AddPatientBody, errors: FormikErrors<AddPatientBody>) => {
  if (errors.selectedDelivery) return true;

  return values.selectedDelivery?.value === DeliveryOptions.PINZON
    ? !!errors.street && !!errors.city && !!errors.postalCode && !!errors.streetNumber && !!errors.mobileNumber
    : !!errors.devices;
};

export const deliveryOptions = (
  formatMessage: FormatMessageFunction,
  features: { hasManualBPDeliveryOption: boolean; hasPINZONDeliveryOption: boolean },
) => {
  const options = [
    {
      label: formatMessage({ id: 'enroll_new_patient.step.delivery.option.in_praxis' }),
      value: DeliveryOptions.PRAXIS,
    },
  ];

  if (features.hasManualBPDeliveryOption) {
    options.push({
      label: formatMessage({ id: 'enroll_new_patient.step.delivery.option.manual_bp' }),
      value: DeliveryOptions.MANUAL_BP,
    });
  }

  if (features.hasPINZONDeliveryOption) {
    options.push({
      label: formatMessage({ id: 'enroll_new_patient.step.delivery.option.pinzon' }),
      value: DeliveryOptions.PINZON,
    });
  }

  return options;
};

const stepsMap: Record<
  AddPatientFormSteps,
  (direction: 'forward' | 'back', values: AddPatientBody, showDiagnosis24h: boolean) => AddPatientFormSteps
> = {
  [AddPatientFormSteps.services]: () => AddPatientFormSteps.basicInfo,
  [AddPatientFormSteps.basicInfo]: (direction, values, showDiagnosis24h) => {
    if (direction === 'back') return AddPatientFormSteps.services;

    if (!showDiagnosis24h) return AddPatientFormSteps.telemonitoring;

    if (!values.send24HDiagnosis && !values.sendDeviceToPatient) return AddPatientFormSteps.final;

    if (!values.send24HDiagnosis) return AddPatientFormSteps.telemonitoring;

    return AddPatientFormSteps.diagnosis24;
  },
  [AddPatientFormSteps.diagnosis24]: (direction, values, showDiagnosis24h) => {
    if (direction === 'back') return AddPatientFormSteps.basicInfo;

    return values.sendDeviceToPatient ? AddPatientFormSteps.telemonitoring : AddPatientFormSteps.final;
  },
  [AddPatientFormSteps.telemonitoring]: (direction, values, showDiagnosis24h) => {
    if (direction === 'forward') return AddPatientFormSteps.final;

    return values.send24HDiagnosis ? AddPatientFormSteps.diagnosis24 : AddPatientFormSteps.basicInfo;
  },
  [AddPatientFormSteps.final]: (direction, values, showDiagnosis24h) => {
    if (!values.send24HDiagnosis && !values.sendDeviceToPatient) return AddPatientFormSteps.basicInfo;

    return values.sendDeviceToPatient ? AddPatientFormSteps.telemonitoring : AddPatientFormSteps.diagnosis24;
  },
};

const createInitSteps = (showDiagnosis24h: boolean): Record<AddPatientFormSteps, AddPatientFormStep> => {
  const initSteps: Record<AddPatientFormSteps, AddPatientFormStep> = {
    [AddPatientFormSteps.services]: {
      name: 'enroll_new_patient.step.device_choice',
      value: AddPatientFormSteps.services,
      state: showDiagnosis24h ? StepState.inProgress : StepState.hidden,
    },
    [AddPatientFormSteps.basicInfo]: {
      name: 'enroll_new_patient.step.basic_info',
      value: AddPatientFormSteps.basicInfo,
      state: showDiagnosis24h ? StepState.active : StepState.inProgress,
    },
    [AddPatientFormSteps.diagnosis24]: {
      name: 'enroll_new_patient.step.diagnosis24',
      value: AddPatientFormSteps.diagnosis24,
      state: showDiagnosis24h ? StepState.inactive : StepState.hidden,
    },
    [AddPatientFormSteps.telemonitoring]: {
      name: 'enroll_new_patient.step.telemonitoring',
      value: AddPatientFormSteps.telemonitoring,
      state: showDiagnosis24h ? StepState.inactive : StepState.active,
    },
    [AddPatientFormSteps.final]: {
      name: 'enroll_new_patient.step.final',
      value: AddPatientFormSteps.final,
      state: StepState.active,
    },
  };

  return initSteps;
};

export const AddPatientModal = ({
  currentStep,
  gender,
  loading,
  onAddNewPatient,
  handleGenderChange,
  setCurrentStep,
  onClose,
}: AddPatientModalProps) => {
  const { isOpen } = useModalState('add-patient');
  const { formatMessage } = useLocale();
  const { profile } = useAuthState();

  const has24hDiagnosisFeature = !!profile?.hasFeature(Features.DIAGNOSIS_24);

  const [steps, setSteps] = useState<Record<AddPatientFormSteps, AddPatientFormStep>>(
    createInitSteps(has24hDiagnosisFeature),
  );

  useEffect(() => {
    if (isOpen) setSteps({ ...createInitSteps(has24hDiagnosisFeature) });
  }, [isOpen, has24hDiagnosisFeature]);

  const handleSubmitPatient = (values: AddPatientBody) => {
    onAddNewPatient(values);
  };

  const handleStepChange = (
    direction: 'forward' | 'back',
    values: AddPatientBody,
    errors: FormikErrors<AddPatientBody>,
  ) => {
    const nextStep = stepsMap[currentStep]?.(direction, values, has24hDiagnosisFeature);
    const isDevicesCompleted = true;
    const isFinalCompleted =
      !!values.termsAndConditionsConfirmation && (!values.sendAppInvite || (values.sendAppInvite && !!values.email));
    const hasFinalErrors = !!errors.termsAndConditionsConfirmation || (values.sendAppInvite && !!errors.email);

    const tempSteps = { ...steps };

    Object.keys(tempSteps).forEach((key: unknown) => {
      const tempKey = key as AddPatientFormSteps;

      if (tempSteps[tempKey].state === StepState.inProgress) {
        tempSteps[tempKey] = {
          ...tempSteps[tempKey],
          state: StepState.active,
        };
      }

      if (tempSteps[tempKey].value === nextStep) {
        tempSteps[tempKey] = {
          ...tempSteps[tempKey],
          state: StepState.inProgress,
        };
      }
    });

    if (isDevicesCompleted) {
      tempSteps[AddPatientFormSteps.services].state = StepState.completed;
    }

    if (isBasicInfoComplete(values)) {
      tempSteps[AddPatientFormSteps.basicInfo].state = StepState.completed;
    } else if (hasBasicInfoErrors(errors)) {
      tempSteps[AddPatientFormSteps.basicInfo].state = StepState.invalid;
    }

    if (is24hComplete(values)) {
      tempSteps[AddPatientFormSteps.diagnosis24].state = StepState.completed;
    } else if (has24hErrors(errors)) {
      tempSteps[AddPatientFormSteps.diagnosis24].state = StepState.invalid;
    }

    if (isTeleCompleted(values)) {
      tempSteps[AddPatientFormSteps.telemonitoring].state = StepState.completed;
    } else if (hasTeleErrors(values, errors)) {
      tempSteps[AddPatientFormSteps.telemonitoring].state = StepState.invalid;
    }

    if (isFinalCompleted) {
      tempSteps[AddPatientFormSteps.final].state = StepState.completed;
    } else if (hasFinalErrors) {
      tempSteps[AddPatientFormSteps.final] = {
        ...tempSteps[AddPatientFormSteps.final],
        state: StepState.invalid,
      };
    }

    if (!values.send24HDiagnosis) {
      tempSteps[AddPatientFormSteps.diagnosis24].state = StepState.inactive;
    }

    if (!values.sendDeviceToPatient) {
      tempSteps[AddPatientFormSteps.telemonitoring].state = StepState.inactive;
    }

    // Override steps if diagnosis24h is not available
    if (!has24hDiagnosisFeature) {
      tempSteps[AddPatientFormSteps.diagnosis24].state = StepState.hidden;
      tempSteps[AddPatientFormSteps.services].state = StepState.hidden;
    }

    if (nextStep !== undefined) {
      setCurrentStep(nextStep);
    }

    setSteps({ ...tempSteps });
  };

  const handleDeviceChange = (
    stepValue: AddPatientFormSteps.diagnosis24 | AddPatientFormSteps.telemonitoring,
    value: boolean,
  ) => {
    setSteps((prevSteps) => ({
      ...prevSteps,
      [stepValue]: {
        ...prevSteps[stepValue],
        state: value ? StepState.active : StepState.inactive,
      },
    }));
  };

  const isBackBtnShown = () => {
    if (has24hDiagnosisFeature) {
      return currentStep !== AddPatientFormSteps.services;
    } else {
      return currentStep !== AddPatientFormSteps.basicInfo;
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto flex items-start justify-center"
        open={isOpen}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-95 transition-opacity" />
          </Transition.Child>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            data-testid="e2e-enroll-patient-modal"
            className="w-3/5 max-w-screen-sm bg-white rounded-lg shadow-xl transform transition-all sm:my-16"
          >
            <AddPatientFormikWrapper onSubmit={handleSubmitPatient}>
              {({ isValid, values, errors }: FormikProps<AddPatientBody>) => (
                <Form>
                  <AppStyled>
                    <CloseIconWrapper className="m-3" onClick={onClose}>
                      <CloseIcon color="#dcdcdc" />
                    </CloseIconWrapper>
                    <div className="p-10">
                      <div className="text-center">
                        <Dialog.Title as="h3" className="text-xl font-bold text-gray">
                          {formatMessage({ id: 'enroll_new_patient.title' })}
                        </Dialog.Title>
                      </div>

                      <div className="mt-7">
                        <div className="mt-3 max-w-xl mx-auto">
                          <FormStepsStatus steps={Object.values(steps)} currentStep={currentStep} />
                        </div>
                        <DeviceContextController>
                          <div className="mt-12 max-w-md mx-auto">
                            {currentStep === AddPatientFormSteps.services && (
                              <DeviceChoiceStep handleDeviceChange={handleDeviceChange} />
                            )}
                            {currentStep === AddPatientFormSteps.basicInfo && (
                              <BasicInfoStep values={values} gender={gender} handleGenderChange={handleGenderChange} />
                            )}
                            {currentStep === AddPatientFormSteps.diagnosis24 && <Assign24hDiagnosisStep />}
                            {currentStep === AddPatientFormSteps.telemonitoring && <AssignTelemonitoringStep />}
                            {currentStep === AddPatientFormSteps.final && <RemarksInfoStep />}
                          </div>
                        </DeviceContextController>
                      </div>
                    </div>

                    {currentStep === AddPatientFormSteps.final &&
                      !profile?.price_change_acknowledged &&
                      !!values.send24HDiagnosis && (
                        <div className="max-w-md mx-auto mb-10">
                          <Alert>
                            <FormattedHTMLMessage
                              tagName="p"
                              id="diagnosis.profile.price_update"
                              values={{
                                link: 'https://pinzon-static.fra1.digitaloceanspaces.com/PINZON_Health_GmbH_AGB.pdf',
                                className: 'text-cyan',
                              }}
                            />
                          </Alert>
                        </div>
                      )}

                    <div
                      className="p-4 bg-gray-background sm:flex sm:flex-row-reverse"
                      style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}
                    >
                      {currentStep === AddPatientFormSteps.final && (
                        <Button
                          testId="e2e-enroll-finish-btn"
                          type="submit"
                          variant="primary"
                          className="ml-3"
                          disabled={!isValid}
                          paddingY={1}
                          paddingX={3}
                          btnLoader={loading}
                        >
                          {formatMessage({ id: 'common.finish' })}
                        </Button>
                      )}
                      {currentStep !== AddPatientFormSteps.final && (
                        <Button
                          testId="e2e-enroll-next-btn"
                          variant="primary"
                          className="ml-3"
                          paddingY={1}
                          paddingX={3}
                          onClick={() => handleStepChange('forward', values, errors)}
                        >
                          {formatMessage({ id: 'common.next' })}
                        </Button>
                      )}
                      {isBackBtnShown() && (
                        <Button
                          testId="e2e-enroll-back-btn"
                          variant="inherit"
                          className="ml-3 border-2 border-gray-light"
                          paddingY={1}
                          paddingX={3}
                          onClick={() => handleStepChange('back', values, errors)}
                        >
                          {formatMessage({ id: 'common.back' })}
                        </Button>
                      )}
                    </div>
                  </AppStyled>
                </Form>
              )}
            </AddPatientFormikWrapper>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
