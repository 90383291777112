import React from 'react';

const ChevronRightIcon = ({ className }: { className?: string }) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.771418 0.50384C1.11313 0.162131 1.66715 0.162131 2.00886 0.50384L6.67552 5.17051C7.01723 5.51222 7.01723 6.06624 6.67552 6.40794L2.00886 11.0746C1.66715 11.4163 1.11313 11.4163 0.771418 11.0746C0.42971 10.7329 0.42971 10.1789 0.771418 9.83717L4.81937 5.78923L0.771418 1.74128C0.42971 1.39957 0.42971 0.845549 0.771418 0.50384Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightIcon;
