import React from 'react';

import { CardProps } from './Card.types';
import { Card as BaseCard } from './styled';

export const Card = ({ children, className, fromReport }: CardProps) => (
  <BaseCard fromReport={fromReport} className={className}>
    {children}
  </BaseCard>
);
