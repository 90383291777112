import { useContext } from 'react';

import { MembersContext } from 'context/members/membersContextController/MembersContextController';

export const useMembers = () => {
  const context = useContext(MembersContext);

  if (!context) {
    throw new Error('useMembers must be used within a MembersContextController');
  }

  return context;
};
