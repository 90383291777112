import { DeviceOrderModel } from 'app/shared/addPatientModal/AddPatientModal.types';

import { BpChartPoint, weekTrendsChartItem } from './bp';
import { ScaleChartPoint } from './scale';
import { BpAlertTypeModel } from './bpAlertType';
import { BpReadingModel, BwReadingModel } from './bpReading';
import { DeviceModel } from './device';
import { MedicineModel, MedicineLogModel } from './medication';
import { UserStatus } from './user';
import { noteData } from './weeklyReport';
import { TaskModel } from './task';
import { DiagnosisPatientModel } from './diagnosis';

export type PatientModel = {
  id?: string;
  id_auto?: number;
  is_active?: boolean;
  email: string;
  gender: number;
  fullname?: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  password?: string;
  mobile_no?: string;
  phone_no?: string;
  patient_id?: string;
  patient_id_vps?: string;
  address?: string;
  date_of_birth?: string;
  status: UserStatus;
  medic_id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  profileDeleteRequest?: boolean;
  hashedEmail?: string;
  isEditFlag?: boolean;
  user_type?: number;
  patient_device?: string;
  device?: Array<DeviceModel>;
  chatId?: string;
  remarks?: string;
  bpReadings?: Array<BpReadingModel>;
  currentReading?: BpReadingModel;
  notes: noteData[];
  numberOfweeks?: number;
  timeGraph?: {
    sinceDays: number;
    sinceHours: number;
    sinceMinutes: number;
    tookOn: string;
  };
  bpAlertType?: BpAlertTypeModel;
  avgAndDelta?: {
    averageBloodPressure: {
      systolic: number;
      diastolic: number;
    };
    delta: {
      systolic: number;
      diastolic: number;
    };
  };
  oneWeekAvgAndDelta?: {
    averageBloodPressure: {
      systolic: number;
      diastolic: number;
    };
    delta: {
      systolic: number;
      diastolic: number;
    };
  };
  technicalIssues?: {
    isSignalWeak: boolean;
    isBatteryLow: boolean;
    isHeartbeatIrregular: boolean;
    isBodyWeightDeviceBatteryLow: boolean;
    isBodyWeightDeviceSignalLow: boolean;
  };
  bp?: BpChartPoint[];
  changeToPreviousWeek?: {
    systolic: number;
    diastolic: number;
  };
  changeTotal?: {
    systolic: number;
    diastolic: number;
  };
  oneWeekAverage?: {
    systolic: number;
    diastolic: number;
  };
  diastolic?: number;
  systolic?: number;
  durationOfTreatment?: {
    totalWeeks: number;
    weeks: number;
  };
  hasReadings?: boolean;
  lastMeasurment?: BpReadingModel;
  priorityLines?: number;
  weeklyTrendData?: weekTrendsChartItem[];
  checked?: boolean;
  checked_reading_id?: string;
  is_treatment_going_on?: boolean;
  treatment: StoppedTreatmentPatientModel[];
  bw?: ScaleChartPoint[];
  tasks?: TaskModel[];
  weeklyReportSent: [];
  measurementEvaluated: [];
  insured_type?: string;
  goal_systolic: number;
  goal_diastolic: number;
  alert_systolic_high: number;
  alert_systolic_low: number;
  alert_diastolic_high: number;
  alert_diastolic_low: number;
  send_daily_alerts: boolean;
  notification_email: string;
  get_report_autopilot?: boolean;
  mobileDevicesCount: number;
  notificationscount: CountModel[];
  street: string;
  city: string;
  street_number: string;
  postal_code: string;
  state: string;
  country: string;
  assigned_doctor: string;
  assigned_nurse: string;
  diagnosis: DiagnosisPatientModel[];
  hasDiagnosis: boolean;
  orders: PatientOrdersModel;
  medications: MedicineModel[] | null;
};

export type PatientProfileModel = {
  id?: string;
  id_auto?: number;
  is_active?: boolean;
  email: string;
  fullname?: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  password?: string;
  gender: number;
  mobile_no?: string;
  phone_no?: string;
  patient_id_vps?: string;
  address?: string;
  date_of_birth?: string;
  medic_id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  profileDeleteRequest?: boolean;
  hashedEmail?: string;
  isEditFlag?: boolean;
  user_type?: number;
  patient_device?: string;
  device?: Array<DeviceModel>;
  chatId?: string;
  remarks?: string;
  insured_type?: string;
  height?: number;
  weight?: number;
  sms_reminder?: boolean;
  get_report_autopilot?: boolean;
  goal_systolic: number;
  goal_diastolic: number;
  send_daily_alerts: boolean;
  notification_email: string;
  bp?: BpChartPoint[];
  notes: noteData[];
  changeToPreviousWeek?: {
    systolic: number;
    diastolic: number;
  };
  changeTotal?: {
    systolic: number;
    diastolic: number;
  };
  oneWeekAverage?: {
    systolic: number;
    diastolic: number;
  };
  diastolic?: number;
  systolic?: number;
  durationOfTreatment?: {
    totalWeeks: number;
    weeks: number;
  };
  timeGraph?: {
    sinceDays: number;
    sinceHours: number;
    sinceMinutes: number;
    sinceWeeks: number;
    tookOn: string;
  };
  hasReadings?: boolean;
  lastMeasurment?: BpReadingModel;
  status?: string;
  medications?: MedicineModel[];
  medicationslogs?: MedicineLogModel[];
  country_code?: string;
  technicalIssues?: {
    isSignalWeak: boolean;
    isBatteryLow: boolean;
    isHeartbeatIrregular: boolean;
    isBodyWeightDeviceBatteryLow: boolean;
    isBodyWeightDeviceSignalLow: boolean;
  };
  hasBodyWeightReadings?: boolean;
  bw?: ScaleChartPoint[];
  weeklyTrendData?: weekTrendsChartItem[];
  is_treatment_going_on?: boolean;
  treatment: StoppedTreatmentPatientModel[];
  // Body weight Metrics
  bWchangeFromPreviousDay?: number;
  bWThreeDayChange?: number;
  bWSevenDayChange?: number;
  bWTotalChange?: number;
  bWLastMeasurment?: BwReadingModel;
  tasks?: TaskModel[];
  mobileDevicesCount: number;
  notificationscount: CountModel[];
  hasDiagnosis: boolean;
  diagnosis: DiagnosisPatientModel[];
  hasAppInvite: boolean;
  hasDeviceOrders: boolean;
  street: string;
  city: string;
  street_number: string;
  postal_code: string;
  state: string;
  country: string;
  disease_management_programs: DiseaseManagementPrograms[];
  orders: PatientOrdersModel;
  manual_bp_treatment: boolean;
  assigned_doctor: string;
  assigned_nurse: string;
  gkv_flatrate: boolean;
  insurance_name: string;
};

export type PatientOrdersModel = {
  telemonitoring: DeviceOrderModel[];
  diagnosis_24h: DeviceOrderModel[];
  hasOpenTelemonitoringOrder: boolean;
  hasOpenDiagnosis24hOrder: boolean;
};

export type StoppedTreatmentPatientModel = {
  start_date: string;
  end_date: string | null;
  reason: string | null;
};

export type PatientGoalModel = {
  goal_systolic: number;
  goal_diastolic: number;
};

export type PatientAlertModel = {
  alert_systolic_high: number;
  alert_systolic_low: number;
  alert_diastolic_high: number;
  alert_diastolic_low: number;
};

export type CountModel = {
  count: number;
};

export const enum DiseaseManagementPrograms {
  diabetesType1 = 0,
  diabetesType2 = 1,
  heartDisease = 2,
  heartFailure = 3,
  obesity = 4,
}
