import { DiseaseManagementPrograms } from 'models/patient';

export const enum DeliveryOptions {
  PRAXIS = 0,
  PINZON = 1,
  MANUAL_BP = 2,
}

export type AddPatientBody = {
  firstName: string;
  lastName: string;
  birthday?: Date;
  email: string;
  phoneNumber?: string;
  mobileNumber?: string;
  remarks?: string;
  patientIdPVS: string;
  devices?: { label: string; value: string } | null;
  countryCode?: string;
  insuredType: { label: string; value: string };
  insuranceName?: string;
  street: string;
  city: string;
  streetNumber: string;
  postalCode: string;
  company?: string;
  send24HDiagnosis?: boolean;
  sendAppInvite?: boolean;
  sendDeviceToPatient?: boolean;
  height: string;
  weight: string;
  goalSystolic?: number;
  goalDiastolic?: number;
  termsAndConditionsConfirmation?: boolean;
  selectedDelivery?: { label: string; value: DeliveryOptions } | null;
  diseaseManagementPrograms: DiseaseManagementPrograms[];
  hasDeliveryDateDiagnosis?: boolean;
  deliveryDateDiagnosis?: Date | null;
  hasDeliveryDateTelemetrie?: boolean;
  deliveryDateTelemetrie?: Date | null;
  smsReminder: boolean;
  getReportAutopilot: boolean;
  assignedDoctor: { label: string; value: string } | null;
  assignedNurse: { label: string; value: string } | null;
  gkvFlatrate: boolean;
};

export type ErrorBody = {
  firstName?: string;
  lastName?: string;
  birthday?: string;
  email?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  remarks?: string;
  patientIdPVS?: string;
  street: string;
  city: string;
  streetNumber: string;
  postalCode: string;
  insuredType?: { label: string; value: string } | string;
};

export type AddPatientModalProps = {
  onAddNewPatient: (values: AddPatientBody) => void;
  handleGenderChange: (gender: number | string) => void;
  onClose: () => void;
  setCurrentStep: (value: AddPatientFormSteps) => void;
  currentStep: AddPatientFormSteps;
  gender: number | null;
  loading: boolean;
};

export type StepsComponentProps = {
  parentContext?: string;
  gender?: number | null;
  values?: AddPatientBody;
  selfPayer?: boolean;
};

export type BasicInfoStepProps = StepsComponentProps & {
  handleGenderChange: (gender: number | string) => void;
};

export const enum AddPatientFormSteps {
  services,
  basicInfo,
  diagnosis24,
  telemonitoring,
  final,
}

export const enum StepState {
  active,
  inactive,
  inProgress,
  completed,
  invalid,
  hidden,
}

export type AddPatientFormStep = {
  name: string;
  value: AddPatientFormSteps;
  state: StepState;
};

export const enum DeviceOrderType {
  bpTelemonitoring = 0,
  bp24Diagnosis = 1,
}

export type DeviceOrderModel = {
  id?: string;
  patient_id: string;
  type: DeviceOrderType;
  street: string;
  number: string;
  postal_code?: string;
  city?: string;
  state?: string;
  country?: string;
  company?: string | null;
  mobile_no?: string;
  delivery_date: string | null;
};
