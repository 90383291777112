import { TaskModel } from 'models/task';
import { PatientModel, PatientProfileModel } from 'models/patient';
import { InsuredType } from 'app/patients/patientsSidebar/PatientsSidebar.types';

// A check applied to ensure that this patient is a outOfRangelist and to show red dot on  both call and email.
export const needTwoTasks = (bpStatus: string) => {
  return bpStatus === 'Red' || bpStatus === 'Yellow';
};

// A general check for showing red dot if tasks are available for medic.
export const determineRedDotOnTasks = (openTasks: TaskModel[], bpStatus: string) => {
  if (openTasks && openTasks.length > 0) {
    const calltask = openTasks.filter((patientTask) => patientTask?.task_type?.call === true).length > 0;
    const emailTask = openTasks.filter((patientTask) => patientTask?.task_type?.email === true).length > 0;
    const reportTask = openTasks.filter((patientTask) => patientTask?.task_type?.report === true).length > 0;

    return { calltask, emailTask, reportTask };
  }

  return { calltask: false, emailTask: false, reportTask: false };
};

export const bodyWeightOutOfRange = (patientProfile: PatientProfileModel | undefined) => {
  if (patientProfile && patientProfile.hasBodyWeightReadings) {
    const { bWchangeFromPreviousDay, bWThreeDayChange, bWSevenDayChange } = patientProfile;

    if (bWchangeFromPreviousDay && bWThreeDayChange && bWSevenDayChange) {
      if (bWchangeFromPreviousDay > 1 || bWThreeDayChange > 2 || bWSevenDayChange > 2.5) {
        return true;
      }

      return false;
    }
  }

  return false;
};

export const isPrivatePatient = (patient: PatientModel) => {
  return patient.insured_type === InsuredType.Privately;
};

export const patientsAlphabetSorter = (a: PatientModel, b: PatientModel) => {
  const aLastName = a.lastname?.toLowerCase() || '';
  const bLastName = b.lastname?.toLowerCase() || '';
  const aFirstName = a.firstname?.toLowerCase() || '';
  const bFirstName = b.firstname?.toLowerCase() || '';

  if (aLastName === bLastName) {
    return aFirstName.localeCompare(bFirstName);
  }

  return aLastName.localeCompare(bLastName);
};
