import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';

import { customAxios } from 'hooks/useCustomAxios/useCustomAxios';
import { useAuthState, useLocale } from 'hooks';
import { setLoading, setDeviceAssignments, setDevices } from '../deviceActionCreators/deviceActionCreator';
import { DeviceDispatchContext, DeviceStateContext } from '../deviceContext/deviceContext';
import { deviceReducer } from '../deviceReducer/deviceReducer';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { UserType } from 'models/user';
import { DeviceAssignmentBody } from 'models/device';

import { DeviceContextControllerProps } from './deviceContextController.types';

const DeviceContextController = ({ children }: DeviceContextControllerProps) => {
  const [state, dispatch] = useReducer(deviceReducer, {
    deviceAssignment: {},
    loading: false,
  });
  const { user, role } = useAuthState();
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();
  const [deviceLength, setDeviceLength] = useState<number[]>([]);

  const processDeviceWithPatient = ({ data, type }: DeviceAssignmentBody) => {
    dispatch(setLoading(true));
    customAxios({ method: 'post', url: '/devices/updatePatient/MultipleDevices', data: data })
      .then(({ data }) => {
        if (data) {
          dispatch(setLoading(false));
          deviceLength.push(data?.data?.device.length);
          setDeviceLength([...deviceLength]);

          if (deviceLength.length > 2) {
            deviceLength.shift();
            setDeviceLength([...deviceLength]);
          }

          if (type) {
            openSnackbar({
              severity: 'success',
              title:
                deviceLength[0] >= deviceLength[1]
                  ? formatMessage({ id: 'dashboard.snackbar.patients.device_status.success_title' })
                  : formatMessage({ id: 'dashboard.snackbar.patients.device_assign.success_title' }),
              description: formatMessage({ id: 'dashboard.snackbar.patients.stop_treatment.success_description' }),
              autoHideDuration: 5000,
            });
          }
          dispatch(setDeviceAssignments(data?.data || {}));
          dispatch(setLoading(false));
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        openSnackbar({
          severity: 'error',
          title: formatMessage({ id: 'dashboard.snackbar.patients.device.assigment_changed.error_title' }),
          description: formatMessage({ id: 'dashboard.snackbar.patients.device.assigment_changed.error_description' }),
          autoHideDuration: 5000,
        });
      });
  };

  const loadMedicDevices = () => {
    customAxios({ method: 'get', url: `/devices/medic/${user?.id}` })
      .then((res) => {
        if (res) {
          dispatch(setLoading(false));
          dispatch(setDevices(res?.data?.data || []));
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        openSnackbar({
          severity: 'error',
          title: formatMessage({
            id: 'dashboard.snackbar.enroll_new_patient.error_title',
          }),
          description: formatMessage({
            id: 'dashboard.snackbar.enroll_new_patient.error_description',
          }),
          autoHideDuration: 5000,
        });
      });
  };

  useEffect(() => {
    if (user?.id && role === UserType.Medic) {
      loadMedicDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, role]);

  return (
    <DeviceStateContext.Provider value={{ ...state, processDeviceWithPatient }}>
      <DeviceDispatchContext.Provider value={dispatch}>{children}</DeviceDispatchContext.Provider>
    </DeviceStateContext.Provider>
  );
};

export default DeviceContextController;
