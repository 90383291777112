export enum TaskTypes {
  REPORT = 'REPORT',
  INSPECTION = 'INSPECTION',
}
export enum TaskStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DISMISSED = 'DISMISSED',
}
export enum TaskExitCodes {
  SUCCESS = 'SUCCESS',
}
export enum TaskCreationPeriod {
  DURING = 1,
  AFTER = 2,
}

export type taskSetting = {
  daysInterval: number;
  creationPeriod: TaskCreationPeriod;
};

export const TaskSettings: { [key in TaskTypes]: taskSetting } = {
  [TaskTypes.REPORT]: {
    daysInterval: 7,
    creationPeriod: TaskCreationPeriod.DURING,
  },
  [TaskTypes.INSPECTION]: {
    daysInterval: 7,
    creationPeriod: TaskCreationPeriod.DURING,
  },
};
