import React from 'react';

const Clock24hIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 'currentColor',
  className = '',
  onClick = () => {},
}: {
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
  className?: string;
  onClick?: () => void;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}
    width={width}
    height={height}
    className={className}
    onClick={onClick}
  >
    <path
      d="M22 12V6C22 3.79086 20.2091 2 18 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H9"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M12 7V12L9 13" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12 18.5V18C12 16.8954 12.8954 16 14 16V16C15.1046 16 16 16.8954 16 18V18C16 18.6295 15.7036 19.2223 15.2 19.6L12 22H16"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 16V17C18 18.1046 18.8954 19 20 19H22M22 19V16M22 19V22"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22 12L20 10" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Clock24hIcon;
