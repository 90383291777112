import styled from 'styled-components';

export const UnitsContainer = styled.div`
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const CommitmentUnit = styled.div``;

export const CircleContainer = styled.div`
  padding: 0 5px;
  min-width: 50px;
  ${(props) =>
    props.dayNight &&
    `
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: rgba(174, 174, 174, 0.5);
  `}
`;
export const IconContainer = styled.div`
  min-width: 34px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;
export const TimeSlotContainer = styled.div`
  border-style: solid;
  border-width: 0 1px 0 0;
`;
export const Circle = styled.div`
  background: rgba(164, 164, 164, 0.4);
  border-radius: 50%;
  margin: auto;
`;

export const CommitmentLegend = styled.div``;
