import React from 'react';

const EuroIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 'currentColor',
  className = '',
  onClick = () => {},
}: {
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
  className?: string;
  onClick?: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    className={`h-6 w-6 ${className}`}
    // fill={color}
    stroke={stroke}
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      d="M21 6.40116C19.5177 4.91939 17.4518 4 15.1667 4C10.6563 4 7 7.58172 7 12C7 16.4183 10.6563 20 15.1667 20C17.4518 20 19.5177 19.0806 21 17.5988"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M3 14L16 14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 10L16 10" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EuroIcon;
