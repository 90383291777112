import styled from 'styled-components';

export const BetaTag = styled.div`
  display: inline-block;
  width: 60px;
  padding: 2px 10px;
  text-align: center;

  color: white;
  font-weight: 500;

  background: linear-gradient(
    242.12deg,
    #de9a14 -58.91%,
    #928435 -6.8%,
    #83803b 12.49%,
    #707a44 28.09%,
    #095c70 105.28%,
    #a4a4a4 105.28%
  );
  border-radius: 4px;
`;
