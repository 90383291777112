import { useContext } from 'react';

import { EmailStateContextType } from 'context/email/emailContext/EmailContext.types';
import { EmailStateContext } from 'context/email/emailContext/EmailContext';

export const useEmailState: () => EmailStateContextType = () => {
  const context = useContext(EmailStateContext);

  if (context === undefined) {
    throw new Error('useEmailState must be used within an EmailContextController');
  }

  return context;
};
