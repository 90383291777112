import React from 'react';

import { RemarkTooltipProps } from './RemarkTooltip.types';
import { RemarkBackPanel } from './styled';

export const RemarkTooltip = ({ tooltipText, children }: RemarkTooltipProps) => {
  const tipRef = React.createRef();

  const handleMouseEnter = () => {
    tipRef.current.style.opacity = 1;
    tipRef.current.style.marginLeft = '40px';
  };

  const handleMouseLeave = () => {
    tipRef.current.style.opacity = 0;
    tipRef.current.style.marginLeft = '10px';
  };

  return (
    <div className="relative flex items-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        className="absolute text-gray px-4 py-2 bg-gray-background rounded flex items-center transition-all duration-150 pointer-events-none left-full opacity-0"
        ref={tipRef}
      >
        <div className="absolute" style={{ left: '-6px', transform: 'rotate(45deg)' }} />
        <RemarkBackPanel className="whitespace-normal pointer-events-none">{tooltipText}</RemarkBackPanel>
      </div>
      {children}
    </div>
  );
};
