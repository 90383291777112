import { PatientModel, PatientProfileModel } from 'models/patient';
import { TaskModel } from 'models/task';
import { HEALTH_METRIC_AREAS } from 'utils/constants.enum';
import { TechnicalIssueModel } from 'models/technical_issue';
import { MedicineModel, MedicineLogModel } from 'models/medication';
import { OverallInfoType } from 'models/overallInfo';
import { BillingReportModel } from 'models/billingReport';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';
import { categoryOptions } from 'api/mocks/content';

import { Constant } from './constants.enum';

export const getFilterOption = (overallInfo, patientId) => {
  return PatientsCategory.All;

  // const technicalIssueIds = overallInfo?.technicalIssues?.map((patient) => patient.id);
  // const everythingGoodIds = overallInfo?.everythingGood?.map((patient) => patient.id);
  // const tasksIds = overallInfo?.tasks?.map((patient) => patient.id);

  // if (technicalIssueIds?.includes(patientId)) return PatientsCategory.TechnicalIssues;
  // else if (everythingGoodIds?.includes(patientId)) return PatientsCategory.EverythingGood;
  // else if (tasksIds?.includes(patientId)) return PatientsCategory.OpenTasks;

  // return PatientsCategory.BpOutOfRange;
};

export const filterStoppedTreatmentPatiens = (array: TaskModel[] | TechnicalIssueModel[]) => {
  if (array && array.length > 0)
    return array.filter((arr: TaskModel | TechnicalIssueModel) => arr.is_treatment_going_on === true);
  else return array;
};

export const getCategory = (category: number, formatMessage) => {
  return categoryOptions(formatMessage)?.find((op) => op.value === category)?.label;
};

export const filterByTasks = (array: TaskModel[], type: string[]) => {
  if (type && type.length > 0) return array.filter((arr) => type.find((t) => arr['task_type'][`${t}`] === true));
  else return array;
};

export const filterByTechnicalIssues = (array: TechnicalIssueModel[], type: string[]) => {
  if (type && type.length > 0) return array.filter((arr) => type.find((t) => arr['technicalIssues'][`${t}`] === true));
  else return array;
};

export const filterByBpOutOfRange = (patients: PatientModel[] | undefined) => {
  if (patients === undefined) {
    return [];
  } else {
    return patients.filter((patient) => checkBpOutOfRange(patient));
  }
};

export const filterByActivities = (array: BillingReportModel[], type: string[]) => {
  if (type && type.length > 0) return array.filter((arr) => type.find((t) => arr['activity_type'] === t));
  else return array;
};

export const checkBpOutOfRange = (patient: PatientModel) => {
  return (
    patient.bpAlertType &&
    !patient.bpAlertType.isGreen &&
    !!patient.bpReadings &&
    !!patient.currentReading &&
    !!patient.is_treatment_going_on
  );
};

export const filteredTaskEstimateTime = (array: TaskModel[] | undefined) => {
  let estimateTime = 0;

  if (array) {
    array.map((arr) => {
      if (arr.task_type?.call) {
        estimateTime += Constant.CALL;
      }

      if (arr.task_type?.email) {
        estimateTime += Constant.EMAIL;
      }

      if (arr.task_type?.report) {
        estimateTime += Constant.REPORT;
      }

      return estimateTime;
    });
  }

  return estimateTime;
};

export const filteredTechnicalIssuesEstimateTime = (array: TechnicalIssueModel[] | undefined) => {
  let estimateTime = 0;

  if (array) {
    array.map((arr) => {
      if (arr.technicalIssues?.isBatteryLow) {
        estimateTime += Constant.BATTERY_LOW;
      }

      if (arr.technicalIssues?.isSignalWeak) {
        estimateTime += Constant.SIGNAL_PROBLEM;
      }

      return estimateTime;
    });
  }

  return estimateTime;
};

export const filteredBpOutOfRangeEstimateTime = (array: PatientModel[] | undefined) => {
  let estimateTime = 0;

  if (array) {
    array.map((arr) => {
      if (arr.bpAlertType?.isRed) {
        estimateTime += Constant.RED_ALERT;
      } else if (arr.bpAlertType?.isYellow) {
        estimateTime += Constant.YELLOW_ALERT;
      }

      return estimateTime;
    });
  }

  return estimateTime;
};

export const arrayFromNumber = (length: number) => {
  const arr = [];
  for (let i = 1; i <= length; i++) {
    arr.push(i);
  }

  return arr;
};

export const MedicationFiltration = (
  filters: string[],
  medications: MedicineModel[],
  medicationLogs: MedicineLogModel[],
) => {
  const intial = medications;
  let returnValue;

  if (filters.length > 0) {
    if (filters.includes('In Use') && filters.length === 1) {
      returnValue = intial.filter((init) => init.active);
    }

    if (filters.includes('Adjusted') && filters.length === 1) {
      returnValue = intial.filter((init) => medicationLogs.filter((log) => init.id === log.medicine_id).length !== 0);
    }

    if (filters.includes('Discontinued') && filters.length === 1) {
      returnValue = intial.filter((init) => !init.active);
    }

    if (filters.includes('In Use') && filters.includes('Adjusted')) {
      returnValue = intial.filter(
        (init) => init.active && medicationLogs.filter((log) => init.id === log.medicine_id).length > 0,
      );
    }

    if (filters.includes('Discontinued') && filters.includes('Adjusted')) {
      returnValue = intial.filter(
        (init) => !init.active && medicationLogs.filter((log) => init.id === log.medicine_id).length > 0,
      );
    }

    if (filters.includes('In Use') && filters.includes('Discontinued') && filters.length === 2) {
      returnValue = intial;
    }

    if (filters.length === 3) {
      returnValue = intial.filter((init) => medicationLogs.filter((log) => init.id === log.medicine_id).length !== 0);
    }
  } else {
    returnValue = intial;
  }

  return returnValue;
};

// This is a filter based function which returns an object conating a boolean flag stating , whether technical issue
// for selected patient profile , if true then accumulates technical issue details and current reading of BPReading type
// such that it has values of signal strength , battery voltage and irregularness indicator.
export const findTechnicalIssue = (
  overallInfo: OverallInfoType | undefined,
  selectedProfileDetails: PatientProfileModel | undefined,
  healthArea: string,
) => {
  if (overallInfo && selectedProfileDetails) {
    const technicalIssueList = overallInfo?.technicalIssues || [];
    const currentPatientInList = technicalIssueList.filter((tip) => tip.id === selectedProfileDetails?.id);

    if (currentPatientInList && currentPatientInList.length > 0) {
      return {
        haveTechnicalIssue: true,
        issues: currentPatientInList[0].technicalIssues,
        latestReading:
          healthArea === HEALTH_METRIC_AREAS.bodyWeight
            ? selectedProfileDetails?.bWLastMeasurment
            : currentPatientInList[0].currentReading,
      };
    }
  }

  return {
    haveTechnicalIssue: true,
    issues: null,
    latestReading: null,
  };
};
