export enum AppRoute {
  home = '/',
  adminLogin = '/auth/admin-login',
  login = '/auth/login',
  forgotPassword = '/auth/forgot-password',
  resetPassword = '/reset-password',
  resetPasswordApp = '/reset-password-app',
  patients = '/patients',
  allPatients = '/allPatients', // TODO: fix the name to all-patients for the route
  diagnosis24h = '/24h-measurements',
  medicsDevices = '/medics-devices',
  openTasks = '/open-tasks',
  dueReports = '/due-reports',
  taskInspection = '/task-inspection',
  bpOutOfRange = '/bp-out-of-range',
  technicalIssues = '/technical-issues',
  everythingGood = '/everything-good',
  billing = '/billing',
  activities = '/activities',
  weeklyReport = '/weekly-report',
  messages = '/messages',

  reports = '/reports',
  reportsBilling = '/reports/billing',

  medics = '/medics',
  devices = '/devices',
  content = '/content',
  createContent = '/content/create',
  previewContent = '/content/preview',

  profile = '/profile',

  settings = '/settings',
  settingsEmployeesList = '/settings/employees-list',
  settingsPrivacyPolicy = '/settings/privacy-policy',
  settingsContactUs = '/settings/contact-us',
  settingsGeneral = '/settings/general',
  settingsMembers = '/settings/members',
  settingsTreatmentStopped = '/settings/treatment-stopped',
  settingsPaymentInformation = '/settings/payment-information',
  settingsDeleteAccount = '/settings/delete-account',
}
