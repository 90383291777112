import { DateTime } from 'luxon';

import { PatientModel } from 'models/patient';

class PatientsUtils {
  static activeFilter = (patient: PatientModel): boolean => {
    return patient.is_treatment_going_on === true;
  };

  static getActive = (patients: PatientModel[]): PatientModel[] => {
    const activePatients = patients?.filter(PatientsUtils.activeFilter);

    return activePatients;
  };

  static getLastMeasurement = (patient: PatientModel): Date | null => {
    if (!('currentReading' in patient)) return null;

    const value = patient.currentReading?.ts;

    if (!value) return null;

    const func = value > 10000000000 ? DateTime.fromMillis : DateTime.fromSeconds;

    return func(value).toJSDate();
  };
}

export { PatientsUtils };
