import React from 'react';

import { LineChartArrowProps } from './LineChartArrow.types';

export const LineChartArrow = (props: LineChartArrowProps) => {
  const { cx, cy, payload, idtmData } = props;

  if (!idtmData || !idtmData.length) return <></>;

  if (payload.name === idtmData[idtmData.length - 1].name) {
    return (
      <svg
        x={cx - 5}
        y={cy - 5}
        width={10}
        height={10}
        fill="#AEAEAE"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeWidth="4" d="M9 5l7 7-7 7"></path>
      </svg>
    );
  }

  return <></>;
};
