import React from 'react';
import { DateTime } from 'luxon';

import { useLocale } from 'hooks';
import { DateFormat, DateFormatsLocale } from '../../../context/locale/DateFormatsLocale.types';

import { CommitmentLegend } from './styled';

const CommitmentChartDate = ({ date, type, endDate }: { date: string; type: string; endDate: string }) => {
  const { locale } = useLocale();
  const dateFormat: DateFormat = new DateFormatsLocale[locale]();

  return (
    <CommitmentLegend className={`w-full text-base text-center`}>
      {type !== 'task' ? (
        DateTime.fromISO(date).toFormat(dateFormat.shortDayMonth, { locale })
      ) : (
        <div className="text-left pl-2">
          <div>{DateTime.fromISO(date).toFormat(dateFormat.shortDayMonth, { locale })} -</div>
          <div>{DateTime.fromISO(endDate).toFormat(dateFormat.shortDayMonth, { locale })}</div>
        </div>
      )}
    </CommitmentLegend>
  );
};

export { CommitmentChartDate };
// +
// '-' +
// DateTime.fromISO(endDate).toFormat(dateFormat.shortDayMonth, { locale })
