import React from 'react';

import { useLocale, usePatientState } from 'hooks';
import { filterByBpOutOfRange } from 'utils/filterBy';
import PatientListHeader from '../rightSidebar/patientListContainer/patientListHeader/PatientListHeader';
import PatientListItem from '../rightSidebar/patientListContainer/patientListItem/PatientListItem';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';

const BpOutOfRangeAll = () => {
  const { formatMessage } = useLocale();
  const { overallInfo, patients } = usePatientState();

  const bpOutOfRangeList = overallInfo?.technicalIssues && filterByBpOutOfRange(patients);

  return (
    <div className="flex-1 border-gray-border md:py-14 px-8 py-8">
      <PatientListHeader title={formatMessage({ id: 'dashboard.bp_out_of_range' })} lists={bpOutOfRangeList} />
      <PatientListItem
        lists={bpOutOfRangeList}
        info={bpOutOfRangeList}
        bpOutOfRange={true}
        filterOption={PatientsCategory.BpOutOfRange}
      />
    </div>
  );
};

export default BpOutOfRangeAll;
