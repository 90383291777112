import React from 'react';
import { Field } from 'formik';
import IntlTelInput from 'react-intl-tel-input';
import clsx from 'classnames';

import 'react-intl-tel-input/dist/main.css';
import { FieldAttributeProps, CustomInputProps, CountryData } from './InternationalNumber.types';

export const InternationlNumber = ({ name, dataTestId, className, placeholder }: FieldAttributeProps) => {
  return (
    <Field type="text" data-testid={dataTestId} name={name} required>
      {({ field: { name, value }, form: { setFieldValue, setTouched, validateForm }, meta }: CustomInputProps) => (
        <div className={clsx('mt-1', className)}>
          <IntlTelInput
            fieldName={name}
            value={value}
            placeholder={placeholder}
            onPhoneNumberChange={(
              _isValid: boolean,
              value: string,
              seletedCountryData: CountryData,
              _fullNumber: string,
              _extension: string,
            ) => {
              setFieldValue(name, value);
              // Hack to force validation on change
              validateForm().then(() => {
                validateForm();
              });

              setFieldValue('countryCode', `+${seletedCountryData.dialCode}`);
            }}
            defaultCountry="de"
            format={false}
            formatOnInit={false}
            containerClassName="intl-tel-input w-full"
            inputClassName={`shadow-sm block w-full sm:text-sm border-${meta.error ? 'red' : 'gray-light'} ${
              meta.error
                ? 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
                : 'focus:border-cyan focus:ring-offset-cyan focus:ring-cyan'
            } text-gray placeholder-gray-light rounded-md`}
            onPhoneNumberBlur={() => {
              setTouched({ name: true });
            }}
          />
        </div>
      )}
    </Field>
  );
};

export default InternationlNumber;
