import React from 'react';

const LeftArrowIcon: React.FC = () => (
  <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.38388 2.21726C9.87204 1.7291 9.87204 0.937646 9.38388 0.449491C8.89573 -0.0386648 8.10427 -0.0386648 7.61612 0.449491L0.94945 7.11616C0.705372 7.36023 0.583333 7.68014 0.583333 8.00004C0.583333 8.16953 0.617065 8.33114 0.678181 8.47852C0.739181 8.62595 0.829604 8.76408 0.94945 8.88392L7.61612 15.5506C8.10427 16.0387 8.89573 16.0387 9.38388 15.5506C9.87204 15.0624 9.87204 14.271 9.38388 13.7828L4.8511 9.25004H21C21.6904 9.25004 22.25 8.6904 22.25 8.00004C22.25 7.30968 21.6904 6.75004 21 6.75004H4.8511L9.38388 2.21726Z"
      fill="#4A4A4A"
    />
  </svg>
);

export default LeftArrowIcon;
