import AbstractSingleValueFilterStore from '../Abstract/AbstractSingleValueFilterStore';

enum TimeRangeFilterValues {
  days7 = 7,
  days30 = 30,
  total = -1,
  idtm = -2,
  weekly = -3,
}

class TimeRangeFilterStore extends AbstractSingleValueFilterStore {}

const store = new TimeRangeFilterStore(
  [
    TimeRangeFilterValues.idtm,
    TimeRangeFilterValues.days7,
    TimeRangeFilterValues.days30,
    TimeRangeFilterValues.total,
    TimeRangeFilterValues.weekly,
  ],
  // TimeRangeFilterValues.weekly,
  undefined,
);

export { store as TimeRangeFilterStore, TimeRangeFilterValues };
