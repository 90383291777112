export enum TimeSlotsFiltervalues {
  morning = 'morning',
  day = 'day',
  night = 'night',
}

export type TimeRangeIntervalSpec = {
  from: string;
  to: string;
};

export const TimeRanges = {
  [TimeSlotsFiltervalues.morning]: {
    from: '04:00:00',
    to: '11:00:00',
  },
  [TimeSlotsFiltervalues.day]: {
    from: '11:00:00',
    to: '20:00:00',
  },
  [TimeSlotsFiltervalues.night]: {
    from: '20:00:00',
    to: '04:00:00',
  },
};

export type TimeObject = {
  hour: number;
  minute: number;
  second: number;
};

export type RangeToFilter = {
  from: TimeObject;
  to: TimeObject;
};

export type FilterRange = {
  from: Date;
  to: Date;
};
