import React from 'react';

const UserListIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.75 4.75C15.726 4.75 14.8215 5.26239 14.279 6.04819C14.0437 6.38907 13.5766 6.47463 13.2357 6.23931C12.8948 6.00399 12.8092 5.53689 13.0446 5.19601C13.8551 4.02194 15.2123 3.25 16.75 3.25C19.2353 3.25 21.25 5.26472 21.25 7.75C21.25 9.99935 19.6004 11.862 17.4448 12.1966C17.0355 12.2601 16.6522 11.9798 16.5887 11.5705C16.5251 11.1612 16.8054 10.7779 17.2148 10.7143C18.6507 10.4915 19.75 9.24841 19.75 7.75C19.75 6.09315 18.4069 4.75 16.75 4.75ZM20.4091 14.25C20.3037 14.25 20.1989 14.2667 20.0987 14.2994C19.705 14.428 19.2815 14.213 19.153 13.8192C19.0244 13.4255 19.2394 13.0021 19.6331 12.8735C19.8837 12.7917 20.1456 12.75 20.4091 12.75H20.75C23.2353 12.75 25.25 14.7647 25.25 17.25V18.4383C25.25 19.5591 24.4377 20.5147 23.3316 20.6953C22.7521 20.7899 22.171 20.872 21.5888 20.9414C21.1775 20.9905 20.8043 20.6969 20.7553 20.2856C20.7062 19.8743 20.9998 19.5011 21.4111 19.452C21.9719 19.3851 22.5316 19.3061 23.0899 19.2149C23.4705 19.1528 23.75 18.8239 23.75 18.4383V17.25C23.75 15.5931 22.4069 14.25 20.75 14.25H20.4091Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.5C9.34315 8.5 8 9.84315 8 11.5C8 13.1569 9.34315 14.5 11 14.5C12.6569 14.5 14 13.1569 14 11.5C14 9.84315 12.6569 8.5 11 8.5ZM6.5 11.5C6.5 9.01472 8.51472 7 11 7C13.4853 7 15.5 9.01472 15.5 11.5C15.5 13.9853 13.4853 16 11 16C8.51472 16 6.5 13.9853 6.5 11.5ZM7.55598 17.9896C7.49902 17.9941 7.4195 18 7.34087 18H7C5.34315 18 4 19.3431 4 21V22.1883C4 22.5739 4.2795 22.9028 4.66012 22.9649C8.85891 23.6504 13.1411 23.6504 17.3399 22.9649C17.7205 22.9028 18 22.5739 18 22.1883V21C18 19.3431 16.6569 18 15 18H14.6591C14.5805 18 14.501 17.9941 14.444 17.9896C14.4245 17.9881 14.4068 17.9866 14.3903 17.9853C14.3502 17.982 14.3172 17.9793 14.2814 17.9772C14.2385 17.9746 14.2106 17.9742 14.193 17.9747C14.1301 17.9915 14.0652 18 14 18H8C7.93479 18 7.86991 17.9915 7.80701 17.9747C7.78945 17.9742 7.76146 17.9746 7.71859 17.9772C7.68284 17.9793 7.64978 17.982 7.60966 17.9853C7.59318 17.9866 7.57551 17.9881 7.55598 17.9896ZM7.8206 17.9755C7.82057 17.9756 7.81968 17.9755 7.81803 17.9753L7.8206 17.9755ZM14.1794 17.9756C14.1793 17.9755 14.1801 17.9754 14.182 17.9753L14.1794 17.9756ZM8.08662 16.5C7.89825 16.4646 7.72126 16.4744 7.62912 16.4799C7.53289 16.4856 7.47802 16.4906 7.43875 16.4942C7.39904 16.4978 7.37529 16.5 7.34087 16.5H7C4.51472 16.5 2.5 18.5147 2.5 21V22.1883C2.5 23.3091 3.31227 24.2647 4.41842 24.4453C8.77729 25.157 13.2227 25.157 17.5816 24.4453C18.6877 24.2647 19.5 23.3091 19.5 22.1883V21C19.5 18.5147 17.4853 16.5 15 16.5H14.6591C14.6247 16.5 14.601 16.4978 14.5612 16.4942C14.522 16.4906 14.4671 16.4856 14.3709 16.4799C14.2787 16.4744 14.1018 16.4646 13.9134 16.5H8.08662Z"
      fill="currentColor"
    />
  </svg>
);

export default UserListIcon;
