import React from 'react';
import { ResponsiveContainer, Bar, XAxis, Tooltip, Line, Cell, LineChart, BarChart } from 'recharts';

import { useLocale } from 'hooks';
import { LineChartArrow, Card } from 'components';

import {
  transformChartData,
  getContainerwidth,
  detmineFillColorSystolic,
  detmineFillColorDiastolic,
} from './weekTrendChart.service';
import { WeekTrendChartProps } from './weekTrendChart.types';

export const WeekTrendChart = ({ thisData, classes, maxWeeksToDisplay = 5 }: WeekTrendChartProps) => {
  const { formatMessage } = useLocale();
  const dataForChart = transformChartData(thisData).slice(maxWeeksToDisplay * -1);

  const responsiveContainerWidth = getContainerwidth(dataForChart);
  const renderTooltip = (tooltipData: { label: string }) => {
    const item = dataForChart.find((dataItem) => dataItem.name === tooltipData.label);
    const { systolic = 0, diastolic = 0 } = item || {};

    return (
      <Card className="p-3 w-full bg-white mt-11">
        <div
          className={`whitespace-nowrap mt-1 text-base text-${
            systolic >= 160 ? 'red' : systolic >= 130 && systolic <= 159 ? 'yellow' : 'default'
          } flex justify-between`}
        >
          <div>{`${formatMessage({ id: 'common.systolic' })} (mmHg): ${systolic}`} </div>
        </div>
        <div
          className={`whitespace-nowrap mt-1 mb-1 text-base text-${
            diastolic >= 100 ? 'red' : diastolic >= 85 && diastolic <= 99 ? 'yellow' : 'default'
          } flex justify-between`}
        >
          <div>{`${formatMessage({ id: 'common.diastolic' })} (mmHg): ${diastolic}`} </div>
        </div>
      </Card>
    );
  };

  return (
    <ResponsiveContainer width={responsiveContainerWidth} height={75} className={classes?.container}>
      <>
        <LineChart
          width={responsiveContainerWidth}
          height={10}
          data={dataForChart}
          margin={{ top: 5, right: 20, left: 0, bottom: 0 }}
        >
          <Line
            type="linear"
            isAnimationActive={false}
            dataKey="systolic"
            strokeWidth={1}
            stroke="#AEAEAE"
            dot={(props) => <LineChartArrow {...props} weekTrendData={dataForChart} />}
          />
        </LineChart>
        <BarChart
          width={responsiveContainerWidth}
          height={75}
          barGap={7}
          data={dataForChart}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            className=""
            tick={{ fontSize: 12, color: '#A4A4A4' }}
          />
          <Tooltip content={renderTooltip} />
          <Bar dataKey="systolic" radius={2}>
            {dataForChart.map((entry, index) => {
              const color = detmineFillColorSystolic(entry.systolic);

              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
          <Bar dataKey="diastolic" stackId="a" radius={2}>
            {dataForChart.map((entry, index) => {
              const color = detmineFillColorDiastolic(entry.diastolic);

              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
        </BarChart>
      </>
    </ResponsiveContainer>
  );
};
