// import { DateTime } from 'luxon';

import { BpChartPoint, RangeAverage } from 'models/bp';
import { PatientGoalModel } from 'models/patient';

class BpUtils {
  static sortAsc = (a: BpChartPoint, b: BpChartPoint): number =>
    new Date(a.dateTimeInfo).getTime() - new Date(b.dateTimeInfo).getTime();

  static sortDesc = (a: BpChartPoint, b: BpChartPoint): number =>
    new Date(b.dateTimeInfo).getTime() - new Date(a.dateTimeInfo).getTime();

  static addSevenDaysAvg = (data: BpChartPoint[]): BpChartPoint[] => {
    const output: BpChartPoint[] = [];

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const { jsDate } = item;

      // seven days average section
      const defaultAvgs: { [key: string]: number | null } = {
        sevenDaysAvgSys: null,
        sevenDaysAvgDia: null,
        sevenDaysAvgHr: null,
      };

      if (jsDate) {
        let sumSys = 0;
        let sumDia = 0;
        let sumHr = 0;
        let count = 0;

        // Calculate the average of the previous 7 days including the current measurement that is also included in the average.
        for (let j = i; j >= 0; j--) {
          const innerItem = data[j];
          const innerJsDate = item.dateTimeInfo ? new Date(innerItem.dateTimeInfo) : undefined;

          if (!innerJsDate) continue;

          // Check if the datetime is within the last 7 days.
          const diff = jsDate.getTime() - innerJsDate.getTime();

          if (diff < 7 * 24 * 60 * 60 * 1000) {
            sumSys += innerItem.systolic;
            sumDia += innerItem.diastolic;
            sumHr += innerItem.heartRate;
            count += 1;
          }
        }

        if (count > 0) {
          defaultAvgs.sevenDaysAvgSys = Math.round(sumSys / count);
          defaultAvgs.sevenDaysAvgDia = Math.round(sumDia / count);
          defaultAvgs.sevenDaysAvgHr = Math.round(sumHr / count);
        }
      }
      // end of seven days average section

      output.push({
        ...item,
        ...defaultAvgs,
      });
    }

    return output;
  };

  static getReadingsAvg = (items: BpChartPoint[]): RangeAverage => {
    const sysAvg = items.reduce((acc, item) => {
      return acc + item.systolic;
    }, 0);

    const diasAvg = items.reduce((acc, item) => {
      return acc + item.diastolic;
    }, 0);

    return {
      systolic: Math.round(sysAvg / items.length) || 0,
      diastolic: Math.round(diasAvg / items.length) || 0,
    };
  };

  static allBelowThreshold = (items: BpChartPoint[], goal: PatientGoalModel) => {
    if (!goal.goal_diastolic || !goal.goal_systolic) return false;

    return items.every((item) => {
      return item.systolic <= goal.goal_systolic && item.diastolic <= goal.goal_diastolic;
    });
  };

  static averageBelowThreshold = (average: RangeAverage, goal: PatientGoalModel): boolean => {
    if (!goal.goal_diastolic || !goal.goal_systolic) return false;

    if (!average.systolic || !average.diastolic) return false;

    return average.systolic <= goal.goal_systolic && average.diastolic <= goal.goal_diastolic;
  };

  static averageBelowAverage = (average: RangeAverage, average2: RangeAverage): boolean => {
    if (!average.systolic || !average.diastolic) return false;

    if (!average2.systolic || !average2.diastolic) return false;

    return average.systolic <= average2.systolic && average.diastolic <= average2.diastolic;
  };
}

export { BpUtils };
