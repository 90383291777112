import React, { useState } from 'react';

import Button from '../../../../components/Button/Button';
import { useLocale, usePatientState } from '../../../../hooks';
import BatteryIcon from 'components/Icons/BatteryIcon';
import SignalIcon from '../../../../components/Icons/SignalIcon';
import PatientListHeader from '../patientListContainer/patientListHeader/PatientListHeader';
import PatientListItem from '../patientListContainer/patientListItem/PatientListItem';
import { SharedStyle } from 'app/shared/styled';
import {
  filterByTechnicalIssues,
  filteredTechnicalIssuesEstimateTime,
  filterStoppedTreatmentPatiens,
} from 'utils/filterBy';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';

const TechnicalIssuesAll = () => {
  const { formatMessage } = useLocale();
  const { overallInfo } = usePatientState();

  const [filterType, setFilterType] = useState<string[]>([]);

  const technicalIssuesList =
    overallInfo?.technicalIssues && filterByTechnicalIssues(overallInfo?.technicalIssues, filterType);
  const estimateTime = filteredTechnicalIssuesEstimateTime(overallInfo?.technicalIssues);
  const stoppedTreatmentPatientList = filterStoppedTreatmentPatiens(technicalIssuesList);

  const setFilter = (data: string) => {
    if (filterType.includes(data)) {
      const filterTypeReplica = [...filterType];
      filterTypeReplica.splice(filterTypeReplica.indexOf(data), 1);
      setFilterType(filterTypeReplica);
    } else {
      setFilterType(() => filterType.concat(data));
    }
  };

  return (
    <div className="flex-1 border-gray-border md:py-14 px-8 py-8">
      <PatientListHeader
        title={formatMessage({ id: 'dashboard.technical_issues' })}
        lists={stoppedTreatmentPatientList}
        minutes={estimateTime}
      >
        <>
          <Button
            variant="inherit"
            justify="start"
            className={`${filterType.includes('isBatteryLow') && 'text-cyan'}`}
            testId="e2e-filter-battery-low-btn"
            onClick={() => setFilter('isBatteryLow')}
          >
            <SharedStyle hoverColor={'cyan'} className="flex items-center">
              <BatteryIcon />
              <span className="ml-2 text-base font-normal">
                {formatMessage({ id: 'dashboard.technical_issues.battery_low' })}
              </span>
            </SharedStyle>
          </Button>
          <Button
            variant="inherit"
            justify="start"
            className={`${filterType.includes('isSignalWeak') && 'text-cyan'}`}
            testId="e2e-filter-signal-problems-btn"
            onClick={() => setFilter('isSignalWeak')}
          >
            <SharedStyle hoverColor={'cyan'} className="flex items-center">
              <SignalIcon />
              <span className="ml-2 text-base font-normal">
                {formatMessage({ id: 'dashboard.technical_issues.signal_problems' })}
              </span>
            </SharedStyle>
          </Button>
        </>
      </PatientListHeader>

      <PatientListItem
        lists={stoppedTreatmentPatientList}
        info={overallInfo?.technicalIssues}
        technicalIssues={true}
        filterOption={PatientsCategory.TechnicalIssues}
      />
    </div>
  );
};

export default TechnicalIssuesAll;
