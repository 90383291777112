import React, { useReducer } from 'react';

import { WeeklyReportDispatchContext, WeeklyReportStateContext } from '../weeklyReportContext/WeeklyReportContext';
import { weeklyReportReducer } from '../weeklyReportReducer/weeklyReportReducer';

import { WeeklyReportContextControllerProps } from './WeeklyReportContextController.types';

export const WeeklyReportContextController = ({ children }: WeeklyReportContextControllerProps) => {
  const [state, dispatch] = useReducer(weeklyReportReducer, {
    note: '',
    weeklyFormDate: null,
    weeklyNotesEdit: { note: '' },
  });

  return (
    <WeeklyReportStateContext.Provider value={{ ...state }}>
      <WeeklyReportDispatchContext.Provider value={dispatch}>{children}</WeeklyReportDispatchContext.Provider>
    </WeeklyReportStateContext.Provider>
  );
};
