import { DeviceStateContextType } from '../deviceContext/deviceContext.types';

import { DeviceAction } from './deviceReducer.types';

export const SET_DEVICE_PATIENT_ASSIGNMENTS = 'set-device-patient-assignments';
export const SET_LOADING = 'set-loading';
export const SET_DEVICES = 'set-devices';

export const deviceReducer: (state: DeviceStateContextType, action: DeviceAction) => DeviceStateContextType = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: DeviceStateContextType, action: DeviceAction) => DeviceStateContextType> = {
    [SET_DEVICE_PATIENT_ASSIGNMENTS]: (state, { deviceAssignment }) => {
      return {
        ...state,
        deviceAssignment,
      };
    },
    [SET_LOADING]: (state, { loading }) => ({
      ...state,
      loading,
    }),
    [SET_DEVICES]: (state, { devices }) => ({
      ...state,
      devices,
    }),
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
