import React from 'react';

const EmailIcon = ({ className }: { className?: string }) => (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.164193 3.3591C-0.0745053 5.60097 -0.0638652 8.18658 0.268287 10.4193C0.452075 11.6548 1.44956 12.6019 2.68084 12.7101L3.96765 12.8232C6.65082 13.0589 9.34917 13.0589 12.0323 12.8232L13.3192 12.7101C14.5504 12.6019 15.5479 11.6548 15.7317 10.4193C16.0639 8.18657 16.0745 5.60112 15.8358 3.35924C15.805 3.09933 15.7702 2.83978 15.7317 2.58066C15.5479 1.34522 14.5504 0.398096 13.3191 0.289902L12.0323 0.176829C9.34917 -0.0589431 6.65082 -0.058943 3.96765 0.176829L2.68084 0.289902C1.44955 0.398096 0.452075 1.34522 0.268287 2.58066C0.229746 2.83973 0.195048 3.09924 0.164193 3.3591ZM4.07833 1.46392C6.68785 1.23462 9.31213 1.23462 11.9217 1.46392L13.2085 1.577C13.8512 1.63347 14.3719 2.12787 14.4678 2.77277C14.4778 2.83975 14.4875 2.90675 14.4969 2.97378L9.7586 5.63472C8.6649 6.24891 7.33503 6.24891 6.24133 5.63472L1.50308 2.97381C1.51251 2.90677 1.5222 2.83976 1.53217 2.77277C1.6281 2.12787 2.14879 1.63347 2.79152 1.577L4.07833 1.46392ZM14.6533 4.36389C14.8186 6.3171 14.7567 8.28504 14.4678 10.2272C14.3719 10.8721 13.8512 11.3665 13.2085 11.423L11.9217 11.5361C9.31214 11.7654 6.68785 11.7654 4.07833 11.5361L2.79152 11.423C2.14879 11.3665 1.6281 10.8721 1.53217 10.2272C1.24324 8.28504 1.18142 6.31712 1.34669 4.36392L5.62063 6.76408C7.10035 7.59506 8.89958 7.59506 10.3793 6.76408L14.6533 4.36389Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailIcon;
