import React from 'react';

import CheckboxIcon from 'components/Icons/CheckboxIcon';
import { useLocale } from 'hooks';

import { AddPatientFormStep, StepState } from './AddPatientModal.types';

type FormStepsStatusProps = {
  steps: AddPatientFormStep[];
  currentStep: number;
};

const FormStepsStatusIcon = ({ status, isCurrent }: { status: StepState; isCurrent: boolean }) => {
  if (status === StepState.completed) {
    return (
      <span className="w-5 h-5 inline-flex items-center justify-center bg-cyan rounded-full">
        <CheckboxIcon className="w-3 h-3 text-white" />
      </span>
    );
  } else if (status === StepState.active) {
    return (
      <span
        className="h-5 w-5 inline-block border-2 border-gray-light flex-shrink-0 rounded-full bg-white"
        aria-hidden="true"
      />
    );
  } else if (status === StepState.inactive) {
    return (
      <span
        className="h-5 w-5 inline-block border-2 border-gray-border flex-shrink-0 rounded-full bg-white"
        aria-hidden="true"
      />
    );
  } else if (status === StepState.inProgress) {
    return (
      <span
        className="h-5 w-5 inline-flex items-center justify-center border-2 border-cyan flex-shrink-0 rounded-full bg-white"
        aria-hidden="true"
      >
        <span className="h-3 w-3 bg-cyan rounded-full"></span>
      </span>
    );
  } else if (status === StepState.invalid) {
    return (
      <span
        className="h-5 w-5 inline-flex items-center justify-center border-2 border-red flex-shrink-0 rounded-full bg-white"
        aria-hidden="true"
      >
        {isCurrent && <span className="h-3 w-3 bg-red rounded-full"></span>}
      </span>
    );
  }

  return null;
};

const FormStepsStatus = ({ steps, currentStep }: FormStepsStatusProps) => {
  const { formatMessage } = useLocale();

  const visibleSteps = steps.filter((step) => step.state !== StepState.hidden);

  return (
    <ul className="flex justify-around">
      {visibleSteps.map((step, index) => {
        return (
          <li key={step.name} className={`relative flex flex-col items-center mx-1 w-1/${visibleSteps.length}`}>
            {index < visibleSteps.length - 1 && (
              <div
                className={`absolute left-1/2 w-full ml-2 h-0.5 top-2 tranform-bg bg-${
                  index <= currentStep - 1 ? 'cyan' : 'gray-borderLight'
                }`}
                style={{ top: '9px' }}
              ></div>
            )}

            <div className="inline-flex z-10">
              <FormStepsStatusIcon status={step.state} isCurrent={step.value === currentStep} />
            </div>
            <span className={`mt-3 text-base whitespace-nowrap text-${index <= currentStep ? 'cyan' : 'gray'}`}>
              {formatMessage({ id: step.name })}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default FormStepsStatus;
