import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAuthState, useLocale } from 'hooks';
import { DateField, SelectField } from 'components';
import { GENDER } from 'utils/constants.enum';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { BasicInfoStepProps } from '../../AddPatientModal.types';
import { insuredTypeOptions } from 'api/mocks/insuredType';
import { DiseaseManagementPrograms } from 'models/patient';
import Alert from 'components/Alert/Alert';
import { Features } from 'models/medic';

import { BasicInfoStepFormikProps } from './BasicInfoStep.types';

const dmpSelectOptions = (formatMessage: ({ id }: { id: string }) => string) => [
  {
    value: DiseaseManagementPrograms.diabetesType1,
    label: formatMessage({ id: 'enroll_new_patient.dmp_option.1' }),
  },
  {
    value: DiseaseManagementPrograms.diabetesType2,
    label: formatMessage({ id: 'enroll_new_patient.dmp_option.2' }),
  },
  {
    value: DiseaseManagementPrograms.heartDisease,
    label: formatMessage({ id: 'enroll_new_patient.dmp_option.3' }),
  },
  {
    value: DiseaseManagementPrograms.heartFailure,
    label: formatMessage({ id: 'enroll_new_patient.dmp_option.4' }),
  },
  {
    value: DiseaseManagementPrograms.obesity,
    label: formatMessage({ id: 'enroll_new_patient.dmp_option.5' }),
  },
];

export const BasicInfoStep = ({ parentContext, gender, handleGenderChange, values }: BasicInfoStepProps) => {
  const { formatMessage } = useLocale();
  const { errors, setFieldValue } = useFormikContext<BasicInfoStepFormikProps>();
  const { profile } = useAuthState();

  const hasDMPFeature = !profile?.hasFeature(Features.DISABLE_DMP);

  const handleDMPCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    if (!value) {
      setFieldValue('diseaseManagementPrograms', []);
    }

    setFieldValue('gkvFlatrate', value, true);
  };

  return (
    <div className={`${parentContext ? 'w-full' : ''}`}>
      <label htmlFor="patientIdPVS" className="text-base text-gray-light">
        <span>{formatMessage({ id: 'enroll_new_patient.patient_id_pvs' })}</span>
        <Field
          id="patientIdPVS"
          type="text"
          data-testid="e2e-enroll-patientIdPVS"
          name="patientIdPVS"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-gray-light text-gray placeholder-gray-light rounded-md pii`}
          placeholder="01234567"
        />
      </label>
      <div className="flex">
        <label htmlFor="firstName" className="block w-full mr-3 mt-3 text-base text-gray-light">
          <span>
            {formatMessage({ id: 'enroll_new_patient.firstname' })}
            <span className="text-red-dark ml-1">{'*'}</span>
          </span>
          <Field
            id="firstName"
            type="text"
            data-testid="e2e-enroll-first-name"
            name="firstName"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${
              errors.firstName ? 'red' : 'gray-light'
            }  text-gray placeholder-gray-light rounded-md ${
              errors.firstName && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            }`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_firstname' })}
            required
          />
        </label>
        <label htmlFor="lastName" className="w-full mt-3 block text-base text-gray-light">
          <span>{formatMessage({ id: 'enroll_new_patient.lastname' })}</span>
          <span className="text-red-dark ml-1">{'*'}</span>
          <Field
            id="lastName"
            type="text"
            data-testid="e2e-enroll-last-name"
            name="lastName"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.lastName ? 'red' : 'gray-light'} ${
              errors.lastName && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            } text-gray placeholder-gray-light rounded-md pii`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_lastname' })}
            required
          />
        </label>
      </div>
      <div className="mt-3">
        <label className="text-base text-gray-light">{formatMessage({ id: 'common.gender' })}</label>
        <div className="flex flex-col text-lg text-gray">
          <RadioButton
            id="add-patient-radio-male"
            label={formatMessage({ id: 'common.male' })}
            name="gender"
            value={GENDER.MALE}
            handleChange={handleGenderChange}
            margin={0}
            className="mr-1 pii"
            checked={gender === GENDER.MALE}
          />
          <RadioButton
            id="add-patient-radio-female"
            label={formatMessage({ id: 'common.female' })}
            name="gender"
            value={GENDER.FEMALE}
            handleChange={handleGenderChange}
            margin={0}
            className="mr-1 pii"
            checked={gender === GENDER.FEMALE}
          />
        </div>
      </div>
      <DateField
        name="birthday"
        labelId="enroll_new_patient.date_of_birth"
        maxDate={new Date()}
        required
        marginTop={3}
        classes={{
          fieldClassName: `mt-1 shadow-sm block w-full sm:text-sm border-${errors.birthday ? 'red' : 'gray-light'} ${
            errors.birthday && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          } text-gray placeholder-gray-light rounded-md pii`,
        }}
      />
      <SelectField
        name="insuredType"
        options={insuredTypeOptions(formatMessage)}
        labelId="enroll_new_patient.insured_type"
        values={values?.insuredType}
        isMulti={false}
        placeholderId="enroll_new_patient.insured_type"
        className="pii"
        marginTop={3}
        paddingR={0}
        required={true}
        isSearchable={false}
        isError={!!errors.insuredType}
      />
      <label className="block w-full mt-3 text-base text-gray-light">
        <span>{formatMessage({ id: 'enroll_new_patient.insurance_name' })}</span>
        <Field
          id="insuranceName"
          type="text"
          data-testid="e2e-enroll-insurance-name"
          name="insuranceName"
          className={`h-10 mt-1 shadow-sm block w-full sm:text-sm border-${
            errors.insuranceName ? 'red' : 'gray-light'
          }  text-gray placeholder-gray-light rounded-md ${
            errors.insuranceName && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          }`}
          placeholder={formatMessage({ id: 'enroll_new_patient.insurance_name.placeholder' })}
        />
      </label>
      {hasDMPFeature && (
        <>
          <label className="flex mt-5">
            <input
              type="checkbox"
              className="form-checkbox rounded h-5 w-5 text-cyan"
              name="gkvFlatrate"
              checked={values?.gkvFlatrate}
              onChange={handleDMPCheckboxChange}
            />
            <span className="ml-2 text-gray">{formatMessage({ id: 'enroll_new_patient.add_dmp' })}</span>
          </label>
          {!!values?.gkvFlatrate && (
            <div>
              <SelectField
                name="diseaseManagementPrograms"
                options={dmpSelectOptions(formatMessage)}
                labelId="enroll_new_patient.dmp_select.label"
                values={values?.diseaseManagementPrograms}
                isMulti={true}
                placeholderId="enroll_new_patient.dmp_select.placeholder"
                className="pii"
                marginTop={3}
                paddingR={0}
                isSearchable={false}
                isError={!!errors.diseaseManagementPrograms}
              />

              <div className="mt-4">
                <Alert text={formatMessage({ id: 'enroll_new_patient.add_dmp.info_price' })} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
