import React from 'react';

const CheckboxIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0354 0.297908C15.3771 0.639616 15.3771 1.19364 15.0354 1.53534L6.86876 9.70201C6.52705 10.0437 5.97303 10.0437 5.63132 9.70201L0.964656 5.03535C0.622947 4.69364 0.622947 4.13962 0.964656 3.79791C1.30636 3.4562 1.86038 3.4562 2.20209 3.79791L6.25004 7.84586L13.798 0.297908C14.1397 -0.0438012 14.6937 -0.0438012 15.0354 0.297908Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckboxIcon;
