export enum Color {
  black = '#000000',
  white = '#FFFFFF',
  cyan = '#095C70',
  lightCyan = '#2B6C7E',
  lightGray = '#F8F8F8',
  lightMediumGray = '#F5F4F4',
  mediumGray = '#e3e3e3',
  lighterGrey = '#7E7E7E', // according to the tailwindcss config
  darkGrey = '#4A4A4A',
  darkGreyPlus = '#333333',
  heartBlue = '#2192FD',

  red = '#F77C6D',
  redMedium = '#D16048',
  redDark = '#E93F1E',
  redLight = '#FFEBEB',
}
