import React from 'react';

import { PatientListItemDataPointProps } from './PatientListItemDataPoint.types';

export const PatientListItemDataPoint = ({ title, data, col }: PatientListItemDataPointProps) => (
  <>
    <div className={`col-start-${col} col-end-${col + 1} row-end-2 text-center text-gray-light text-base`}>{title}</div>
    <div
      className={`col-start-${col} col-end-${
        col + 1
      } row-start-2 row-end-3 text-center text-gray-light text-lg mt-2 md:whitespace-nowrap pii`}
    >
      {data}
    </div>
  </>
);
