import styled from 'styled-components';

export const ListItem = styled.li`
  position: relative;

  &.selected:before {
    content: '';
    position: absolute;
    left: 0;
    width: 26px;
    height: 10px;
    background: #095c70;
    transform: rotate(90deg);
    top: 11px;
    left: -8px;
  }
`;

export const ListItemButton = styled.button`
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover svg {
    color: #095c70;
  }
`;

export const MainContainer = styled.div`
  @media (max-width: 768px) {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
`;
