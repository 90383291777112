import React from 'react';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { de, enIN } from 'date-fns/locale';

import { useLocale } from 'hooks';

import { DateFieldProps } from './DateField.types';

export const DateField = ({
  id,
  name,
  label,
  labelId,
  required = false,
  maxDate,
  minDate,
  isError = false,
  marginTop = 5,
  classes,
}: DateFieldProps) => {
  const { formatMessage, locale } = useLocale();
  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <div className={`block mt-${marginTop} w-full`}>
      {(label || labelId) && (
        <label htmlFor={id}>
          <span className={`text-base text-${isError ? 'red' : 'gray-light'}`}>
            {label ? label : formatMessage({ id: labelId })}
          </span>
          {required && <span className="text-base text-red-dark ml-1">*</span>}
        </label>
      )}
      <div className="relative">
        <DatePicker
          {...field}
          id={id}
          name={name}
          className={classes?.fieldClassName}
          selected={value ? new Date(value) : undefined}
          onChange={(date) => {
            setValue(date);
          }}
          maxDate={maxDate}
          minDate={minDate}
          required={required}
          showYearDropdown
          placeholderText={locale === 'de' ? 'TT.MM.JJJJ' : 'DD.MM.YYYY'}
          locale={locale === 'de' ? de : enIN}
          dateFormat={locale === 'de' ? 'dd.MM.yyyy' : 'dd/MM/yyyy'}
        />
      </div>
    </div>
  );
};
