import { makeObservable, computed, observable, action } from 'mobx';
import { DateTime } from 'luxon';

import { BpChartPoint } from '../../models/bp';
import { TimeRangeFilterStore, TimeRangeFilterValues } from '../FilterStore/SingleTimeRangeFilter/TimeRangeFilterStore';
import { TimeSlotsFilterStore } from 'store/FilterStore/MultipleTimeSlotsFilter/TimeSlotsFilterStore';
import { PatientProfileModel } from '../../models/patient';
import { isDateBetween } from 'utils/helper';
import { BpUtils } from 'utils/BpUtils';
import { FormDateModel } from 'models/formDate';

import { InspectionsCompleteStore } from './TaskCompleteStore/InspectionsCompleteStore';

type ViewFilters = {
  timeRange: typeof TimeRangeFilterStore;
  timeSlot: typeof TimeSlotsFilterStore;
};

class BloodPressureStore {
  raw: BpChartPoint[] = [];
  filters: ViewFilters = {
    timeRange: TimeRangeFilterStore,
    timeSlot: TimeSlotsFilterStore,
  };

  setRaw = (raw: BpChartPoint[]): void => {
    const addedDate: BpChartPoint[] = raw.map((item: BpChartPoint) => {
      return {
        ...item,
        ...{
          jsDate: item.dateTimeInfo ? new Date(item.dateTimeInfo) : undefined,
        },
      };
    });

    this.raw = BpUtils.addSevenDaysAvg(addedDate);
  };

  get RawAsc(): BpChartPoint[] {
    return this.raw.slice().sort(BpUtils.sortAsc);
  }
  get RawFrom(): Date | undefined {
    return (this.RawAsc[0] && this.RawAsc[0].jsDate) || undefined;
  }
  get RawTo(): Date | undefined {
    return (this.RawAsc[this.RawAsc.length - 1] && this.RawAsc[this.RawAsc.length - 1].jsDate) || undefined;
  }

  // For filtering Blod Pressure Reading List for weekly report
  getFilteredReadingList = (formDate: FormDateModel) => {
    if (formDate) {
      return this.raw.filter((list) => isDateBetween(list.dateTimeInfo, formDate.from, formDate.to));
    } else {
      return this.raw;
    }
  };

  // For filtering Medication List for weekly report
  getFilteredMedicationList = (selectedProfileDetails: PatientProfileModel, formDate: FormDateModel) => {
    const medicationList = selectedProfileDetails?.medications || [];

    if (formDate) {
      return medicationList.filter((list) => isDateBetween(list.created_at, formDate.from, formDate.to));
    } else {
      return medicationList;
    }
  };

  filterLastNDays = (lastNDays: number): BpChartPoint[] => {
    const d = new Date();
    d.setDate(d.getDate() - lastNDays);
    const earliestDate = DateTime.fromJSDate(d).endOf('day'); // will result the day before at 23:59:59

    return this.raw.filter((bp: BpChartPoint) => {
      const date = DateTime.fromISO(bp.dateTimeInfo);

      return date >= earliestDate;
    });
  };

  filterByTimeRange = (from: Date | null, to: Date | null): BpChartPoint[] => {
    const fromToFilter = from == null ? DateTime.local().minus({ years: 100 }) : from;
    const toToFilter = to == null ? DateTime.local().plus({ years: 1 }) : to;

    return this.raw.filter((bp: BpChartPoint) => {
      const itemDate = bp.jsDate || new Date();

      return itemDate >= fromToFilter && itemDate <= toToFilter;
    });
  };

  filterBp = (): BpChartPoint[] => {
    let bpData = [...this.raw];

    if (InspectionsCompleteStore.shouldFilterBy) {
      const { from, to } = InspectionsCompleteStore.getCurrentTimeRange;

      bpData = this.filterByTimeRange(from, to);
    } else if (
      this.filters.timeRange.current === TimeRangeFilterValues.days7 ||
      this.filters.timeRange.current === TimeRangeFilterValues.days30
    ) {
      bpData = [...this.filterLastNDays(this.filters.timeRange.current)];
    }

    bpData = this.filters.timeSlot.getTimeRangeFilteredData(bpData);

    bpData = BpUtils.addSevenDaysAvg(bpData);

    return bpData;
  };

  get FilteredBpAsc(): BpChartPoint[] {
    return this.filterBp().sort(BpUtils.sortAsc);
  }
  get FilteredBpDesc(): BpChartPoint[] {
    return this.filterBp().sort(BpUtils.sortDesc);
  }
  get FilteredFrom(): Date | undefined {
    return (this.FilteredBpAsc[0] && this.FilteredBpAsc[0].jsDate) || undefined;
  }
  get FilteredTo(): Date | undefined {
    return (
      (this.FilteredBpAsc[this.FilteredBpAsc.length - 1] && this.FilteredBpAsc[this.FilteredBpAsc.length - 1].jsDate) ||
      undefined
    );
  }
  get FindEarliestDate(): Date | null {
    if (!(this.raw instanceof Array) || this.raw.length === 0) return null;

    const earliest: string = this.raw.reduce((a, b) => {
      return new Date(a.dateTimeInfo) < new Date(b.dateTimeInfo) ? a : b;
    }).dateTimeInfo;

    return DateTime.fromISO(earliest).toJSDate();
  }

  constructor() {
    makeObservable(this, {
      setRaw: action,
      filterByTimeRange: action,
      raw: observable,
      RawAsc: computed,
      RawFrom: computed,
      RawTo: computed,
      FilteredBpAsc: computed,
      FilteredBpDesc: computed,
      FilteredFrom: computed,
      FilteredTo: computed,
      FindEarliestDate: computed,
    });
  }
}

const store = new BloodPressureStore();
export { store as BloodPressureStore };
