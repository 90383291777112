// import { makeObservable, action } from 'mobx';
import { DateTime } from 'luxon';

import { TaskModel } from 'models/task';
import { ReportTimeRangeFilterStore } from 'store/FilterStore/WeeklyReport/ReportTimeRangeFilterStore';
import { PatientsTasksStore } from '../PatientTasksStore';

import { AbstractTaskCompleteStore } from './AbstractTaskCompleteStore';

class ReportCompleteStore extends AbstractTaskCompleteStore {
  public load(): void {
    this.tasks = PatientsTasksStore.dueReports;
    // iterate this.tasks and set the nextOfType
    this.setNextPrevOfType();
  }

  public setCurrentTask = (task: TaskModel | null): void => {
    super.setCurrentTask(task);

    if (!task) return;

    ReportTimeRangeFilterStore.setFrom(DateTime.fromISO(task.start_date).toJSDate(), true);
    ReportTimeRangeFilterStore.setTo(DateTime.fromISO(task.end_date).toJSDate());
  };

  //   constructor() {
  // super();

  // makeObservable(this, {
  //   load: action,
  // });
  //   }
}

const store = new ReportCompleteStore();

export { store as ReportCompleteStore };
