import useAxios from 'axios-hooks';
import React, { useReducer } from 'react';
import { useEffect } from 'react';

import { useAuthState, useLocale } from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { UserType } from 'models/user';
import { medicReducer } from '../medicReducer/medicReducer';
import { setLoading, setMedics } from '../medicActionCreators/medicActionCreators';
import { MedicDispatchContext, MedicStateContext } from '../medicContext/MedicContext';

import { MedicContextControllerProps } from './MedicContextController.types';

export const MedicContextController = ({ children }: MedicContextControllerProps) => {
  const [state, dispatch] = useReducer(medicReducer, {
    medics: [],
    loading: false,
  });
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();
  const { user, role } = useAuthState();

  const [{ data, error, loading }, getMedics] = useAxios('/medics', { manual: true });

  useEffect(() => {
    if (user?.id && role === UserType.Admin) {
      getMedics();

      return () => {
        // clearInterval(refreshInterval);
      };
    }
  }, [getMedics, user, role]);

  useEffect(() => {
    dispatch(setLoading(loading));

    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'medics.snackbar.medics.error_title' }),
        description: formatMessage({ id: 'medics.snackbar.medics.error_description' }),
        autoHideDuration: 5000,
      });
      dispatch(setLoading(false));
    }

    if (loading && !error) {
      dispatch(setLoading(true));
    }

    if (!loading && !error && data) {
      dispatch(setMedics(data?.data || []));
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  return (
    <MedicStateContext.Provider value={{ ...state, getMedics }}>
      <MedicDispatchContext.Provider value={dispatch}>{children}</MedicDispatchContext.Provider>
    </MedicStateContext.Provider>
  );
};
