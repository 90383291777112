import React from 'react';

export const FormatMoney = ({
  value,
  locale = 'de-DE',
  ifZero = '-',
}: {
  value: number;
  locale?: string;
  ifZero?: string;
}) => {
  if (ifZero && value === 0) return <span>{ifZero}</span>;

  return <span>{new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(value)}</span>;
};
