import React from 'react';
import { Field } from 'formik';

import { useLocale } from 'hooks';
import Button from 'components/Button/Button';

import { GenericFieldProps } from './GenericField.types';

export const GenericField = ({
  id,
  name,
  type,
  label,
  labelId,
  placeholder,
  placeholderId,
  autoComplete,
  dataTestId,
  required,
  showRequired,
  disabled = false,
  endIcon,
  className = '',
  endIconClassName = '',
  validate,
  endIconAction,
  as,
  isError = false,
}: GenericFieldProps) => {
  const { formatMessage } = useLocale();

  return (
    <label className={`block mt-5 w-full ${className}`}>
      {(label || labelId) && (
        <span className={`text-base text-${isError ? 'red' : 'gray-light'}`}>
          {label ? label : formatMessage({ id: labelId })}
        </span>
      )}
      {showRequired && <span className="text-base text-red-dark ml-1">{'*'}</span>}
      <div className="relative">
        <Field
          id={id}
          name={name}
          type={type}
          as={as}
          className={`w-full rounded block border${isError ? '-2' : ''} border-${
            isError ? 'red' : 'gray-borderLight'
          } ${as !== 'textarea' && 'h-10'}
          ${endIcon ? 'pr-10 pl-3' : 'px-3'}`}
          data-testid={dataTestId}
          placeholder={placeholder ? placeholder : placeholderId ? formatMessage({ id: placeholderId }) : undefined}
          required={required}
          disabled={disabled}
          validate={validate}
          autoComplete={autoComplete || ''}
        />
        {endIcon && (
          <Button
            onClick={endIconAction}
            className={`text-gray-light absolute inset-y-0 right-0 pr-3 flex items-center ${endIconClassName}`}
          >
            {endIcon}
          </Button>
        )}
      </div>
    </label>
  );
};
