import React from 'react';

const RemoteDeviceIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  stroke = 'currentColor',
  className = '',
  onClick = () => {},
}: {
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
  className?: string;
  onClick?: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    className={`h-6 w-6 ${className}`}
    // fill={color}
    stroke={stroke}
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      d="M12.75 11.75C12.75 12.1642 12.4142 12.5 12 12.5C11.5858 12.5 11.25 12.1642 11.25 11.75C11.25 11.3358 11.5858 11 12 11C12.4142 11 12.75 11.3358 12.75 11.75Z"
      fill={color}
    />
    <path
      d="M15 7.35925C14.2322 6.51943 13.1716 6 12 6C10.8284 6 9.76777 6.51943 9 7.35925M10.5 9.67962C10.8839 9.25972 11.4142 9 12 9C12.5858 9 13.1161 9.25972 13.5 9.67962M18 22H10C7.79086 22 6 20.2091 6 18V6C6 3.79086 7.79086 2 10 2H14C16.2091 2 18 3.79086 18 6V13M13.5 19H20.5C21.3284 19 22 18.3284 22 17.5C22 16.6716 21.3284 16 20.5 16H13.5C12.6716 16 12 16.6716 12 17.5C12 18.3284 12.6716 19 13.5 19Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RemoteDeviceIcon;
