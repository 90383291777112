import React from 'react';

const SunriseIcon = ({
  x,
  y,
  stroke = '#111111',
  height = 24,
  width = 24,
}: {
  x?: number;
  y?: number;
  stroke?: string;
  height?: number;
  width?: number;
}) => (
  <svg x={x} y={y} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6667 24C22.6667 22.2319 21.9643 20.5362 20.7141 19.2859C19.4638 18.0357 17.7681 17.3333 16 17.3333C14.2319 17.3333 12.5362 18.0357 11.286 19.2859C10.0357 20.5362 9.33334 22.2319 9.33334 24"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16 2.66667V12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.62666 13.6267L7.52 15.52"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.33333 24H3.99999" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28 24H30.6667" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24.48 15.52L26.3733 13.6267"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M30.6667 29.3333H1.33333" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.6667 8L16 2.66667L21.3333 8"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SunriseIcon;
