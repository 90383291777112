import React from 'react';

const PhoneIcon = ({ className }: { className?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.06085 6.21673C3.69355 9.77403 6.59891 12.6022 10.2107 14.1346L10.2221 14.1395L10.9198 14.4506C11.8083 14.8467 12.8526 14.5654 13.4223 13.7765L14.5864 12.1644C14.6575 12.066 14.6392 11.929 14.5447 11.8528L12.5124 10.2123C12.4114 10.1307 12.2628 10.1497 12.1855 10.2541L11.3946 11.322C11.2025 11.5814 10.8556 11.6715 10.5617 11.5384C7.86099 10.3155 5.69079 8.14401 4.4686 5.44178C4.33558 5.14768 4.42565 4.80058 4.68485 4.60838L5.75216 3.81696C5.85645 3.73962 5.87543 3.59098 5.79393 3.48989L4.15417 1.45614C4.07795 1.36162 3.94116 1.34326 3.84275 1.41435L2.22285 2.58444C1.42922 3.15771 1.14973 4.21144 1.55483 5.10303L2.0603 6.21555C2.06048 6.21594 2.06067 6.21634 2.06085 6.21673ZM9.66995 15.3944C5.74555 13.7276 2.58909 10.6537 0.815042 6.78763L0.813974 6.7853L0.307438 5.67045C-0.367726 4.18447 0.0980908 2.42825 1.42082 1.47281L3.04071 0.302707C3.7296 -0.194895 4.68715 -0.0663785 5.22063 0.595279L6.86039 2.62903C7.43092 3.33664 7.29804 4.37716 6.56801 4.91849L5.95642 5.37198C6.98951 7.37772 8.62684 9.016 10.6314 10.0497L11.0846 9.43774C11.6257 8.70728 12.6656 8.57432 13.3728 9.14518L15.4051 10.7857C16.0665 11.3196 16.1948 12.2781 15.6971 12.9674L14.533 14.5795C13.5835 15.8943 11.843 16.3632 10.3621 15.703L9.66995 15.3944Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneIcon;
