import { useContext } from 'react';

import { EmailDispatchContextType } from 'context/email/emailContext/EmailContext.types';
import { EmailDispatchContext } from 'context/email/emailContext/EmailContext';

export const useEmailDispatch: () => EmailDispatchContextType = () => {
  const context = useContext(EmailDispatchContext);

  if (context === undefined) {
    throw new Error('useEmailDispatch must be used within an EmailContextController');
  }

  return context;
};
