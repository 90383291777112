import { action, makeObservable, observable } from 'mobx';
import { DateTime } from 'luxon';

import { BpChartPoint } from 'models/bp';
import { BpUtils } from 'utils/BpUtils';
import { BloodPressureStore } from 'store/PatientStore/BloodPressureStore';
import AbstractTimeRangeFilterStore from '../Abstract/AbstractTimeRangeFilterStore';

// enum TimeRangeFilterValues {
//   days7 = 7,
//   days30 = 30,
//   total = -1,
//   idtm = -2,
// }

class ReportTimeRangeFilterStore extends AbstractTimeRangeFilterStore {
  public FilteredBpAsc: BpChartPoint[] = [];
  public FilteredBpDesc: BpChartPoint[] = [];

  setLastNDays = (days: number): void => {
    const daysToApply = days < 0 ? 5 : days;
    const from = new Date();
    from.setDate(from.getDate() - daysToApply);
    this.setFrom(from, true);
    this.setTo(new Date());
  };
  private filterBp(): BpChartPoint[] {
    const fromToFilter: Date | null = this.from instanceof Date ? this.from : null;
    const toToFilter: Date | null = this.to instanceof Date ? this.to : null;

    return BloodPressureStore.filterByTimeRange(fromToFilter, toToFilter);
  }
  _FilteredBpAsc(data: BpChartPoint[]): void {
    this.FilteredBpAsc = data.sort((a: BpChartPoint, b: BpChartPoint) => {
      return new Date(a.dateTimeInfo).getTime() - new Date(b.dateTimeInfo).getTime();
    });
  }
  _FilteredBpDesc(data: BpChartPoint[]): void {
    this.FilteredBpDesc = data.sort((a: BpChartPoint, b: BpChartPoint) => {
      return new Date(b.dateTimeInfo).getTime() - new Date(a.dateTimeInfo).getTime();
    });
  }
  protected _applyFilters(): void {
    // console.log('_applyFilters');
    const filtered: BpChartPoint[] = this.filterBp();
    this._FilteredBpAsc(filtered);
    this._FilteredBpDesc(filtered);
  }
  public setFrom(value: Date | null, silent?: boolean): void {
    super.setFrom(value);

    if (!silent) this._applyFilters();
  }
  public setTo(value: Date | null, silent?: boolean): void {
    super.setTo(value);

    if (!silent) this._applyFilters();
  }
  public getFirstWeekBpAsc(): BpChartPoint[] {
    // call BloodPressureStore.filterByTimeRange(from, to) and return the result
    // from is the current 'to' date
    // to is 7 days after the current 'to' date

    if (!BloodPressureStore.RawAsc.length || !this.from) return [];

    const from = BloodPressureStore.RawAsc[0].jsDate;

    const to = DateTime.fromJSDate(from).plus({ days: 7 }).toJSDate();

    const bp = BloodPressureStore.filterByTimeRange(from, to);

    return bp.sort(BpUtils.sortAsc);
  }

  constructor({
    from = new Date(),
    to = new Date(),
    emptyIsToday = true,
    daysOnlyFilter = true,
  }: {
    from: Date | null;
    to: Date | null;
    emptyIsToday: boolean;
    daysOnlyFilter: boolean;
  }) {
    super({
      from,
      to,
      emptyIsToday,
      daysOnlyFilter,
    });
    makeObservable(this, {
      setLastNDays: action,
      FilteredBpAsc: observable,
      FilteredBpDesc: observable,
    });
  }
}

const store = new ReportTimeRangeFilterStore({
  from: null,
  to: null,
  emptyIsToday: true,
  daysOnlyFilter: true,
});

export { store as ReportTimeRangeFilterStore };
