import { SET_MEDICS, SET_LOADING } from '../medicReducer/medicReducer';
import { MedicAction } from '../medicReducer/medicReducer.types';
import { MedicModel } from 'models/medic';

export const setMedics: (medics: MedicModel[]) => MedicAction = (medics) => ({
  type: SET_MEDICS,
  medics,
});

export const setLoading: (loading: boolean) => MedicAction = (loading) => ({
  type: SET_LOADING,
  loading,
});
