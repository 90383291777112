import { SET_LOADING, SET_DEVICES } from '../deviceListReducer/deviceListReducer';
import { DeviceListAction } from '../deviceListReducer/deviceListReducer.types';
import { DeviceModel } from '../../../models/device';

export const setLoading: (loading: boolean) => DeviceListAction = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setDevices: (devices: DeviceModel[]) => DeviceListAction = (devices) => ({
  type: SET_DEVICES,
  devices,
});
