import React from 'react';
import { useHistory } from 'react-router';

import Beta from 'components/Beta/Beta';
import { useLocale } from '../../../../../hooks';
import LeftArrowIcon from '../../../../../components/Icons/LeftArrowIcon';

import { PatientListHeaderProps } from './PatientListHeader.types';

const PatientListHeader = ({ title, subTitle, lists, children, isBeta = false }: PatientListHeaderProps) => {
  const { formatMessage } = useLocale();
  const history = useHistory();

  return (
    <div className="lg:flex items-center justify-between w-full">
      <div className="lg:flex items-center lg:mb-0 mb-3 md:w-1/2 w-full pr-10">
        <div className="flex items-center text-lg text-gray font-bold lg:mb-0 mb-2">
          <div className="pr-3 cursor-pointer" onClick={() => history.goBack()} data-testid="e2e-back-arrow-btn">
            <LeftArrowIcon />
          </div>
          <div>{title}</div>
          {isBeta && <Beta className="ml-2">BETA</Beta>}
        </div>
        {lists && (
          <div className="flex items-center lg:ml-6">
            <div className="text-base text-gray">
              {subTitle
                ? subTitle
                : formatMessage({ id: 'dashboard.patients_number' }, { patients: lists?.length || 0 })}
            </div>
          </div>
        )}
      </div>
      {children && (
        <div className="lg:flex items-center md:w-1/2 w-full justify-end">
          <span className="text-gray mr-6 text-base font-normal">
            {formatMessage({ id: 'dashboard.patients.filterBy' })}
          </span>
          <div className="flex items-center justify-between -ml-3 lg:ml-0">{children}</div>
        </div>
      )}
    </div>
  );
};

export default PatientListHeader;
