import { makeObservable, observable, action } from 'mobx';

import { isDateBetween } from '../../utils/helper';
import { MedicineModel } from '../../models/medication';
import { FormDateModel } from 'models/formDate';

class MedicationStore {
  medications: MedicineModel[] = [];

  setMedication = (medications: MedicineModel[]): void => {
    this.medications = medications || [];
  };

  // For filtering Medication List for weekly report
  filteredMedicationList = (formDate: FormDateModel | undefined) => {
    const medicationList = this.medications || [];

    if (formDate) {
      return medicationList.filter((list) => isDateBetween(list.created_at, formDate.from, formDate.to));
    }

    return medicationList;
  };

  constructor() {
    makeObservable(this, {
      setMedication: action,
      filteredMedicationList: action,
      medications: observable,
    });
  }
}

const store = new MedicationStore();
export { store as MedicationStore };
