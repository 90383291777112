import { DateTime } from 'luxon';

import { TaskModel } from 'models/task';

class TasksUtils {
  static isTaskInCurrentPeriod = (task: TaskModel): boolean => {
    const startOfDay = (date: DateTime) => date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endOfDay = (date: DateTime) => date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    const now = DateTime.local();
    const start: DateTime = startOfDay(DateTime.fromISO(task.start_date));
    const end: DateTime = endOfDay(DateTime.fromISO(task.end_date));

    return now >= start && now <= end;
  };

  static isTaskNotInCurrentPeriod = (task: TaskModel): boolean => {
    return !TasksUtils.isTaskInCurrentPeriod(task);
  };

  static countMoneyWidget = (tasks: TaskModel[]) => {
    // count only privately insured patients for billing purposes
    // sum items
    const countByField = privatePatients.reduce((acc, item) => {
      return acc + item.patientCountInList;
    }, 0);

    return countByField;
  };

  static addPatientCountToArray = (tasksByPatient: filterTaskModel[]) => {
    if (!tasksByPatient) return { list: [], uniques: 0, moneyWidgetTasksListLength: 0 };

    // only tasks that are status = CREATED
    const createdByPatient: filterTaskModel = Object.keys(tasksByPatient).reduce((acc, i) => {
      acc[i] = tasksByPatient[i].filter((item) => item.status === TaskStatus.CREATED);

      return acc;
    }, {});

    // count the number of tasks per patients
    const countHash: { [key: string]: number } = Object.keys(createdByPatient).reduce((acc, i) => {
      acc[i] = createdByPatient[i].length;

      return acc;
    }, {});

    // create a patiets list to display and add the count to each item
    const patientsList = Object.keys(createdByPatient)
      .filter((item) => {
        const firstTask: TaskModel = createdByPatient[item][0];
        const hasAtLeastOneTask = Boolean(firstTask);

        return hasAtLeastOneTask;
      })
      .map((item) => {
        const firstTask: TaskModel = createdByPatient[item][0];

        return {
          ...firstTask,
          patientCountInList: countHash[firstTask.patient_id],
        };
      });

    return {
      list: patientsList,
      uniques: patientsList?.length,
      moneyWidgetTasksListLength: TasksUtils.countMoneyWidget(patientsList),
    };
  };
}

export { TasksUtils };
