import styled from 'styled-components';

export const FieldStyled = styled.div`
  .custom-select-field__control {
    min-height: 38px; // Arbitrary value to align with other input fields
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; // Arbitrary value to align with other input fields. To align, see computed styles in dev tools in input
    border-color: #a4a4a4; // tw-gray-light
    font-size: 14px;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.375rem; // tw-rounded-md
    cursor: pointer;
  }

  .custom-select-field__control:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; // Arbitrary value to align with other input fields. To align, see computed styles in dev tools in input
    border-color: #a4a4a4; // tw-gray-light
  }

  .custom-select-field__control--is-focused,
  .custom-select-field__control--is-focused:hover {
    border-color: #095c70; // tw-cyan
    box-shadow: rgb(233, 63, 30) 0px 0px 0px 0px, rgb(9, 92, 112) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; // Arbitrary value to align with other input fields
  }

  .custom-select-field__dropdown-indicator {
    color: #a4a4a4; // tw-gray-light
  }

  .custom-select-field__indicator-separator {
    display: none;
  }

  .custom-select-field__dropdown-indicator:hover {
    color: #095c70; // tw-cyan
  }

  .custom-select-field__input input:focus {
    --tw-ring-color: none !important;
  }

  .custom-select-field__option {
    font-size: 14px;
    cursor: pointer;
  }

  .custom-select-container._error .custom-select-field__control {
    border-color: #f77c6d; // tw-red

    &:hover {
      border-color: #f77c6d; // tw-red
    }

    &--is-focused,
    &--is-focused:hover {
      border-color: #095c70; // tw-cyan
    }
  }
`;
