import React from 'react';
import { observer } from 'mobx-react';
import { default as Cnft } from 'react-confetti';

import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';
import ConfettiStore from 'store/ConfettiStore/ConfettiStore';

export const Confetti = observer(() => {
  const { width, height } = useWindowSize();

  return ConfettiStore.isOn ? <Cnft width={width} height={height} gravity={0.35} /> : '';
});
