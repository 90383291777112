import styled from 'styled-components';

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  & > div {
    position: relative;
  }

  & div > div {
    width: 48px;
    height: 48px;
  }
`;

export const LoaderTrack = styled.div`
  & circle {
    stroke: rgba(9, 92, 112, 0.4);
  }
`;

export const LoaderPath = styled.div`
  animation: loader-rotate 550ms linear infinite;
  position: absolute;
  top: 0;
  left: 0;

  & circle {
    stroke-dasharray: 32px, 200px;
    stroke: #095c70;
  }

  @keyframes loader-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
