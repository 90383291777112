import React, { useReducer } from 'react';

import { EmailDispatchContext, EmailStateContext } from '../emailContext/EmailContext';
import { emailReducer } from '../emailReducer/emailReducer';

import { EmailContextControllerProps } from './EmailContextController.types';

export const EmailContextController = ({ children }: EmailContextControllerProps) => {
  const [state, dispatch] = useReducer(emailReducer, {
    emailState: undefined,
  });

  return (
    <EmailStateContext.Provider value={{ ...state }}>
      <EmailDispatchContext.Provider value={dispatch}>{children}</EmailDispatchContext.Provider>
    </EmailStateContext.Provider>
  );
};
