import { WeeklyReportStateContextType } from '../weeklyReportContext/WeeklyReportContext.types';

import { WeeklyReportAction } from './weeklyReportReducer.types';

export const SET_NOTES = 'set-notes';
export const SET_WEEKLY_FORM_DATE = 'set-weekly-form-date';
export const SET_WEEKLY_NOTES_EDIT = 'set-weekly-notes-edit';

export const weeklyReportReducer: (
  state: WeeklyReportStateContextType,
  action: WeeklyReportAction,
) => WeeklyReportStateContextType = (state, action) => {
  const behaviours: Record<
    string,
    (state: WeeklyReportStateContextType, action: WeeklyReportAction) => WeeklyReportStateContextType
  > = {
    [SET_NOTES]: (state, { notes }) => {
      return {
        ...state,
        notes,
      };
    },
    [SET_WEEKLY_FORM_DATE]: (state, { weeklyFormDate }) => {
      return {
        ...state,
        weeklyFormDate,
      };
    },
    [SET_WEEKLY_NOTES_EDIT]: (state, { weeklyNotesEdit }) => {
      return {
        ...state,
        weeklyNotesEdit,
      };
    },
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
