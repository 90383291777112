import React from 'react';
import clsx from 'classnames';
import styled from 'styled-components';

const CircleContainer = styled.div`
  padding: 0 5px;
  min-width: 50px;

  // border-style: solid;
  // border-width: 0 1px 0 0;
  // border-color: rgba(174, 174, 174, 0.5);
`;
const Circle = styled.div`
  border: 2px solid rgba(164, 164, 164, 0.4);
  border-radius: 50%;
  margin: auto;
  &.full {
    background: rgba(164, 164, 164, 0.4);
    border-color: transparent;
  }
  &.empty {
    background: transparent;
  }
`;

const sizeEnum = {
  0: 'empty',
  1: 'full',
  2: 'full',
};

const CommitmentYesNoCircle = ({ count = 0 }: { count?: number }) => {
  const maxCount: number = count > 2 ? 2 : count;
  const className: string = clsx('w-2/4 h-2/4', sizeEnum[maxCount]);

  return (
    <CircleContainer className="w-10 h-10 flex">
      <Circle className={className}></Circle>
    </CircleContainer>
  );
};

export { CommitmentYesNoCircle };
