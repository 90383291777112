import React, { useEffect, useState } from 'react';

import { useAuthState, useLocale, useModalDispatch, useMembers } from 'hooks';
import { customAxios } from 'hooks/useCustomAxios/useCustomAxios';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { InviteMembers } from './InviteMembers';
import { AddMemberBody } from './InviteMembers.types';

export const InviteMemberContainer = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuthState();
  const { close } = useModalDispatch();
  const { open: openSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { getMembers } = useMembers();

  useEffect(() => {
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewMember = async (values: AddMemberBody) => {
    setLoading(true);

    const addData = {
      email: values.email,
      firstname: values.firstName,
      lastname: values.lastName,
      position_type: values?.position?.value,
      mobile_no: values.mobileNumber,
      medicId: user?.id,
    };

    customAxios({ method: 'post', url: '/members', data: addData })
      .then((res) => {
        if (res && res?.data) {
          setLoading(false);
          openSnackbar({
            severity: 'success',
            title: formatMessage({ id: 'members.snackbar.success' }),
            description: '',
            autoHideDuration: 5000,
          });
          getMembers();
          onClose();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          openSnackbar({
            severity: 'error',
            title: formatMessage({ id: 'common.emailConflict' }),
            description: ' ',
            autoHideDuration: 5000,
          });
        }
      });
  };

  const onClose = () => {
    close('add-member');
  };

  return <InviteMembers onAddNewMember={onAddNewMember} onClose={onClose} loading={loading} />;
};
