import React from 'react';

import { LoaderProps } from './Loader.types';
import { Loader as LoaderBase, LoaderPath, LoaderTrack } from './styled';

export const Loader = ({ className, ...props }: LoaderProps) => (
  <LoaderBase className={className} {...props}>
    <div>
      <LoaderTrack>
        <svg viewBox="22 22 44 44">
          <circle cx="44" cy="44" r="20" fill="none" strokeWidth="4"></circle>
        </svg>
      </LoaderTrack>
      <LoaderPath>
        <svg viewBox="22 22 44 44">
          <circle cx="44" cy="44" r="20" fill="none" strokeWidth="4"></circle>
        </svg>
      </LoaderPath>
    </div>
  </LoaderBase>
);

export default Loader;
