import { FormatMessageFunction } from 'utils/types';

export type MemberPositionType = {
  value: string;
  label: string;
};

export const memberPositionsOptions = (formatMessage: FormatMessageFunction): MemberPositionType[] => {
  return [
    {
      value: 'Physician',
      label: formatMessage({ id: 'common.role.medic' }),
    },
    {
      value: 'Medical Nurse',
      label: formatMessage({ id: 'members.select_position.medical_nurse' }),
    },
    {
      value: 'Practice Organisation',
      label: formatMessage({ id: 'members.select_position.practice_organisation' }),
    },
    {
      value: 'Student',
      label: formatMessage({ id: 'members.select_position.student' }),
    },
    {
      value: 'IT Manager',
      label: formatMessage({ id: 'members.select_position.it_manager' }),
    },
    {
      value: 'Pool Account',
      label: formatMessage({ id: 'members.select_position.pool_account' }),
    },
    {
      value: 'Management',
      label: formatMessage({ id: 'members.select_position.management' }),
    },
  ];
};
