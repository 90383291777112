import { weekTrendsChartItem, WeekBar } from './weekTrendChart.types';

export const detmineFillColorSystolic = (systolic: number) => {
  if (systolic >= 130 && systolic <= 159) {
    return '#DE9A14';
  } else if (systolic >= 160) {
    return '#f77c6d';
  }

  return '#AEAEAE';
};

export const detmineFillColorDiastolic = (diastolic: number) => {
  if (diastolic >= 85 && diastolic <= 99) {
    return '#DE9A14';
  } else if (diastolic >= 100) {
    return '#f77c6d';
  }

  return '#AEAEAE';
};

export const transformChartData = (weeklyTrend: weekTrendsChartItem[]) => {
  const transformed = weeklyTrend.map((wgr) => {
    return {
      name: Object.keys(wgr)[0],
      systolic: wgr[Object.keys(wgr)[0]].systolic,
      diastolic: wgr[Object.keys(wgr)[0]].diastolic,
    };
  });

  return transformed;
};

export const getContainerwidth = (chartData: WeekBar[]) => {
  let returnVal;

  switch (chartData.length) {
    case 1:
      returnVal = 30;
      break;
    case 2:
      returnVal = 55;
      break;
    case 3:
      returnVal = 75;
      break;
    case 4:
      returnVal = 100;
      break;
    case 5:
      returnVal = 115;
      break;
    case 6:
      returnVal = 135;
      break;
    default:
      returnVal = 150;
      break;
  }

  return returnVal;
};
