import React from 'react';

import SunriseIcon from 'components/Icons/SunriseIcon';
import MoonIcon from 'components/Icons/MoonIcon';

import { CircleContainer, IconContainer } from './styled';

const CommitmentDayNight = ({ morning = false, night = false }: { morning?: boolean; night?: boolean }) => {
  const morningStroke = morning ? '#111111' : 'rgba(174, 174, 174, 0.5)';
  const nightStroke = night ? '#111111' : 'rgba(174, 174, 174, 0.5)';

  return (
    <CircleContainer dayNight className="h-8 flex flex-row">
      <IconContainer>
        <SunriseIcon stroke={morningStroke} height={20} width={20} />
      </IconContainer>
      <IconContainer>
        <MoonIcon stroke={nightStroke} height={20} width={20} />
      </IconContainer>
    </CircleContainer>
  );
};

export { CommitmentDayNight };
