import { makeObservable, observable, action } from 'mobx';
abstract class AbstractMultiValueFilterStore {
  slotOptions: string[] = [];
  currentSlot?: string[] = [];
  noneIsAll = false;

  setCurrentSlot = (value: string): void => {
    if (!this.currentSlot?.map((item) => item).includes(value)) {
      this.currentSlot?.push(value);
    } else {
      this.currentSlot.splice(this.currentSlot.indexOf(value), 1);
    }

    if (this.noneIsAll) this._forceNoneIsAll();
  };

  setCurrentSlots = (value: string[]): void => {
    this.currentSlot = value;

    if (this.noneIsAll) this._forceNoneIsAll();
  };

  _forceNoneIsAll(): void {
    if (this.currentSlot?.length === 0) {
      this.currentSlot = [...this.slotOptions];
    }
  }

  constructor(slotOptions: string[], defaultSlot?: string[], noneIsAll?: boolean) {
    this.slotOptions = [...slotOptions];
    this.currentSlot = defaultSlot ? [...defaultSlot] : [];
    this.noneIsAll = Boolean(noneIsAll);

    makeObservable(this, {
      slotOptions: observable,
      currentSlot: observable,
      setCurrentSlot: action,
    });
  }
}

export default AbstractMultiValueFilterStore;
