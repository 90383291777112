import { makeObservable, action, observable } from 'mobx';
import { DateTime } from 'luxon';

abstract class AbstractTimeRangeFilterStore {
  emptyIsToday: boolean;
  daysOnlyFilter: boolean;
  from: Date | null | undefined;
  to: Date | null | undefined;

  public setFrom(value: Date | null): void {
    if (value === null && this.emptyIsToday === false) {
      this.to = null;

      return;
    }

    let newValue = value || new Date();

    if (this.daysOnlyFilter) {
      newValue = DateTime.fromJSDate(newValue).startOf('day').toJSDate();
    }

    this.from = newValue;
  }

  public setTo(value: Date | null): void {
    if (value === null && this.emptyIsToday === false) {
      this.to = null;

      return;
    }

    let newValue = value || new Date();

    if (this.daysOnlyFilter) {
      newValue = DateTime.fromJSDate(newValue).endOf('day').toJSDate();
    }

    this.to = newValue;
  }

  constructor({
    from = new Date(),
    to = new Date(),
    emptyIsToday = true,
    daysOnlyFilter = true,
  }: {
    from: Date | null;
    to: Date | null;
    emptyIsToday: boolean;
    daysOnlyFilter: boolean;
  }) {
    this.setFrom(from);
    this.setTo(to);
    this.emptyIsToday = emptyIsToday;
    this.daysOnlyFilter = daysOnlyFilter;

    makeObservable(this, {
      from: observable,
      to: observable,
      setFrom: action,
      setTo: action,
    });
  }
}

export default AbstractTimeRangeFilterStore;
