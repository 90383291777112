import React from 'react';
import { Field } from 'formik';

type CustomFieldProps = {
  id: string;
  name: string;
  label: string;
  required: boolean;
  isError: boolean;
  placeholder: string;
};

const CustomField = ({ id, name, label, required, isError, placeholder }: CustomFieldProps) => {
  return (
    <label htmlFor="firstName" className="block w-full mt-3 text-base text-gray-light">
      <span>
        {label}
        {required && <span className="text-red-dark ml-1">{'*'}</span>}
      </span>
      <Field
        id={id}
        type="text"
        data-testid={`e2e-${id}`}
        name={name}
        className={`mt-1 shadow-sm block w-full sm:text-sm border-${
          isError ? 'red' : 'gray-light'
        }  text-gray placeholder-gray-light rounded-md ${
          isError && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
        }`}
        placeholder={placeholder}
        required
      />
    </label>
  );
};

export default CustomField;
