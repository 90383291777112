import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import InternationlNumber from 'components/InternationalNumber/InternationalNumber';

import { FormikType } from './Assign24hDiagnosisStep';

const DeliveryDetailsFields = () => {
  const { formatMessage } = useLocale();
  const { errors, values } = useFormikContext<FormikType>();

  return (
    <>
      <label htmlFor="company" className="block text-base text-gray-light mt-3">
        <span>{formatMessage({ id: 'enroll_new_patient.placeholder_company' })}</span>
        <Field
          type="text"
          data-testid="e2e-enroll-company"
          name="company"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.company ? 'red' : 'gray-light'} ${
            errors.company && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_company' })}
        />
      </label>
      <div className="flex">
        <label htmlFor="street" className="w-full mr-3 block text-base text-gray-light mt-3">
          <span>
            {formatMessage({ id: 'enroll_new_patient.placeholder_street' })}
            <span className="text-red-dark ml-1">{'*'}</span>
          </span>
          <Field
            type="text"
            data-testid="e2e-enroll-street"
            name="street"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.street ? 'red' : 'gray-light'} ${
              errors.street && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            } text-gray placeholder-gray-light rounded-md pii`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_street' })}
            required
          />
        </label>
        <label htmlFor="number" className="block text-base text-gray-light mt-3">
          <span>
            {formatMessage({ id: 'enroll_new_patient.placeholder_number' })}
            <span className="text-red-dark ml-1">{'*'}</span>
          </span>
          <Field
            type="text"
            data-testid="e2e-enroll-number"
            name="streetNumber"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.streetNumber ? 'red' : 'gray-light'} ${
              errors.streetNumber && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            } text-gray placeholder-gray-light rounded-md pii`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_number' })}
            required
          />
        </label>
      </div>
      <div className="flex">
        <label htmlFor="postalCode" className="w-full mr-3 block text-base text-gray-light mt-3">
          <span>
            {formatMessage({ id: 'enroll_new_patient.placeholder_postal_code' })}
            <span className="text-red-dark ml-1">{'*'}</span>
          </span>
          <Field
            type="text"
            data-testid="e2e-enroll-postalCode"
            name="postalCode"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.postalCode ? 'red' : 'gray-light'} ${
              errors.postalCode && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            } text-gray placeholder-gray-light rounded-md pii`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_postal_code' })}
            required
          />
        </label>
        <label htmlFor="city" className="w-full block text-base text-gray-light mt-3">
          <span>
            {formatMessage({ id: 'enroll_new_patient.placeholder_city' })}
            <span className="text-red-dark ml-1">{'*'}</span>
          </span>
          <Field
            type="text"
            data-testid="e2e-enroll-city"
            name="city"
            className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.city ? 'red' : 'gray-light'} ${
              errors.city && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
            } text-gray placeholder-gray-light rounded-md pii`}
            placeholder={formatMessage({ id: 'enroll_new_patient.placeholder_city' })}
            required
          />
        </label>
      </div>
      <label htmlFor="mobileNumber" className="mt-3 block text-base text-gray-light">
        <span>
          {formatMessage({ id: 'enroll_new_patient.mobile_number_optional' })}
          {values.send24HDiagnosis && <span className="text-red-dark ml-1">{'*'}</span>}
        </span>
        <InternationlNumber
          name="mobileNumber"
          dataTestId="e2e-enroll-mobile"
          isError={!!errors.mobileNumber}
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.mobileNumber ? 'red' : 'gray-light'} ${
            errors.mobileNumber && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          } text-gray placeholder-gray-light rounded-md pii`}
        />
      </label>
    </>
  );
};

export default DeliveryDetailsFields;
