import React from 'react';

const UserPlusIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.5C9.34315 8.5 8 9.84315 8 11.5C8 13.1569 9.34315 14.5 11 14.5C12.6569 14.5 14 13.1569 14 11.5C14 9.84315 12.6569 8.5 11 8.5ZM6.5 11.5C6.5 9.01472 8.51472 7 11 7C13.4853 7 15.5 9.01472 15.5 11.5C15.5 13.9853 13.4853 16 11 16C8.51472 16 6.5 13.9853 6.5 11.5ZM7.55598 17.9896C7.49902 17.9941 7.4195 18 7.34087 18H7C5.34315 18 4 19.3431 4 21V22.1883C4 22.5739 4.2795 22.9028 4.66012 22.9649C8.85891 23.6504 13.1411 23.6504 17.3399 22.9649C17.7205 22.9028 18 22.5739 18 22.1883V21C18 19.3431 16.6569 18 15 18H14.6591C14.5805 18 14.501 17.9941 14.444 17.9896C14.4245 17.9881 14.4068 17.9866 14.3903 17.9853C14.3502 17.982 14.3172 17.9793 14.2814 17.9772C14.2385 17.9746 14.2106 17.9742 14.193 17.9747C14.1301 17.9915 14.0652 18 14 18H8C7.93479 18 7.86991 17.9915 7.80701 17.9747C7.78945 17.9742 7.76146 17.9746 7.71859 17.9772C7.68284 17.9793 7.64978 17.982 7.60966 17.9853C7.59318 17.9866 7.57551 17.9881 7.55598 17.9896ZM7.8206 17.9755C7.82057 17.9756 7.81968 17.9755 7.81803 17.9753L7.8206 17.9755ZM14.1794 17.9756C14.1793 17.9755 14.1801 17.9754 14.182 17.9753L14.1794 17.9756ZM8.08662 16.5C7.89825 16.4646 7.72126 16.4744 7.62912 16.4799C7.53289 16.4856 7.47802 16.4906 7.43875 16.4942C7.39904 16.4978 7.37529 16.5 7.34087 16.5H7C4.51472 16.5 2.5 18.5147 2.5 21V22.1883C2.5 23.3091 3.31227 24.2647 4.41842 24.4453C8.77729 25.157 13.2227 25.157 17.5816 24.4453C18.6877 24.2647 19.5 23.3091 19.5 22.1883V21C19.5 18.5147 17.4853 16.5 15 16.5H14.6591C14.6247 16.5 14.601 16.4978 14.5612 16.4942C14.522 16.4906 14.4671 16.4856 14.3709 16.4799C14.2787 16.4744 14.1018 16.4646 13.9134 16.5H8.08662Z"
      fill="#A4A4A4"
    />
    <path d="M20.5 10V1" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16 5.5L25 5.5" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default UserPlusIcon;
