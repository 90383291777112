import React, { ForwardedRef, forwardRef } from 'react';
import clsx from 'classnames';

import { FontSize } from 'enums/font';
import BtnLoader from 'components/Icons/BtnLoader';

import { ButtonProps, ButtonVariant } from './Button.types';
import styles from './Button.module.scss';

function getButtonTextColor(disabled: boolean, variant?: ButtonVariant): string {
  let colorClass;

  if (disabled) {
    colorClass = 'text-white';
  } else if (variant === 'primary') {
    colorClass = 'text-white';
  } else if (variant === 'inherit') {
    colorClass = 'text-gray-light';
  } else {
    colorClass = 'text-cyan';
  }

  return colorClass;
}

function getButtonBorderColor(disabled: boolean, variant?: ButtonVariant): string {
  let colorClass;

  if (disabled) {
    colorClass = 'border-gray-borderLight';
  } else if (variant === 'primary') {
    colorClass = 'border-cyan';
  } else if (variant === 'inherit') {
    colorClass = 'border-gray-light';
  } else {
    colorClass = 'border-cyan';
  }

  return colorClass;
}

function getButtonBgColor(disabled: boolean, variant?: ButtonVariant): string {
  let colorClass;

  if (disabled) {
    colorClass = 'bg-gray-lightest';
  } else if (variant === 'primary') {
    colorClass = 'bg-cyan';
  } else if (variant === 'inherit') {
    colorClass = 'bg-transparent';
  } else {
    colorClass = 'bg-transparent';
  }

  return colorClass;
}

const Button = forwardRef(
  (
    {
      id,
      type = 'button',
      width = 'auto',
      fontSize = FontSize.lg,
      variant,
      children,
      onClick,
      className,
      disabled,
      paddingX = 3,
      paddingY = 3,
      circlic = false,
      hasShadow = false,
      justify = 'center',
      testId,
      borderWidth = 0,
      btnLoader = false,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    // const props = id ? { id } : null;
    const colorClass = getButtonTextColor(!!disabled, variant);
    const borderColorClass = getButtonBorderColor(!!disabled, variant);
    const bgColorClass = getButtonBgColor(!!disabled, variant);

    return (
      <button
        ref={ref}
        type={type}
        data-testid={testId}
        className={clsx(
          className,
          `w-${width} inline-flex items-center justify-${justify} px-${paddingX} py-${paddingY} border-${
            variant === 'secondary' ? '2' : borderWidth
          } text-${fontSize} font-medium rounded-${circlic ? 'full' : 'md'}
        ${colorClass}
        ${borderColorClass}
        ${bgColorClass}`,
          styles.button,
          (variant === 'text' || !hasShadow) && styles.noShadow,
          disabled && 'cursor-not-allowed',
          btnLoader && 'relative',
        )}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {btnLoader && (
          <div className="absolute top-1/2 left-1/2 -ml-2.5 -mt-2.5">
            <BtnLoader variant={variant} />
          </div>
        )}

        <div className={btnLoader ? 'opacity-0' : ''}>{children}</div>
      </button>
    );
  },
);

export default Button;
