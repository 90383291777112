// import { makeObservable, action } from 'mobx';

import { PatientsTasksStore } from '../PatientTasksStore';

import { AbstractTaskCompleteStore } from './AbstractTaskCompleteStore';

class InspectionsCompleteStore extends AbstractTaskCompleteStore {
  public load(): void {
    this.tasks = PatientsTasksStore.inspections;
    // iterate this.tasks and set the nextOfType
    this.setNextPrevOfType();
  }

  // constructor() {
  //   super();
  //   makeObservable(this, {
  //     load: action,
  //   });
  // }
}

const store = new InspectionsCompleteStore();

export { store as InspectionsCompleteStore };
