export abstract class DateFormat {
  abstract locale: string;
  abstract regularDate: string;
  abstract graphDate: string;
  abstract shortDayMonth: string;
  abstract fullDate: string;
}

class enDateFormat extends DateFormat {
  locale = 'en';
  regularDate = 'MMM dd yyyy';
  graphDate = 'MMM dd';
  shortDayMonth = 'MM/dd';
  fullDate = 'MM/dd/yyyy';
}

class deDateFormat extends DateFormat {
  locale = 'de';
  regularDate = 'd. MMM yyyy';
  graphDate = 'd. MMM';
  shortDayMonth = 'dd.MM';
  fullDate = 'dd.MM.yyyy';
}

export const DateFormatsLocale = {
  de: deDateFormat,
  en: enDateFormat,
};
