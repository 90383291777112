import React from 'react';
import { useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import { SelectField } from 'components';
import { memberPositionsOptions } from 'api/mocks/memberPositionsType';
import CustomField from 'components/form/CustomField/CustomField';

import { BasicInfoStepFormikProps } from './steps.types';

export const BasicInfoStep = () => {
  const { formatMessage } = useLocale();
  const { errors, values } = useFormikContext<BasicInfoStepFormikProps>();

  return (
    <div>
      <div className="flex">
        <div className="mr-3 w-full">
          <CustomField
            id="firstName"
            name="firstName"
            label={formatMessage({ id: 'enroll_new_patient.firstname' })}
            placeholder={formatMessage({ id: 'enroll_new_patient.firstname' })}
            required
            isError={!!errors.firstName}
          />
        </div>
        <div className="w-full">
          <CustomField
            id="lastName"
            name="lastName"
            label={formatMessage({ id: 'enroll_new_patient.lastname' })}
            placeholder={formatMessage({ id: 'enroll_new_patient.lastname' })}
            required
            isError={!!errors.lastName}
          />
        </div>
      </div>

      <SelectField
        name="position"
        options={memberPositionsOptions(formatMessage)}
        labelId="members.select_position"
        values={values?.position}
        isMulti={false}
        placeholderId="members.select_position"
        className="pii"
        marginTop={3}
      />

      <CustomField
        id="email"
        name="email"
        label={formatMessage({ id: 'enroll_new_patient.email' })}
        placeholder="you@example.com"
        required
        isError={!!errors.email}
      />
    </div>
  );
};
