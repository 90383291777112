import { AxiosPromise } from 'axios';
import { makeObservable, observable, action, computed } from 'mobx';

import { customAxios } from 'hooks/useCustomAxios/useCustomAxios';
import { TaskModel } from 'models/task';
import { TaskTypes, TaskStatus } from '../../app/tasks/Tasks.types';
import { TasksUtils } from '../../utils/TasksUtils';

import { BloodPressureStore } from './BloodPressureStore';

type TasksFilter = {
  type?: string[];
  status?: string[];
  excludeCurrentPeriod?: boolean;
};

class PatientsTasksStore {
  tasks: TaskModel[] = [];
  patiendId: string | null = null;

  setTasks = (tasks: TaskModel[]): void => {
    this.tasks = tasks;
  };

  fetchPatientTasks = (patientId: string): void => {
    customAxios({ method: 'post', url: `/patients/${patientId}/tasks` }).then((res) => {
      if (res && res?.data) {
        this.setTasks(res.data);
        this.patiendId = patientId;
      }
    });
  };

  completeTask = (
    patientId: string,
    taskId: string,
    isBillable: boolean,
    emailId: string | null,
    billing_code: string,
    factor: number,
    amount: number,
    memberId: string | undefined,
    name: string,
    report?: { name: string; url: string },
    isAutoPilot?: boolean,
    email?: string,
  ): AxiosPromise<unknown> => {
    const context = { readings: BloodPressureStore.FilteredBpAsc, pdf_url: report?.url, email: email };

    return customAxios({
      method: 'post',
      url: `/patients/${patientId}/tasks/${taskId}/complete/${isAutoPilot || false}`,
      data: {
        context,
        isBillable,
        billingCode: billing_code,
        factor,
        amount,
        name,
        quantity: 1,
        totalAmount: amount * 1,
        ...(emailId && { emailId }),
        ...(memberId && { memberId }),
      },
    });
  };

  dismissTask = (patientId: string, taskId: string): AxiosPromise<unknown> => {
    return customAxios({
      method: 'post',
      url: `/patients/${patientId}/tasks/${taskId}/dismiss`,
    });
  };

  set patientId(patientId: string) {
    if (this.patiendId !== patientId) {
      this.fetchPatientTasks(patientId);
    }
  }

  filterTasks = (filter: TasksFilter): TaskModel[] => {
    // if filter is empty, filter by all filter options
    const type = filter.type && filter.type.length > 0 ? filter.type : Object.values(TaskTypes);
    const status = filter.status && filter.status.length > 0 ? filter.status : Object.values(TaskStatus);

    return this.tasks.filter((task: TaskModel) => {
      const currentPeriodFilter = () => {
        return filter.excludeCurrentPeriod ? TasksUtils.isTaskNotInCurrentPeriod(task) : true;
      };
      // console.log('currentPeriodFilter :>> ', currentPeriodFilter, 'task.status', task.status);

      return type.includes(task.type) && status.includes(task.status) && currentPeriodFilter();
    });
  };

  get hasDueReports(): boolean {
    const has =
      this.filterTasks({ type: [TaskTypes.REPORT], status: [TaskStatus.CREATED], excludeCurrentPeriod: true }).length >
      0;

    return has;
  }

  get hasInspectionsToRegister(): boolean {
    const has =
      this.filterTasks({ type: [TaskTypes.INSPECTION], status: [TaskStatus.CREATED], excludeCurrentPeriod: true })
        .length > 0;

    return has;
  }

  get reportsToComplete(): TaskModel[] {
    return this.filterTasks({
      type: [TaskTypes.REPORT],
      status: [TaskStatus.CREATED],
      excludeCurrentPeriod: true,
    });
  }

  get inspectionsToComplete(): TaskModel[] {
    return this.filterTasks({
      type: [TaskTypes.INSPECTION],
      status: [TaskStatus.CREATED],
      excludeCurrentPeriod: true,
    });
  }

  static sortByStartDateDesc = (a: TaskModel, b: TaskModel): number => {
    if (a.start_date > b.start_date) return -1;

    if (a.start_date < b.start_date) return 1;

    return 0;
  };

  get dueReports(): TaskModel[] {
    return this.filterTasks({
      type: [TaskTypes.REPORT],
      status: [TaskStatus.CREATED, TaskStatus.COMPLETED, TaskStatus.DISMISSED],
    }).sort(PatientsTasksStore.sortByStartDateDesc);
  }

  get inspections(): TaskModel[] {
    return this.filterTasks({
      type: [TaskTypes.INSPECTION],
      status: [TaskStatus.CREATED, TaskStatus.COMPLETED, TaskStatus.DISMISSED],
    }).sort(PatientsTasksStore.sortByStartDateDesc);
  }

  constructor() {
    makeObservable(this, {
      tasks: observable,
      setTasks: action,
      filterTasks: action,
      fetchPatientTasks: action,
      hasDueReports: computed,
      hasInspectionsToRegister: computed,
      reportsToComplete: computed,
      inspectionsToComplete: computed,
      dueReports: computed,
      inspections: computed,
    });
  }
}

const store = new PatientsTasksStore();
export { store as PatientsTasksStore };
