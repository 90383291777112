import { FormatMessageFunction } from 'utils/types';

import { StoppedTreatmentPatientModel } from './patient';

export type DeviceModel = {
  id?: string;
  id_auto?: number;
  admated_at?: string;
  curin_id?: string;
  current_status?: DeviceStatus;
  shipping_status?: DeviceShippingStatus;
  imei?: string;
  label?: string;
  medic_id?: string;
  patient_id?: string;
  last_patient_id?: string;
  updated_at?: string;
  medic_name?: string;
  patient_name?: string;
  patient_treatment?: StoppedTreatmentPatientModel;
  is_treatment_going_on?: boolean;
  practice_name?: string;
  type?: number;
};

export type DevicePatientModel = {
  id?: string;
  email?: string;
  fullname?: string;
  mobile_no?: string;
  address?: string;
  date_of_birth?: string;
  status?: DeviceStatus;
  hashedEmail?: string;
  remarks?: string;
  phone_no?: string;
  patient_id_vps?: string;
  device?: [
    {
      id: string;
      imei: string;
    },
  ];
};

export type TheDeviceMedicPatient = {
  ids?: string[];
  medic_id?: string;
  patient_id?: string;
};

export type DeviceAssignmentBody = {
  data?: TheDeviceMedicPatient;
  type?: string;
};

export enum DeviceStatus {
  NOT_ASSIGNED = 1,
  ASSIGNED_TO_MEDIC = 2,
  ASSIGNED_TO_PATIENT = 3,
  DAMAGED = 4,
  IN_SHIPPING = 5,
  WRITTEN_OFF = 6,
}

export type DeviceShippingStatus = 'TO_PATIENT' | 'AT_THE_PATIENT' | 'TO_PRACTICE' | 'AT_THE_PRACTICE';

export type DeviceShippingStatusMap = Record<DeviceShippingStatus, string>;

export const ShippingStatusLabel: DeviceShippingStatusMap = {
  TO_PATIENT: 'devices.shipping.to_patient',
  AT_THE_PATIENT: 'devices.shipping.at_patient',
  TO_PRACTICE: 'devices.shipping.to_medic',
  AT_THE_PRACTICE: 'devices.shipping.at_medic',
};

export const shippingStatusOptions = (formatMessage: FormatMessageFunction) => {
  const options = Object.keys(ShippingStatusLabel).map((statusString: string) => ({
    value: statusString,
    label: formatMessage({ id: ShippingStatusLabel[statusString as DeviceShippingStatus] }),
  }));

  return [
    {
      value: 'None',
      label: formatMessage({ id: 'devices.shipping.no_status' }),
    },
    ...options,
  ];
};
