import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { Form, Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { useLocale, useModalState } from 'hooks';
import EllipsisIcon from 'components/Icons/EllipsisIcon';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import DeviceContextController from 'context/devices/deviceContextController/deviceContextController';

import { BasicInfoStep } from './steps/basicInfoStep/BasicInfoStep';
import { ContactInfoStep } from './steps/contactInfoStep/ContactInfoStep';
import { AddMedicBody, ErrorBody, AddMedicProps } from './AddMedicModal.types';

// const phoneRegExp = /^((\\+[1-9][ \\-]*)|(\\([0-9]\\)[ \\-]*)|([0-9])[ \\-]*)*?[0-9]?[ \\-]*[0-9]?$/;

export const AddMedicModal = ({ onAddNewMedic, setCurrentStep, currentStep, onClose }: AddMedicProps) => {
  const { isOpen } = useModalState('add-medic');
  const { formatMessage } = useLocale();
  const { open: openSnackbar } = useSnackbar();

  const EnrollSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(1, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required(formatMessage({ id: 'common.required' })),
    // lastName: Yup.string()
    //   .min(1, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required(formatMessage({ id: 'common.required' })),
    email: Yup.string()
      .email('Invalid email')
      .required(formatMessage({ id: 'common.required' })),
    // birthday: Yup.string().required(formatMessage({ id: 'common.required' })),
    clinic: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    // phoneNumber: Yup.string()
    //   .matches(phoneRegExp, formatMessage({ id: 'common.invalid_format' }))
    //   .required(formatMessage({ id: 'common.required' })),
    accountType: Yup.object({
      value: Yup.string()
        .oneOf(['CUSTOMER', 'TEST', 'ARCHIVED', 'INTERNAL'])
        .required(formatMessage({ id: 'common.required' })),
    }),
  });

  const returnToUnvalidatedStep = (errors: FormikErrors<ErrorBody>) => {
    // if (errors.firstName || errors.lastName || errors.clinic || errors.accountType) {
    if (errors.clinic || errors.accountType) {
      setCurrentStep(0);
      // } else if (errors.email || errors.birthday || errors.phoneNumber) {
    } else if (errors.email) {
      setCurrentStep(1);
    }
  };
  const onValidationFailure = (
    event: React.FormEvent<HTMLFormElement>,
    values: AddMedicBody,
    errors: FormikErrors<ErrorBody>,
  ) => {
    event.preventDefault();

    if (
      // errors.firstName ||
      // errors.lastName ||
      errors.email ||
      // errors.birthday ||
      // errors.phoneNumber ||
      errors.clinic ||
      errors.accountType
    ) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'enroll_new_medic.snackbar.validation_error_title' }),
        description: formatMessage({ id: 'enroll_new_medic.snackbar.validation_error_description' }),
        autoHideDuration: 2000,
      });

      returnToUnvalidatedStep(errors);
    }
  };

  const handleSubmitMedic = (values: AddMedicBody) => {
    onAddNewMedic(values);
  };

  const steps = [
    {
      name: 'enroll_new_medic.step.basic_info',
    },
    {
      name: 'enroll_new_medic.step.contact_info',
    },
  ];

  // const date = new Date();

  const initialValues = {
    firstName: '',
    lastName: '',
    birthday: null, //new Date(date.getFullYear() - 55, date.getMonth(), date.getDate()),
    clinic: '',
    email: '',
    phoneNumber: '',
    accountType: 'TEST',
    sendNotification: true,
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center"
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            data-testid="e2e-enroll-patient-modal"
            className="relation bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg"
          >
            <Formik initialValues={initialValues} onSubmit={handleSubmitMedic} validationSchema={EnrollSchema}>
              {({ handleSubmit, isValid, values, errors }) => (
                <Form
                  onSubmit={(e) => {
                    isValid ? handleSubmit(e) : onValidationFailure(e, values, errors);
                  }}
                >
                  <div className="p-10">
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-xl font-bold text-gray">
                        {formatMessage({ id: 'enroll_new_medic.title' })}
                      </Dialog.Title>
                    </div>

                    <div className="mt-7 flex flex-col justify-center items-center">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-12 sm:text-left">
                        <div className="mt-2 text-center">
                          <ol className="flex items-center space-x-3">
                            {steps.map((step, index) => (
                              <li key={formatMessage({ id: step.name })} className="flex items-center">
                                {index > 0 && (
                                  <div className="flex items-center">
                                    <div className={`mr-4 ${index <= currentStep ? 'text-cyan' : 'text-gray-light'}`}>
                                      <EllipsisIcon />
                                    </div>
                                    <div className={`mr-4 ${index <= currentStep ? 'text-cyan' : 'text-gray-light'}`}>
                                      <EllipsisIcon />
                                    </div>
                                    <div className={`mr-4 ${index <= currentStep ? 'text-cyan' : 'text-gray-light'}`}>
                                      <EllipsisIcon />
                                    </div>
                                  </div>
                                )}
                                {index <= currentStep ? (
                                  <span className="relative w-5 h-5 flex items-center justify-center bg-cyan rounded-full">
                                    <CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
                                    <span className="absolute top-7 left-0 text-base whitespace-nowrap text-cyan">
                                      {formatMessage({ id: step.name })}
                                    </span>
                                  </span>
                                ) : (
                                  <>
                                    <div className="inset-0 flex items-center" aria-hidden="true">
                                      <div className="h-0.5 w-full bg-gray-200" />
                                    </div>
                                    <span className="group relative w-5 h-5 flex items-center justify-center bg-white">
                                      <span
                                        className="h-5 w-5 border-2 border-gray-light rounded-full bg-white"
                                        aria-hidden="true"
                                      />
                                      <span className="absolute top-7 left-0  text-base whitespace-nowrap text-gray">
                                        {formatMessage({ id: step.name })}
                                      </span>
                                    </span>
                                  </>
                                )}
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                      <DeviceContextController>
                        <div className="mt-12 flex flex-col justify-center">
                          {currentStep === 0 && <BasicInfoStep />}
                          {currentStep === 1 && <ContactInfoStep />}
                        </div>
                      </DeviceContextController>
                    </div>
                  </div>

                  <div className="p-4 bg-gray-background sm:flex sm:flex-row-reverse">
                    {currentStep === steps.length - 1 && (
                      <button
                        type="submit"
                        data-testid="e2e-enroll-finish-btn"
                        className="ml-3 inline-flex rounded-md shadow-sm px-4 py-2 bg-cyan text-base font-bold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        {formatMessage({ id: 'common.finish' })}
                      </button>
                    )}
                    {currentStep < steps.length - 1 && (
                      <button
                        type="button"
                        data-testid="e2e-enroll-next-btn"
                        className="ml-3 inline-flex rounded-md shadow-sm px-4 py-2 bg-cyan text-base font-bold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setCurrentStep(Math.min(3, currentStep + 1))}
                      >
                        {formatMessage({ id: 'common.next' })}
                      </button>
                    )}
                    {currentStep > 0 && (
                      <button
                        type="button"
                        data-testid="e2e-enroll-back-btn"
                        className="inline-flex justify-center rounded-md border border-gray-light shadow-sm px-4 py-2 bg-transparent text-base font-bold text-gray focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
                      >
                        {formatMessage({ id: 'common.back' })}
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
