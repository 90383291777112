import { makeObservable, action, observable } from 'mobx';

import { DUE_REPORT, EVALUATION_INSPECTION } from 'utils/constants.enum';
import { PatientModel } from 'models/patient';
import { TaskModel, MedicTasksByPatient, PatientTasks } from 'models/task';
import { TaskStatus, TaskTypes } from 'app/tasks/Tasks.types';
import { TasksUtils } from 'utils/TasksUtils';
import { isPrivatePatient } from 'utils/patientProfile';
import { PatientsUtils } from 'store/PatientStore/PatientsUtils';

class MedicTaskStore {
  raw: TaskModel[] = [];
  tasksToComplete: TaskModel[] = [];
  byPatient: MedicTasksByPatient = {};
  moneyToTake: { [key in TaskTypes]: number } = { REPORT: 0, INSPECTION: 0 };

  setRaw = (tasks: TaskModel[]): void => {
    this.raw = tasks;
  };

  setByPatient = (tasksByPatient: MedicTasksByPatient): void => {
    this.byPatient = tasksByPatient;
  };

  isTaskToComplete = (task: TaskModel): boolean => {
    return task.status === TaskStatus.CREATED && !TasksUtils.isTaskInCurrentPeriod(task);
  };

  createPatientGroups = (tasks: TaskModel[]): MedicTasksByPatient => {
    const tasksByPatient: MedicTasksByPatient = {};

    tasks.forEach((task) => {
      if (task.patient_id) {
        if (!tasksByPatient[task.patient_id]) {
          tasksByPatient[task.patient_id] = {
            all: [],
            byType: {
              all: {
                INSPECTION: [],
                REPORT: [],
              },
              toComplete: {
                INSPECTION: [],
                REPORT: [],
              },
            },
          };
        }

        tasksByPatient[task.patient_id].all.push(task);
        tasksByPatient[task.patient_id].byType.all[task.type].push(task);

        if (this.isTaskToComplete(task)) {
          tasksByPatient[task.patient_id].byType.toComplete[task.type].push(task);
        }
      }
    });

    return tasksByPatient;
  };

  calcTaskMoney(patient: PatientModel, task: TaskModel): number {
    if (!isPrivatePatient(patient) || !this.isTaskToComplete(task)) return 0;

    if (task.type === TaskTypes.REPORT) return DUE_REPORT.amount;

    if (task.type === TaskTypes.INSPECTION) return EVALUATION_INSPECTION.amount;

    return 0;
  }

  calcMoneyToTake = (patients: PatientModel[]): void => {
    const activePatients = PatientsUtils.getActive(patients);

    this.moneyToTake = activePatients.reduce(
      (acc, patient: PatientModel) => {
        if (!patient.id) return acc;

        const tasks = this.byPatient[patient.id]?.all || [];

        tasks.forEach((task: TaskModel) => {
          acc[task.type] += this.calcTaskMoney(patient, task);
        });

        return acc;
      },
      { REPORT: 0, INSPECTION: 0 },
    );
  };

  setByPatients = (patients: PatientModel[]): void => {
    const _raw: TaskModel[] = patients
      .filter(PatientsUtils.activeFilter)
      .reduce((acc: TaskModel[], patient: PatientModel) => {
        const tasks = patient.tasks || [];

        return [...acc, ...tasks];
      }, []);
    this.setRaw(_raw);

    const byPatient = this.createPatientGroups(_raw);
    this.setByPatient(byPatient);

    this.tasksToComplete = _raw.filter(this.isTaskToComplete);

    this.calcMoneyToTake(patients);
  };

  hasTasksForDashboard = (patient: PatientModel): boolean => {
    if (!patient.id) return false;

    const tasks: PatientTasks = this.byPatient[patient.id];

    if (!tasks) return false;

    // return tasks.byType.toComplete.REPORT.length > 0 || tasks.byType.toComplete.INSPECTION.length > 0;
    return tasks.byType.toComplete.REPORT.length > 0;
  };

  constructor() {
    makeObservable(this, {
      raw: observable,
      byPatient: observable,
      tasksToComplete: observable,
      moneyToTake: observable,
      hasTasksForDashboard: action,
      setByPatients: action,
    });
  }
}

const store = new MedicTaskStore();
export { store as MedicTaskStore };
