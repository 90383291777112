import { useContext } from 'react';

import { PatientStateContextType } from 'context/patients/patientContext/PatientContext.types';
import { PatientStateContext } from 'context/patients/patientContext/PatientContext';

export const usePatientState: () => PatientStateContextType = () => {
  const context = useContext(PatientStateContext);

  if (context === undefined) {
    throw new Error('usePatientState must be used within an PatientContextController');
  }

  return context;
};
