import React from 'react';
import clsx from 'classnames';

import { CircleContainer, Circle } from './styled';

const sizeEnum = {
  0: 'w-0 h-0',
  1: 'w-1/3 h-1/3',
  2: 'w-2/4 h-2/4',
};

const CommitmentCircle = ({ count = 0 }: { count?: number }) => {
  const maxCount: number = count > 2 ? 2 : count;
  const className: string = clsx(sizeEnum[maxCount]);

  return (
    <CircleContainer className="w-10 h-10 flex">
      <Circle className={className}></Circle>
    </CircleContainer>
  );
};

export { CommitmentCircle };
