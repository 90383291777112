import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthState } from 'hooks';
import { Loader } from 'components';
import { UserType } from 'models/user';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRouteProps } from './AuthorizedRoute.types';

export const AuthorizedRoute = (props: AuthorizedRouteProps) => {
  const { isAuthorized, user, role } = useAuthState();
  const { permissions } = props;

  if (isAuthorized && user && role && permissions?.includes(role)) {
    return <Route {...props} />;
  } else if (isAuthorized && user && role && !permissions?.includes(role) && role === UserType.Medic) {
    return <Redirect to={AppRoute.patients} />;
  } else if (isAuthorized && user && role && !permissions?.includes(role) && role === UserType.Admin) {
    return <Redirect to={AppRoute.medics} />;
  } else if (isAuthorized && (!user || !role)) {
    return <Route {...props} component={Loader} />;
  }

  return <Redirect to={AppRoute.login} />;
};
