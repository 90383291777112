import { configureAxios } from 'api/http.helper';
import { UserType } from 'models/user';

const ACCESS_TOKEN_KEY = 'accessToken';
const GHOST_LOGIN_KEY = 'ghostLogin';
const ROLE_KEY = 'role';
const Is_MEMBER = 'member';
const Is_OWNER = 'owner';

class AuthStorage {
  private _accessToken: string | undefined = undefined;
  private _ghostLogin: boolean | undefined = undefined;
  private _role: UserType | undefined = undefined;
  private _isMember: boolean | undefined = undefined;
  private _isOwner: boolean | undefined = undefined;

  constructor() {
    try {
      this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || undefined;
      this.ghostLogin =
        Boolean(localStorage.getItem(GHOST_LOGIN_KEY) && localStorage.getItem(GHOST_LOGIN_KEY) === 'true') || false;
      this.member = localStorage.getItem(Is_MEMBER) === 'true';
      this.owner = localStorage.getItem(Is_OWNER) === 'true';
      const roleString = localStorage.getItem(ROLE_KEY);
      this.role = !!roleString ? (parseInt(roleString) as UserType) : undefined;
    } catch (error) {
      this.accessToken = undefined;
      this._ghostLogin = undefined;
      this.role = undefined;
      this.member = undefined;
      this.owner = undefined;
    }
  }

  get accessToken(): string | undefined {
    return this._accessToken;
  }

  set accessToken(value: string | undefined) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        localStorage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
      configureAxios();
    } catch (error) {}
  }

  get ghostLogin(): boolean | undefined {
    return this._ghostLogin;
  }

  set ghostLogin(value: boolean | undefined) {
    this._ghostLogin = value;

    try {
      if (typeof value === 'boolean') {
        localStorage.setItem(GHOST_LOGIN_KEY, value);
      } else {
        localStorage.removeItem(GHOST_LOGIN_KEY);
      }
    } catch (error) {}
  }

  get role(): UserType | undefined {
    return this._role;
  }

  set role(value: UserType | undefined) {
    this._role = value;

    try {
      if (!!value) {
        localStorage.setItem(ROLE_KEY, `${value}`);
      } else {
        localStorage.removeItem(ROLE_KEY);
      }
    } catch (error) {}
  }

  get owner(): boolean | undefined {
    return this._isOwner;
  }

  set owner(value: boolean | undefined) {
    this._isOwner = value;

    try {
      if (!!value) {
        localStorage.setItem(Is_OWNER, `${value}`);
      } else {
        localStorage.removeItem(Is_OWNER);
      }
    } catch (error) {}
  }

  get member(): boolean | undefined {
    return this._isMember;
  }

  set member(value: boolean | undefined) {
    this._isMember = value;

    try {
      if (!!value) {
        localStorage.setItem(Is_MEMBER, `${value}`);
      } else {
        localStorage.removeItem(Is_MEMBER);
      }
    } catch (error) {}
  }
}

export const authStorage = new AuthStorage();
