import styled from 'styled-components';

export const BoxShadow = styled.div`
  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
  }
`;

export const PatientRemark = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 300px;
`;

export const SearchInput = styled.input`
:focus {
    border-color: #0a5b6f;
    outline: 0;
    outline:none
    -webkit-appearance: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
`;
