import styled from 'styled-components';

export const Card = styled.div<{ fromReport: boolean }>`
  border: 1.5px solid #f8f8f8;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  ${({ fromReport }) => fromReport && 'width: 15%;'}

  &.bp-card {
    .bp-card-title {
      max-width: 15ch;
    }
  }
`;
