import {
  SET_PATIENTS,
  SET_OVERALL_INFO,
  SET_LOADING,
  SET_RECENT_INSERTED_PATIENT,
  SET_SELECTED_PATIENT,
  SET_PATIENT_ACTIVITIES,
  SET_BILL_FORM_DATE,
  SET_PATIENT_IN_BETWEEN_ACTIVITIES,
  SET_PATIENT_BODYWEIGHT_OUT_OF_RANGE,
  SET_SELECTED_DATE_NOTE,
} from '../patientReducer/patientReducer';
import { PatientAction } from '../patientReducer/patientReducer.types';
import { PatientModel } from '../../../models/patient';
import { OverallInfoType } from '../../../models/overallInfo';
import { BillingReportModel } from 'models/billingReport';
import { FormDateModel } from 'models/formDate';
import { ActivityModel } from 'models/activity';

export const setPatients: (patients: PatientModel[]) => PatientAction = (patients) => ({
  type: SET_PATIENTS,
  patients,
});

export const setOverallInfo: (overallInfo: OverallInfoType) => PatientAction = (overallInfo) => ({
  type: SET_OVERALL_INFO,
  overallInfo,
});

export const setLoading: (loading: boolean) => PatientAction = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setRecentlyInsertedPatient: (patients: PatientModel) => PatientAction = (patient) => ({
  type: SET_RECENT_INSERTED_PATIENT,
  patient,
});

export const setSelectedPatientProfile: (selectedPatient: PatientModel | null) => PatientAction = (selectedPatient) => {
  return {
    type: SET_SELECTED_PATIENT,
    selectedPatient,
  };
};

export const setPatientActivities: (activities: ActivityModel[]) => PatientAction = (activities) => ({
  type: SET_PATIENT_ACTIVITIES,
  activities,
});

export const setBillFormDate: (formDate: FormDateModel) => PatientAction = (formDate) => ({
  type: SET_BILL_FORM_DATE,
  formDate,
});

export const setPatientInBetweenActivities: (activitiesBetweenData: BillingReportModel[]) => PatientAction = (
  activitiesBetweenData,
) => ({
  type: SET_PATIENT_IN_BETWEEN_ACTIVITIES,
  activitiesBetweenData,
});

export const setPatientBwOutOfRange: (bwOutOfRange: boolean) => PatientAction = (bwOutOfRange) => ({
  type: SET_PATIENT_BODYWEIGHT_OUT_OF_RANGE,
  bwOutOfRange,
});

export const setSelectedDateNoteAction: (selectedDateNote: string | null) => PatientAction = (selectedDateNote) => ({
  type: SET_SELECTED_DATE_NOTE,
  selectedDateNote,
});
