import { DeviceListStateContextType } from '../deviceListContext/deviceListContext.types';

import { DeviceListAction } from './deviceListReducer.types';

export const SET_LOADING = 'set-loading';
export const SET_DEVICES = 'set-devices';

export const deviceListReducer: (
  state: DeviceListStateContextType,
  action: DeviceListAction,
) => DeviceListStateContextType = (state, action) => {
  const behaviours: Record<
    string,
    (state: DeviceListStateContextType, action: DeviceListAction) => DeviceListStateContextType
  > = {
    [SET_LOADING]: (state, { loading }) => ({
      ...state,
      loading,
    }),
    [SET_DEVICES]: (state, { devices }) => ({
      ...state,
      devices,
    }),
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
