import { useContext } from 'react';

import { MedicStateContextType } from 'context/medics/medicContext/MedicContext.types';
import { MedicStateContext } from 'context/medics/medicContext/MedicContext';

export const useMedicsState: () => MedicStateContextType = () => {
  const context = useContext(MedicStateContext);

  if (context === undefined) {
    throw new Error('useMedicsState must be used within an MedicContextController');
  }

  return context;
};
