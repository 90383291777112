import styled from 'styled-components';

import { Color } from 'utils/colors.enum';

export const SharedStyle = styled.span<{ hoverColor: string }>`
  ${({ hoverColor }) =>
    hoverColor &&
    `
    &: hover {
      color: ${Color[hoverColor]};
    }`}
`;

export const AppStyled = styled.div`
  input:focus {
    border-color: #095c70;
    --tw-ring-color: #095c70;
  }
  textarea:focus {
    border-color: #095c70;
    --tw-ring-color: #095c70;
  }
`;

export const CheckBoxStyled = styled.div`
  /*
  input[type='checkbox']:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  */
`;
