export enum FontSize {
  base = 'base', // Font size 12px
  sm = 'sm', // Font size 14px
  lg = 'lg', // Font size 16px
  xl = 'xl', // Font size 22px
  xxxl = '3xl', // Font size 40px
}

export enum FontWeight {
  Normal = 'normal', // Font weight 400
  Medium = 'medium', // Font weight 500
  Bold = 'bold', // Font weight 700
}
