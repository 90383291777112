import { DateTime } from 'luxon';

import AbstractMultiValueFilterStore from '../Abstract/AbstractMultiValueFilterStore';
import { BpChartPoint } from 'models/bp';

import {
  FilterRange,
  RangeToFilter,
  TimeObject,
  TimeRangeIntervalSpec,
  TimeSlotsFiltervalues,
  TimeRanges,
} from './TimeSlotsFilterTypes';

class TimeSlotsFilterStore extends AbstractMultiValueFilterStore {
  parseTimeString(time: string): TimeObject {
    const times = time.split(':');

    return {
      hour: parseInt(times[0], 10),
      minute: parseInt(times[1], 10),
      second: parseInt(times[2], 10),
    };
  }
  isDateBetween(item: Date, from: Date, to: Date): boolean {
    return item >= from && item <= to;
  }

  getUnitFilterRange(itemDate: Date, range: RangeToFilter): FilterRange {
    const from: Date = DateTime.fromJSDate(itemDate)
      .set({
        hour: range.from.hour,
        minute: range.from.minute,
        second: range.from.second,
      })
      .toJSDate();
    const to: Date = DateTime.fromJSDate(itemDate)
      .set({
        hour: range.to.hour,
        minute: range.to.minute,
        second: range.to.second,
      })
      .toJSDate();

    return { from, to };
  }
  filterByRanges(data: BpChartPoint[], ranges: RangeToFilter[]): BpChartPoint[] {
    const filtered: BpChartPoint[] = data?.filter((item: BpChartPoint) => {
      const itemDate: Date = item.jsDate || new Date(item.dateTimeInfo);
      const atLeastOneRangeIsValid = ranges.some((range: RangeToFilter) => {
        const filter: FilterRange = this.getUnitFilterRange(itemDate, range);

        const isBetween: boolean = this.isDateBetween(itemDate, filter.from, filter.to);

        // if (!isBetween) console.log('itemDate', itemDate, 'filter from', filter.from, 'filter to', filter.to);

        return isBetween;
      });

      return atLeastOneRangeIsValid;
    });

    return filtered;
  }
  getCurrentTimeRanges(): TimeRangeIntervalSpec[] {
    const currentSlot = this.currentSlot;
    const slotTimeFilter: TimeRangeIntervalSpec[] = [];

    currentSlot?.forEach((i) => {
      slotTimeFilter.push(this.getTimeRangeIntervalSpec(i));
    });

    return slotTimeFilter;
  }
  getRangesToFilter(timeRanges: TimeRangeIntervalSpec[]): RangeToFilter[] {
    // timeRanges : [{from: "04:00:00", to: "11:00:00"}, {from: "11:00:00", to: "20:00:00"}, {from: "20:00:00", to: "04:00:00"}]

    if (timeRanges.length === 0) return [];

    const rangeToFilter: RangeToFilter[] = timeRanges
      .map((range: TimeRangeIntervalSpec) => {
        const from: TimeObject = this.parseTimeString(range.from);
        const to: TimeObject = this.parseTimeString(range.to);

        if (from.hour <= to.hour) {
          return [
            {
              from,
              to,
            },
          ];
        }

        // edge case. for example if from is 20:00:00 and to is 04:00:00

        return [
          {
            from,
            to: {
              hour: 24,
              minute: 0,
              second: 0,
            },
          },
          {
            from: {
              hour: 0,
              minute: 0,
              second: 0,
            },
            to: {
              hour: to.hour,
              minute: to.minute,
              second: to.second,
            },
          },
        ];
      })
      .reduce((acc: RangeToFilter[], curr: RangeToFilter[]) => {
        return acc.concat(curr);
      });

    return rangeToFilter;
  }
  getTimeRangeFilteredData(data: BpChartPoint[]): BpChartPoint[] {
    const getCurrentTimeRanges: TimeRangeIntervalSpec[] = this.getCurrentTimeRanges();
    const rangeToFilter: RangeToFilter[] = this.getRangesToFilter(getCurrentTimeRanges); // [{from: "04:00:00", to: "11:00:00", daysToAddFrom: 0}, {from: "11:00:00", to: "20:00:00", daysToAddFrom: 0}, {from: "20:00:00", to: "04:00:00", daysToAddFrom: 1}]

    if (rangeToFilter.length === 0 && this.noneIsAll) return data;

    return this.filterByRanges(data, rangeToFilter);
  }
  filterByIntervalSpec(data: BpChartPoint[], timeInterval: TimeSlotsFiltervalues): BpChartPoint[] {
    const range: TimeRangeIntervalSpec = this.getTimeRangeIntervalSpec(timeInterval);
    const rangeToFilter: RangeToFilter[] = this.getRangesToFilter([range]);

    const filtered = this.filterByRanges(data, rangeToFilter);

    return filtered;
  }
  getTimeRangeIntervalSpec(timeInterval: TimeSlotsFiltervalues): TimeRangeIntervalSpec {
    return TimeRanges[timeInterval];
  }
}

const store = new TimeSlotsFilterStore(
  [TimeSlotsFiltervalues.morning, TimeSlotsFiltervalues.day, TimeSlotsFiltervalues.night],
  [TimeSlotsFiltervalues.morning, TimeSlotsFiltervalues.day, TimeSlotsFiltervalues.night],
  false,
);

export { store as TimeSlotsFilterStore };
