import Axios from 'axios';
import { configure } from 'axios-hooks';

import config from 'config';

export const getConfig = () => {
  const token = localStorage.getItem('accessToken');

  return {
    'x-access-token': token,
  };
};

export const configureAxios = () => {
  const axios = Axios.create({
    baseURL: config.apiUrl,
    headers: getConfig(),
  });

  configure({ axios });
};
