import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuthState, useLocale } from 'hooks';
import { phoneRegExp } from 'utils/constants.regex';
import { insuredTypeOptions } from 'api/mocks/insuredType';
import { Features } from 'models/medic';

import { AddPatientBody, DeliveryOptions } from './AddPatientModal.types';
import { deliveryOptions } from './AddPatientModal';

type SelectInputSelectDelivery = { label: string; value: DeliveryOptions };

const isDeviceDeliveryOnPraxis = (selectedDelivery: SelectInputSelectDelivery) =>
  selectedDelivery && Number(selectedDelivery.value) === DeliveryOptions.PRAXIS;

const isDeliveryDetailsRequired = (
  selectedDelivery: SelectInputSelectDelivery,
  sendDeviceToPatient: boolean,
  send24HDiagnosis: boolean,
) => {
  if (send24HDiagnosis) return true;

  if (sendDeviceToPatient) {
    if (selectedDelivery && Number(selectedDelivery.value) === DeliveryOptions.PINZON) return true;
  }

  return false;
};

const AddPatientFormikWrapper = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit: (values: AddPatientBody) => void;
}) => {
  const { formatMessage } = useLocale();
  const { profile } = useAuthState();

  const has24hDiagnosisFeature = !!profile?.hasFeature(Features.DIAGNOSIS_24);
  const hasAssignedDoctorAndNurseFeature = profile?.hasFeature(Features.ASSIGNED_DOCTOR_AND_NURSE);
  const hasAppInviteEnrollmentFeature = !profile?.hasFeature(Features.DISABLE_SEND_APP_INVITE_ENROLLMENT);

  const hasPINZONDeliveryOption = !profile?.hasFeature(Features.DISABLE_PINZON_DELIVERY);
  const hasManualBPDeliveryOption = !profile?.hasFeature(Features.DISABLE_MANUAL_BP);

  const isByPracticeDefaulDeliveryOption = !hasPINZONDeliveryOption && !hasManualBPDeliveryOption;

  const initSelectedDelivery = isByPracticeDefaulDeliveryOption
    ? deliveryOptions(formatMessage, {
        hasPINZONDeliveryOption,
        hasManualBPDeliveryOption,
      })[0]
    : null;

  let EnrollSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    lastName: Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required(formatMessage({ id: 'common.required' })),
    email: Yup.string()
      .test(
        'empty-or-email',
        formatMessage({ id: 'common.required' }),
        (value) => !value || Yup.string().email().isValidSync(value),
      )
      .when(['sendAppInvite', 'getReportAutopilot'], {
        is: (sendAppInvite: boolean, getReportAutopilot: boolean) => sendAppInvite || getReportAutopilot,
        then: Yup.string().required(formatMessage({ id: 'common.required' })),
      }),
    birthday: Yup.string().required(formatMessage({ id: 'common.required' })),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, formatMessage({ id: 'common.invalid_format' }))
      .when(['smsReminder'], {
        is: (smsReminder: boolean) => smsReminder,
        then: Yup.string()
          .matches(phoneRegExp, formatMessage({ id: 'common.invalid_format' }))
          .required(formatMessage({ id: 'common.required' })),
      }),
    send24HDiagnosis: Yup.boolean(),
    sendDeviceToPatient: Yup.boolean(),
    street: Yup.string().when(['selectedDelivery', 'sendDeviceToPatient', 'send24HDiagnosis'], {
      is: isDeliveryDetailsRequired,
      then: Yup.string()
        .min(1, 'Too Short!')
        .max(150, 'Too Long!')
        .required(formatMessage({ id: 'common.required' })),
    }),
    city: Yup.string().when(['selectedDelivery', 'sendDeviceToPatient', 'send24HDiagnosis'], {
      is: isDeliveryDetailsRequired,
      then: Yup.string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long!')
        .required(formatMessage({ id: 'common.required' })),
    }),
    streetNumber: Yup.string().when(['selectedDelivery', 'sendDeviceToPatient', 'send24HDiagnosis'], {
      is: isDeliveryDetailsRequired,
      then: Yup.string()
        .min(1, 'Too Short!')
        .max(25, 'Too Long!')
        .required(formatMessage({ id: 'common.required' })),
    }),
    postalCode: Yup.string().when(['selectedDelivery', 'sendDeviceToPatient', 'send24HDiagnosis'], {
      is: isDeliveryDetailsRequired,
      then: Yup.string()
        .min(1, 'Too Short!')
        .max(25, 'Too Long!')
        .required(formatMessage({ id: 'common.required' })),
    }),
    insuredType: Yup.object().shape({
      label: Yup.string().required(formatMessage({ id: 'common.required' })),
      value: Yup.string().required(formatMessage({ id: 'common.required' })),
    }),
    termsAndConditionsConfirmation: Yup.boolean().oneOf([true], formatMessage({ id: 'common.required' })),
    sendAppInvite: Yup.boolean().oneOf([true, false], formatMessage({ id: 'common.required' })),
    height: Yup.number().when(['send24HDiagnosis'], {
      is: (send24HDiagnosis: boolean) => send24HDiagnosis,
      then: Yup.number()
        .min(120)
        .max(230)
        .required(formatMessage({ id: 'common.required' })),
    }),
    weight: Yup.number().when(['send24HDiagnosis'], {
      is: (send24HDiagnosis: boolean) => send24HDiagnosis,
      then: Yup.number()
        .min(30)
        .max(250)
        .required(formatMessage({ id: 'common.required' })),
    }),
    selectedDelivery: Yup.object()
      .shape({
        label: Yup.string().required(formatMessage({ id: 'common.required' })),
        value: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
      .when(['sendDeviceToPatient'], {
        is: (sendDeviceToPatient: SelectInputSelectDelivery) => sendDeviceToPatient,
        then: Yup.object().required(),
      })
      .nullable(),
    devices: Yup.object()
      .shape({
        label: Yup.string().required(formatMessage({ id: 'common.required' })),
        value: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
      .when(['selectedDelivery', 'sendDeviceToPatient'], {
        is: (selectedDelivery: SelectInputSelectDelivery, sendDeviceToPatient: boolean) =>
          sendDeviceToPatient ? isDeviceDeliveryOnPraxis(selectedDelivery) : false,
        then: Yup.object().required().nullable(),
      })
      .nullable(),
    deliveryDateDiagnosis: Yup.string()
      .when(['hasDeliveryDateDiagnosis'], {
        is: (hasDeliveryDateDiagnosis: boolean) => hasDeliveryDateDiagnosis,
        then: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
      .nullable(),
    deliveryDateTelemetrie: Yup.string()
      .when(['hasDeliveryDateTelemetrie'], {
        is: (hasDeliveryDateTelemetrie: boolean) => hasDeliveryDateTelemetrie,
        then: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
      .nullable(),
  });

  if (hasAssignedDoctorAndNurseFeature) {
    EnrollSchema = EnrollSchema.shape({
      assignedDoctor: Yup.object({
        label: Yup.string().required(formatMessage({ id: 'common.required' })),
        value: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
        .required()
        .nullable(),
      assignedNurse: Yup.object({
        label: Yup.string().required(formatMessage({ id: 'common.required' })),
        value: Yup.string().required(formatMessage({ id: 'common.required' })),
      })
        .required()
        .nullable(),
    });
  }

  const initialValues: AddPatientBody = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    patientIdPVS: '',
    remarks: '',
    devices: null,
    countryCode: '',
    insuredType: insuredTypeOptions(formatMessage)[0],
    sendDeviceToPatient: has24hDiagnosisFeature ? false : true,
    send24HDiagnosis: false,
    street: '',
    city: '',
    streetNumber: '',
    postalCode: '',
    company: '',
    termsAndConditionsConfirmation: false,
    sendAppInvite: hasAppInviteEnrollmentFeature ? true : false,
    selectedDelivery: initSelectedDelivery,
    weight: '',
    height: '',
    gkvFlatrate: false,
    diseaseManagementPrograms: [],
    hasDeliveryDateDiagnosis: false,
    deliveryDateDiagnosis: null,
    hasDeliveryDateTelemetrie: false,
    deliveryDateTelemetrie: null,
    smsReminder: true,
    getReportAutopilot: true,
    assignedDoctor: null,
    assignedNurse: null,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={EnrollSchema}>
      {(formikProps) => (typeof children === 'function' ? children(formikProps) : children)}
    </Formik>
  );
};

export default AddPatientFormikWrapper;
