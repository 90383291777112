export const insuredTypeOptions = (formatMessage) => {
  return [
    {
      value: 'Privately insured',
      label: formatMessage({ id: 'insured_type.option.privately_insured' }),
    },
    {
      value: 'Legally insured',
      label: formatMessage({ id: 'insured_type.option.legally_insured' }),
    },
    {
      value: 'Self payer',
      label: formatMessage({ id: 'insured_type.option.self_payer' }),
    },
  ];
};
