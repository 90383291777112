import React from 'react';

const Report = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0166 1.78533L10.6803 1.86799C12.2122 2.05878 13.3621 3.36076 13.3621 4.9045V13.4638C13.3621 14.9627 12.2456 16.2268 10.7582 16.4121C8.28772 16.7197 5.78861 16.7197 3.31812 16.4121C1.83072 16.2268 0.714233 14.9627 0.714233 13.4638V4.90449C0.714233 3.36076 1.86412 2.05878 3.39603 1.86799L4.05971 1.78533C4.32261 0.838092 5.1912 0.142822 6.22217 0.142822H7.85415C8.88513 0.142822 9.75371 0.838092 10.0166 1.78533ZM3.9782 3.02893L3.5473 3.08259C2.62815 3.19706 1.93822 3.97825 1.93822 4.90449V13.4638C1.93822 14.3452 2.59475 15.0885 3.46939 15.1975C5.83942 15.4926 8.23691 15.4926 10.6069 15.1975C11.4816 15.0885 12.1381 14.3452 12.1381 13.4638V4.9045C12.1381 3.97825 11.4482 3.19706 10.529 3.08259L10.0981 3.02893V4.01878C10.0981 4.35677 9.82413 4.63077 9.48613 4.63077H4.59019C4.25219 4.63077 3.9782 4.35677 3.9782 4.01878V3.02893ZM5.20218 2.3868C5.20218 1.82347 5.65885 1.36681 6.22217 1.36681H7.85415C8.41748 1.36681 8.87414 1.82347 8.87414 2.3868V3.40679H5.20218V2.3868Z"
      fill="currentColor"
    />
  </svg>
);

export default Report;
