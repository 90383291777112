import { SET_DEVICE_PATIENT_ASSIGNMENTS, SET_LOADING, SET_DEVICES } from '../deviceReducer/deviceReducer';
import { DeviceAction } from '../deviceReducer/deviceReducer.types';
import { DeviceModel, DevicePatientModel } from '../../../models/device';

export const setDeviceAssignments: (deviceAssignment: DevicePatientModel) => DeviceAction = (deviceAssignment) => ({
  type: SET_DEVICE_PATIENT_ASSIGNMENTS,
  deviceAssignment,
});

export const setLoading: (loading: boolean) => DeviceAction = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setDevices: (devices: DeviceModel[]) => DeviceAction = (devices) => ({
  type: SET_DEVICES,
  devices,
});
