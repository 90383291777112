import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { UserController } from 'context/auth/userController/UserController';
import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { SnackbarContextController } from 'context/snackbar/snackbarContextController/SnackbarContextController';
import { ModalContextController } from 'context/modal/modalContextController/ModalContextController';
import { PatientContextController } from 'context/patients/patientContextController/PatientContextController';
import DeviceContextController from 'context/devices/deviceContextController/deviceContextController';
import { EmailContextController } from 'context/email/emailContextController/EmailContextController';
import { MedicContextController } from 'context/medics/medictContextController/MedicContextController';
import { DevicesListContextController } from 'context/deviceList/deviceListContextController/deviceListContextController';
import { WeeklyReportContextController } from 'context/weeklyReport/weeklyReportContextController/WeeklyReportContextController';
import { MembersContextController } from 'context/members/membersContextController/MembersContextController';

import { AppProvidersProps as AppProvidersProperties } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProperties) => (
  <AuthContextController>
    <MembersContextController>
      <LocaleContextController>
        <SnackbarContextController>
          <UserController>
            <ModalContextController>
              <PatientContextController>
                <DeviceContextController>
                  <EmailContextController>
                    <MedicContextController>
                      <DevicesListContextController>
                        <WeeklyReportContextController>
                          <Router>
                            <QueryParamProvider ReactRouterRoute={Route}>{children}</QueryParamProvider>
                          </Router>
                        </WeeklyReportContextController>
                      </DevicesListContextController>
                    </MedicContextController>
                  </EmailContextController>
                </DeviceContextController>
              </PatientContextController>
            </ModalContextController>
          </UserController>
        </SnackbarContextController>
      </LocaleContextController>
    </MembersContextController>
  </AuthContextController>
);
