import { EmailStateContextType } from '../emailContext/EmailContext.types';

import { EmailAction } from './emailReducer.types';

export const SET_EMAIL_STATE = 'set-email-state';

export const emailReducer: (state: EmailStateContextType, action: EmailAction) => EmailStateContextType = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: EmailStateContextType, action: EmailAction) => EmailStateContextType> = {
    [SET_EMAIL_STATE]: (state, { emailState }) => {
      return {
        ...state,
        emailState,
      };
    },
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
