import React from 'react';

import { ButtonVariant } from 'components/Button/Button.types';
import { Color } from 'utils/colors.enum';

const BtnLoader = ({ variant }: { variant?: ButtonVariant }) => (
  <svg
    className="animate-spin"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 2.33333C9.01849 2.33333 7.40732 2.82207 6.03691 3.73775C4.6665 4.65343 3.5984 5.95492 2.96767 7.47764C2.33694 9.00035 2.17191 10.6759 2.49345 12.2924C2.815 13.9089 3.60867 15.3938 4.77411 16.5592C5.93955 17.7247 7.4244 18.5183 9.04091 18.8399C10.6574 19.1614 12.333 18.9964 13.8557 18.3657C15.3784 17.7349 16.6799 16.6668 17.5956 15.2964C18.5113 13.926 19 12.3148 19 10.6667"
      stroke={variant === 'secondary' ? Color.cyan : '#FCFCFC'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BtnLoader;
