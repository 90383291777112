import React from 'react';
import { DateTime } from 'luxon';

import { BpChartPoint } from '../../../models/bp';
import { isBetweenWithTimeSlot } from 'utils/helper';
import { getArrayOfDatesBetween } from 'utils/date';
import { TaskModel } from 'models/task';

import { CommitmentCircle } from './CommitmentCircle';
import { CommitmentDayNight } from './CommitmentDayNight';
import { CommitmentChartDate } from './CommitmentChartDate';
import { UnitsContainer, CommitmentUnit } from './styled';
import { CommitmentYesNoCircle } from './CommitmentYesNoCircle';

type CountPoint = {
  date: string;
  count: number;
  morning: number;
  night: number;
};
type CountPointHash = {
  [x: string]: CountPoint;
};
type TimeSlot = {
  minHour: number;
  maxHour: number;
};
type timeSlots = {
  morning: TimeSlot;
  night: TimeSlot;
};
const idtmTimeSlots: timeSlots = {
  morning: {
    minHour: 4,
    maxHour: 11,
  },
  night: {
    minHour: 20,
    maxHour: 4,
  },
};

const objectToArray = (obj: CountPointHash): CountPoint[] => Object.keys(obj).map((key) => obj[key]);

const toYYYYMMDD = (date: Date): string => {
  const d = DateTime.fromJSDate(date);

  return d.toFormat('yyyy-MM-dd');
};

const chartDaysReducer = (acc: CountPointHash, curr: Date) => {
  const formattedDate = toYYYYMMDD(curr);

  acc[formattedDate] = {
    date: formattedDate,
    count: 0,
    morning: 0,
    night: 0,
  };

  return acc;
};

const countGroups = (groups: CountPointHash, points: BpChartPoint[], field: string): CountPointHash => {
  const morningMinHour = idtmTimeSlots.morning.minHour;
  const morningMaxHour = idtmTimeSlots.morning.maxHour - 1; // e.g 6 is until 5:59:59
  const nightMinHour = idtmTimeSlots.night.minHour;
  const nightMaxHour = idtmTimeSlots.night.maxHour - 1; // e.g 23 is until 22:59:59

  points?.forEach((point) => {
    // this might fail if the point is not in the groups
    // it is due to the fact that startDate and endDate are not extracted from chartPoints
    // but gotten as parameters. It might cause a reactivity issue that is automatically where these two dates are updated
    // the reason for not extracting them from chartPoints is that it is to have the complete date range of the task
    try {
      const date: string = point[field];

      if (isBetweenWithTimeSlot(point.dateTimeInfo, nightMinHour, nightMaxHour)) {
        groups[date].night += 1;
      } else if (isBetweenWithTimeSlot(point.dateTimeInfo, morningMinHour, morningMaxHour)) {
        groups[date].morning += 1;
      }

      if (groups[date]) groups[date].count += 1;
    } catch (e) {}
  });

  return groups;
};

const getStartDateToUse = (points: BpChartPoint[], startDate: Date | null): Date => {
  const firstPoint: BpChartPoint = points[0];

  if (startDate && startDate < firstPoint.jsDate) {
    return startDate;
  } else {
    const lastDateString: string = firstPoint.date;

    return DateTime.fromISO(lastDateString).toJSDate();
  }
};

const unitsComponents = {
  circle: (point: CountPoint): JSX.Element => <CommitmentCircle count={point.count} />,
  dayNight: (point: CountPoint): JSX.Element => (
    <CommitmentDayNight morning={Boolean(point.morning)} night={Boolean(point.night)} />
  ),
};

export const BpCommitmentChart = ({
  id = 'bp-commitment-chart',
  chartPoints,
  unitType = 'circle',
  direction = 'rtl',
  startDate = null,
  endDate = new Date(),
}: {
  id: string;
  chartPoints: BpChartPoint[] | undefined;
  unitType: string;
  direction: string;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  if (!chartPoints || chartPoints.length === 0) {
    return null;
  }
  const startDateToUse = getStartDateToUse(chartPoints, startDate);
  const endDateToUse = endDate || new Date();

  const chartDays = getArrayOfDatesBetween(startDateToUse, endDateToUse);
  const basicGroups: CountPointHash = chartDays.reduce(chartDaysReducer, {});
  const countedGroups: CountPointHash = countGroups(basicGroups, chartPoints, 'date');
  const chartData: CountPoint[] = objectToArray(countedGroups);
  const unit: (point: CountPoint) => JSX.Element = unitsComponents[unitType];
  const flexClass = direction === 'rtl' ? 'flex-row-reverse' : 'flex-row';

  return (
    <>
      <UnitsContainer id={id} className={`flex ${flexClass} overflow-x-scroll overflow-y-hidden items-center`}>
        {chartData.map((point, index) => (
          <CommitmentUnit key={index} className="flex flex-col content-between">
            {unit(point)}
            <CommitmentChartDate date={point.date}></CommitmentChartDate>
          </CommitmentUnit>
        ))}
      </UnitsContainer>
    </>
  );
};

const TaskStatus: { COMPLETED: number; CREATED: number } = {
  COMPLETED: 2,
  CREATED: 0,
};

export const TaskCommitmentChart = ({
  id = 'task-commitment-chart',
  data,
  direction = 'rtl',
  maxItems,
}: {
  id: string;
  data: TaskModel[];
  direction: string;
  maxItems?: number;
}) => {
  const flexClass = direction === 'rtl' ? 'flex-row-reverse' : 'flex-row';
  const maxItemsToShow = maxItems ? maxItems : data.length;

  return (
    <>
      <UnitsContainer id={id} className={`flex ${flexClass} overflow-x-scroll overflow-y-hidden mt-3 mb-3`}>
        {data?.slice(0, maxItemsToShow).map((item) => (
          <CommitmentUnit className="flex flex-col content-between mr-5" key={item.id}>
            <CommitmentYesNoCircle count={TaskStatus[item.status]} />
            <CommitmentChartDate type="task" date={item.start_date} endDate={item.end_date}></CommitmentChartDate>
          </CommitmentUnit>
        ))}
      </UnitsContainer>
    </>
  );
};
