import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import clsx from 'classnames';

import { TextToggleProps } from './TextToggle.types';
import styles from './TextToggle.module.scss';

export const TextToggle = ({ options = [], onChange, selected }: TextToggleProps) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(selected);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  useEffect(() => {
    if (selectedOption && onChange) {
      onChange(selectedOption);
    }
  }, [onChange, selectedOption]);

  return (
    <div className={clsx('rounded-md border-2 border-gray-border flex', classNames(styles.toggle))}>
      {options.map((option, key) => (
        <React.Fragment key={option.id}>
          {option.id === selectedOption ? (
            <SelectedOption
              testId={option.testId}
              font={option.font}
              text={option.name}
              onClick={() => setSelectedOption(option.id)}
            />
          ) : (
            <UnselectedOption test-id={option.testId} text={option.name} onClick={() => setSelectedOption(option.id)} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const SelectedOption = ({
  text,
  onClick,
  testId,
  font,
}: {
  text: string;
  onClick: () => void;
  testId?: string;
  font?: string;
}) => (
  <div
    data-test-id={testId}
    className={`px-4 py-2 rounded-md bg-gray-border text-base text-cyan font-${font ? font : 'bold'} cursor-pointer`}
    onClick={onClick}
  >
    {text}
  </div>
);

const UnselectedOption = ({ text, onClick, testId }: { text: string; onClick: () => void; testId?: string }) => (
  <div data-test-id={testId} className="px-4 py-2 text-base text-gray-light cursor-pointer" onClick={onClick}>
    {text}
  </div>
);
