import { makeObservable, observable, action } from 'mobx';

class ConfettiStore {
  isOn = false;

  toggle = (): void => {
    this.isOn = !this.isOn;
  };
  turnOn = (): void => {
    this.isOn = true;
  };
  turnOnForDuration = ({ durationInMilliseconds = 2000 }: { durationInMilliseconds?: number }): void => {
    this.isOn = true;
    setTimeout(() => {
      this.turnOff();
    }, durationInMilliseconds);
  };
  turnOff = (): void => {
    this.isOn = false;
  };

  constructor() {
    makeObservable(this, {
      isOn: observable,
      toggle: action,
      turnOn: action,
      turnOff: action,
    });
  }
}

export default new ConfettiStore();
