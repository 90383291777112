import { BloodPressureStore } from 'store/PatientStore/BloodPressureStore';
import { MedicationStore } from 'store/PatientStore/MedicationStore';
import { PatientsTasksStore } from 'store/PatientStore/PatientTasksStore';
import { InspectionsCompleteStore } from 'store/PatientStore/TaskCompleteStore/InspectionsCompleteStore';
import { ReportCompleteStore } from 'store/PatientStore/TaskCompleteStore/ReportCompleteStore';
import { MedicTaskStore } from 'store/TaskStore/MedicTaskStore';
import { PatientStateContextType } from '../patientContext/PatientContext.types';

import { PatientAction } from './patientReducer.types';

export const SET_PATIENTS = 'set-patients';
export const SET_OVERALL_INFO = 'set-overall-info';
export const SET_LOADING = 'set-loading';
export const SET_RECENT_INSERTED_PATIENT = 'set-recent-inserted-patient';
export const SET_SELECTED_PATIENT = 'set-selected-patient';
export const SET_PATIENT_ACTIVITIES = 'set-patient-activities';
export const SET_BILL_FORM_DATE = 'set-bill-form-date';
export const SET_PATIENT_IN_BETWEEN_ACTIVITIES = 'set-patient-in-between-activities';
export const SET_PATIENT_BODYWEIGHT_OUT_OF_RANGE = 'set_patient_bodyweight_out_of_range';
export const SET_SELECTED_DATE_NOTE = 'set-selected-date-note';

export const patientReducer: (state: PatientStateContextType, action: PatientAction) => PatientStateContextType = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: PatientStateContextType, action: PatientAction) => PatientStateContextType> =
    {
      [SET_PATIENTS]: (state, { patients }) => {
        if (patients && patients.length > 0) MedicTaskStore.setByPatients(patients);

        return {
          ...state,
          patients,
        };
      },
      [SET_OVERALL_INFO]: (state, { overallInfo }) => ({
        ...state,
        overallInfo,
      }),
      [SET_LOADING]: (state, { loading }) => ({
        ...state,
        loading,
      }),
      [SET_RECENT_INSERTED_PATIENT]: (state, { patient }) => ({
        ...state,
        patient,
      }),
      [SET_SELECTED_PATIENT]: (state, { selectedPatient }) => {
        BloodPressureStore.setRaw(selectedPatient?.bp || []);
        MedicationStore.setMedication(selectedPatient?.medications || []);

        if (selectedPatient && Array.isArray(selectedPatient.tasks)) {
          PatientsTasksStore.setTasks(selectedPatient.tasks);
          InspectionsCompleteStore.switchPatientProfile({ switchIfActive: false, closeWidgetIfEmpty: true });
          selectedPatient.tasks = PatientsTasksStore.filterTasks({ status: ['CREATED'] });
        } else {
          // no tasks for patient. Still need to load the tasks with empty array and fire the switchPatientProfile action
          PatientsTasksStore.setTasks([]);
          InspectionsCompleteStore.switchPatientProfile({ switchIfActive: false, closeWidgetIfEmpty: true });

          if (selectedPatient) selectedPatient.tasks = [];
        }
        ReportCompleteStore.load();

        if (ReportCompleteStore.active)
          ReportCompleteStore.switchPatientProfile({ switchIfActive: false, closeWidgetIfEmpty: true });

        return { ...state, selectedPatient };
      },
      [SET_PATIENT_ACTIVITIES]: (state, { activities }) => ({
        ...state,
        activities,
      }),
      [SET_BILL_FORM_DATE]: (state, { formDate }) => ({
        ...state,
        formDate,
      }),
      [SET_PATIENT_IN_BETWEEN_ACTIVITIES]: (state, { activitiesBetweenData }) => ({
        ...state,
        activitiesBetweenData,
      }),
      [SET_PATIENT_BODYWEIGHT_OUT_OF_RANGE]: (state, { bwOutOfRange }) => ({
        ...state,
        bwOutOfRange,
      }),
      [SET_SELECTED_DATE_NOTE]: (state, { selectedDateNote }) => ({
        ...state,
        selectedDateNote: selectedDateNote ? selectedDateNote : null,
      }),
    };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
