import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { useLocale, useModalDispatch, useMedicsState } from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { AddMedicModal } from './AddMedicModal';
import { AddMedicBody } from './AddMedicModal.types';

export const AddMedicModalContainer = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { close } = useModalDispatch();
  const { open: openSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { getMedics } = useMedicsState();
  const [{ data, error, loading }, addMedic] = useAxios(
    {
      url: '/medics',
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    if (!loading && error) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: 'dashboard.snackbar.enroll_new_medic.error_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.enroll_new_medic.error_description' }),
        autoHideDuration: 5000,
      });
    }

    if (!loading && !error && data) {
      openSnackbar({
        severity: 'success',
        title: formatMessage({ id: 'dashboard.snackbar.enroll_new_medic.success_title' }),
        description: formatMessage({ id: 'dashboard.snackbar.enroll_new_medic.success_description' }),
        autoHideDuration: 5000,
      });
      // This will trigger the device assigments just after the patient is successfully enrolled.

      onClose();

      if (getMedics) {
        getMedics();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data]);

  const onAddNewMedic = async (values: AddMedicBody) => {
    await addMedic({
      data: {
        email: values.email,
        fullname: `${values.firstName} ${values.lastName}`,
        date_of_birth: values.birthday ? DateTime.fromJSDate(values.birthday).toFormat('yyyy-MM-dd') : null,
        clinic: values.clinic,
        phone_no: values.phoneNumber,
        account_type: values.accountType.value,
        sendNotification: values.sendNotification,
      },
    });
  };

  const onClose = () => {
    close('add-medic');
    setCurrentStep(0);
  };

  return (
    <AddMedicModal
      onAddNewMedic={onAddNewMedic}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      onClose={onClose}
    />
  );
};
