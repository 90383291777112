import { makeObservable, action, observable } from 'mobx';

import { filterTaskModel } from 'models/task';
import { TasksUtils } from 'utils/TasksUtils';
import { iterateObjectAndFilterArrays } from 'utils/helper';

class TaskStore {
  filterTaskList: filterTaskModel[] = [];
  filterTaskListWithoutCurrentPeriod: filterTaskModel[] = [];

  setFilterTaskList = (filterTaskList: filterTaskModel[], filterOutCurrentPeriod: boolean): void => {
    this.filterTaskList = filterTaskList;

    if (filterOutCurrentPeriod)
      this.filterTaskList = iterateObjectAndFilterArrays(this.filterTaskList, TasksUtils.isTaskNotInCurrentPeriod);
  };

  constructor() {
    makeObservable(this, {
      setFilterTaskList: action,
      filterTaskList: observable,
      filterTaskListWithoutCurrentPeriod: observable,
    });
  }
}

const store = new TaskStore();
export { store as TaskStore };
