import { Features } from 'models/medic';
import { AuthStateContextType } from '../authContext/AuthContext.types';
import { OnboardingModeStore } from 'store/OnboardingModeStore/OnboardingModeStore';

import { AuthAction } from './authReducer.types';

export const SET_AUTHORIZED = 'auth/set-authorized';
export const SET_UNAUTHORIZED = 'auth/set-unauthorized';
export const SET_TOKENS = 'auth/set-tokens';
export const SET_ROLE = 'auth/set-role';
export const START_AUTHORIZING = 'auth/start-authorizing';
export const LOGOUT = 'auth/logout';
export const SET_IS_MEMBER = 'auth/set-isMember';
export const SET_IS_OWNER = 'auth/set-isOwner';
export const SET_PROFILE = 'auth/set-profile';
export const SET_PROFILE_LOADING = 'auth/set-profile-loading';

export const authReducer: (state: AuthStateContextType, action: AuthAction) => AuthStateContextType = (
  state,
  action,
) => {
  const behaviours: Record<string, (state: AuthStateContextType, action: AuthAction) => AuthStateContextType> = {
    [START_AUTHORIZING]: (state) => ({
      ...state,
      isAuthorizing: true,
    }),
    [SET_AUTHORIZED]: (state, { user, role }) => {
      return {
        ...state,
        user,
        isAuthorizing: false,
        isAuthorized: true,
      };
    },
    [SET_UNAUTHORIZED]: (state) => ({
      ...state,
      user: undefined,
      isAuthorizing: false,
      isAuthorized: false,
    }),
    [LOGOUT]: () => ({
      user: undefined,
      isAuthorizing: false,
      isAuthorized: false,
      accessToken: undefined,
    }),
    [SET_ROLE]: () => {
      if (!action.role) {
        throw new Error('Missing role in authReducer');
      }

      return {
        ...state,
        role: action.role,
      };
    },
    [SET_TOKENS]: (state) => {
      if (!action.accessToken) {
        throw new Error('Missing access token in authReducer');
      }

      return {
        ...state,
        accessToken: action.accessToken,
        isAuthorizing: false,
        isAuthorized: true,
      };
    },
    [SET_IS_MEMBER]: () => ({
      ...state,
      isMember: action.isMember,
    }),
    [SET_IS_OWNER]: () => ({
      ...state,
      isOwner: action.isOwner,
    }),
    [SET_PROFILE]: (state, { profile }) => {
      if (profile) {
        profile.hasFeature = function (feature: Features) {
          return Array.isArray(this.features)
            ? !!this.features.find((profileFeature) => profileFeature.name === feature)
            : false;
        };

        const hasOnboardingMode = profile?.hasFeature(Features.ONBOARDING_MODE);

        if (!hasOnboardingMode) OnboardingModeStore.setValue(0);
      }

      return {
        ...state,
        profile,
      };
    },
    [SET_PROFILE_LOADING]: (state, { profileLoading }) => ({
      ...state,
      profileLoading,
    }),
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};
