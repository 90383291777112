import { DateTime } from 'luxon';

export const startOfDay = (date: Date) => startOfDayLuxon(DateTime.fromJSDate(date)).toJSDate();
export const endOfDay = (date: Date) => endOfDayLuxon(DateTime.fromJSDate(date)).toJSDate();

export const startOfDayLuxon = (date: DateTime) => date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
export const endOfDayLuxon = (date: DateTime) => date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

export const formatDate = (date: string | Date, isDisplay = false) => {
  if (isDisplay) {
    return DateTime.fromISO(date as string).toFormat('dd/MM/yyyy');
  }

  return DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');
};

export const getDayStartsInRange = (from: Date, to: Date): Date[] => {
  const rangeFrom = startOfDay(from);
  const rangeTo = endOfDay(to);

  const dates: Date[] = [];
  let currentDate = rangeFrom;
  while (rangeTo >= currentDate) {
    dates.push(currentDate);
    currentDate = DateTime.fromJSDate(currentDate).plus({ days: 1 }).toJSDate();
  }
  dates.push(currentDate);

  // console.log('rangeFrom', rangeFrom);
  // console.log('rangeTo', rangeTo);
  // console.log('dates', dates);

  return dates;
};

export const getArrayOfDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  let theDate: DateTime = DateTime.fromJSDate(startDate);

  while (theDate.toJSDate() <= endDate) {
    dates.push(theDate.toJSDate());
    theDate = theDate.plus({ days: 1 });
  }

  return dates.reverse();
};
