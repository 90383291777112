import React from 'react';

const CloseIcon = ({ color = 'currentColor' }: { color?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7764 2.9911C15.2646 2.50294 15.2646 1.71148 14.7764 1.22333C14.2883 0.735173 13.4968 0.735173 13.0086 1.22333L7.99998 6.23199L2.99132 1.22333C2.50317 0.735174 1.71171 0.735174 1.22355 1.22333C0.735399 1.71148 0.735399 2.50294 1.22355 2.9911L6.23222 7.99976L1.22354 13.0084C0.735383 13.4966 0.735383 14.2881 1.22354 14.7762C1.71169 15.2644 2.50315 15.2644 2.9913 14.7762L7.99998 9.76753L13.0087 14.7762C13.4968 15.2644 14.2883 15.2644 14.7764 14.7762C15.2646 14.2881 15.2646 13.4966 14.7764 13.0084L9.76775 7.99976L14.7764 2.9911Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
