import styled from 'styled-components';

export const LogoWrapper = styled.div`
  width: 57px;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #095c70;
  color: white;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.2px;
  line-height: 57px;
`;
