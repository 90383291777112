import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useLocale } from 'hooks';
import { InternationlNumber } from 'components';
import { stepsComponentProps } from '../../AddMedicModal.types';

import { ContactInfoStepFormikProps } from './ContactInfoStep.types';

export const ContactInfoStep = ({ parentContext }: stepsComponentProps) => {
  const { formatMessage } = useLocale();
  const { errors } = useFormikContext<ContactInfoStepFormikProps>();

  return (
    <div className={`${parentContext ? 'w-full' : 'w-60'}`}>
      <label htmlFor="email" className="block text-base text-gray-light">
        <span>{formatMessage({ id: 'enroll_new_medic.email' })}</span>
        <span className="text-red-dark ml-1">{'*'}</span>
        <Field
          type="email"
          data-testid="e2e-enroll-email"
          name="email"
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.email ? 'red' : 'gray-light'} ${
            errors.email && 'border-2'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder="you@example.com"
          required
        />
      </label>
      <label htmlFor="phoneNumber" className="mt-3 block text-base text-gray-light hidden">
        <span>{formatMessage({ id: 'enroll_new_medic.phone_number' })}</span>
        <span className="text-red-dark ml-1">{'*'}</span>
        <InternationlNumber
          name="phoneNumber"
          dataTestId="e2e-enroll-phone"
          isError={!!errors.phoneNumber}
          className="pii"
        />
      </label>
    </div>
  );
};
