import { makeObservable, observable, action, computed } from 'mobx';
import { DateTime } from 'luxon';

import { TaskModel } from 'models/task';
import { TaskStatus } from 'app/tasks/Tasks.types';
import { PatientsTasksStore } from '../PatientTasksStore';

class AbstractTaskCompleteStore {
  tasks: TaskModel[] = [];
  currentTask: TaskModel | null = null;
  active = false;
  chosenDate: Date | null = null;

  public load(): void {
    this.tasks = PatientsTasksStore.tasks;
  }

  get hasItems(): boolean {
    return this.tasks && this.tasks.length > 0;
  }

  public setActive(active: boolean): void {
    if (active && !this.hasItems) return;

    this.switchToLatestTask({ chooseEnabled: true });
    this.active = active;
  }

  public setInactive(): void {
    this.active = false;
  }

  get shouldFilterBy(): boolean {
    return this.active && Boolean(this.currentTask?.start_date) && Boolean(this.currentTask?.end_date);
  }

  get getCurrentTimeRange(): { from: Date; to: Date } {
    if (!this.currentTask?.start_date || !this.currentTask?.end_date) return { from: new Date(), to: new Date() };

    const range = {
      from: DateTime.fromISO(this.currentTask?.start_date).startOf('day').toJSDate(), // will be 00:00:00
      to: DateTime.fromISO(this.currentTask?.end_date).endOf('day').toJSDate(), // will be 23:59:59
    };

    return range;
  }

  switchToLatestTask = ({ chooseEnabled = true }: { chooseEnabled?: boolean }): void => {
    if (!this.hasItems) return;

    // this.tasks can't be undefined here (checked in hasItems)
    const tasks = this.tasks.sort((a, b) => {
      return DateTime.fromISO(b.start_date).toMillis() - DateTime.fromISO(a.start_date).toMillis();
    });

    if (chooseEnabled) {
      const latestEnabled = tasks.find((task) => task.status === TaskStatus.CREATED);

      if (latestEnabled) {
        this.setCurrentTask(latestEnabled);

        return;
      }
    }

    const latestTask = tasks[0];
    this.setCurrentTask(latestTask);
  };

  switchToChosenDate = (): void => {
    if (!this.chosenDate) return;

    if (!this.hasItems) return;

    // chosenTask should be the task with a start date < this.chosenDate and an end date > this.chosenDate
    const chosenTask = this.tasks.find((task) => {
      const startDate = DateTime.fromISO(task.start_date).toJSDate();
      const endDate = DateTime.fromISO(task.end_date).toJSDate();

      return startDate <= this.chosenDate && endDate >= this.chosenDate;
    });

    if (chosenTask) this.setCurrentTask(chosenTask);
  };

  public setCurrentTask(task: TaskModel | null): void {
    this.currentTask = task;
  }

  switchToTask = (taskId: string): void => {
    this.setCurrentTask(this.tasks.find((task: TaskModel) => task.id === taskId) || null);
  };

  switchPatientProfile = ({
    switchIfActive = false,
    closeWidgetIfEmpty = true,
  }: {
    switchIfActive?: boolean;
    closeWidgetIfEmpty?: boolean;
  }): void => {
    if (switchIfActive && !this.active) return;

    this.load();

    if (this.hasItems) {
      if (this.chosenDate) {
        this.switchToChosenDate();
        this.setChooseDate(null);
      } else {
        this.switchToLatestTask({ chooseEnabled: true });
      }
    }

    if (!this.hasItems && closeWidgetIfEmpty) this.setActive(false);
  };

  public setNextPrevOfType = (): void => {
    // next and prev of type will contain a task of the same type
    // for example, only reports or only inspections
    for (let i = 0; i < this.tasks.length; i++) {
      if (i === 0) {
        this.tasks[i].prevOfType = undefined;
      } else {
        this.tasks[i].prevOfType = this.tasks[i - 1];
      }

      if (i === this.tasks.length - 1) {
        this.tasks[i].nextOfType = undefined;
      } else {
        this.tasks[i].nextOfType = this.tasks[i + 1];
      }
    }
  };

  public setChooseDate = (date: Date | null): void => {
    this.chosenDate = date;
  };

  constructor() {
    makeObservable(this, {
      tasks: observable,
      hasItems: computed,
      load: action,
      currentTask: observable,
      active: observable,
      setActive: action,
      setInactive: action,
      setCurrentTask: action,
      shouldFilterBy: computed,
      getCurrentTimeRange: computed,
      switchPatientProfile: action,
      setNextPrevOfType: action,
      setChooseDate: action,
    });
  }
}

export { AbstractTaskCompleteStore };
