import React from 'react';
import { createRoot } from 'react-dom/client'; // changed import
import Axios from 'axios';
import { configure } from 'axios-hooks';
import * as Sentry from '@sentry/react';

import { AppProviders } from 'providers/AppProviders';

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { getConfig } from './api/http.helper';

import './index.scss';

const deployEnv = process.env.REACT_APP_DEPLOY_ENV || '';
const sentryEnvs = ['production', 'staging'];

if (process.env.REACT_APP_SENTRY_DSN && sentryEnvs.includes(deployEnv))
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: deployEnv,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // TODO Later after we are safe on production (Uri)
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const axios = Axios.create({
  baseURL: config.apiUrl,
  headers: getConfig(),
});

configure({ axios });

const app = (
  <AppProviders>
    <App />
  </AppProviders>
);

const root = document.getElementById('root');

if (process.env.REACT_APP_API_URL.startsWith('/mock')) {
  const mockServer = import('./api/mockServer');

  mockServer.then((server) => {
    server.mockServer();

    createRoot(root).render(app);
  });
} else {
  createRoot(root).render(app);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
